import { FormEvent, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addingRobotAction, addingRobotException, deleteActionRobot, deleteRobotException } from '../../../store/sesRobotSlice';
import { addingDataElemAction, addingEndpointAction, addingEndpointEntryAction, deleteActionDataElem, deleteActionEndpoint, deleteActionEndpointEntry } from '../../../store/sesSlice';
import { selectChannelList } from '../../../store/qasSlice';
import useTranslate from '../../../hooks/useTranslate';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import { IFormCopyAndMoveActionProps } from './FormCopyAndMoveAction.props';

const FormCopyAndMoveAction = ({ showModal, setShowModal, action, sourceChannel, sourceIdx, actionFor, changeFlgHandler }: IFormCopyAndMoveActionProps): JSX.Element => {
	const [selectChannel, setSelectChannel] = useState<string>(''); // канал

	const dispatch = useAppDispatch();
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const translate = useTranslate(); // hook для перевода текста

	// обработчик копирования/перемещения действия
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const dataToCopy = { channel: selectChannel, action };
		// добавление действия
		if (actionFor.for === 'robotException') {
			dispatch(addingRobotException({ exception: actionFor.exception, ...dataToCopy })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(addingEndpointEntryAction({ ...dataToCopy })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(addingRobotAction({ actionEvent: actionFor.event, ...dataToCopy })); // робота
			actionFor.for === 'dataElement' && dispatch(addingDataElemAction({ actionEvent: actionFor.event, ...dataToCopy })); // ЭД
			actionFor.for === 'endpoint' && dispatch(addingEndpointAction({ ...dataToCopy })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}

		// удаление действия
		if (showModal.type === 'move') {
			const dataToMove = { channel: sourceChannel, idx: sourceIdx };
			if (actionFor.for === 'robotException') {
				dispatch(deleteRobotException({ exception: actionFor.exception, ...dataToMove })); // исключения робота
			} else if (actionFor.for === 'endpointEntry') {
				dispatch(deleteActionEndpointEntry({ ...dataToMove })); // входа КТ
			} else {
				actionFor.for === 'robotEvent' && dispatch(deleteActionRobot({ actionEvent: actionFor.event, ...dataToMove })); // робота
				actionFor.for === 'dataElement' && dispatch(deleteActionDataElem({ actionEvent: actionFor.event, ...dataToMove })); // ЭД
				actionFor.for === 'endpoint' && dispatch(deleteActionEndpoint({ ...dataToMove })); // КТ
			}
		}

		formCloseHandler(); // закрываем форму
	};

	// обработчик закрытия формы
	const formCloseHandler = (): void => {
		setShowModal(prev => ({ ...prev, isShown: false }));
	};

	return (
		<ModalFormWindow showModal={showModal.isShown} headerTitle={showModal.type === 'copy' ? 'formHeader_copyAction' : 'formHeader_movementAction'} close={formCloseHandler}>
			<form onSubmit={submitHandler}>
				{/* канал */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }} required>
					<InputLabel id="channel-label" sx={{ fontSize: 13 }}>{translate('select_channel')}</InputLabel>
					<Select
						id="channel"
						labelId="channel-label"
						label={translate('select_channel')}
						value={selectChannel}
						onChange={(e) => setSelectChannel(e.target.value)}
						style={{ fontSize: 13, height: 33 }}
					>
						{Array.from(new Set(['default', sourceChannel].concat(channelList.data.map(channel => channel.name))))
							.filter(channelName => !(showModal.type === 'move' && channelName === sourceChannel))
							.map((channel) => (
								<MenuItem key={channel} value={channel} sx={{ fontSize: 13 }}>{channel}</MenuItem>
							))}
					</Select>
				</FormControl>

				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
					>
						{translate(showModal.type === 'copy' ? 'button_copy' : 'button_move')}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormCopyAndMoveAction;
