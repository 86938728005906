import { useEffect, useRef, useState } from 'react';
import { Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, getDashboardsList, selectDashboardsList } from '../../store/resSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { RES, SERVICE } from '../../constants/accessRights';
import { RequestStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import ReportsControls from '../../components/Controls/ReportsControls/ReportsControls';
import ReportNavbar from '../../components/Navbars/ReportNavbar/ReportNavbar';
import styles from './Reports.module.scss';

const Reports = (): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const iframeRef = useRef<HTMLIFrameElement>(null);

	const dispatch = useAppDispatch();
	// const tokenAuth = useAppSelector(selectTokenAuth); // store - токены авторизации
	const dashboardsList = useAppSelector(selectDashboardsList); // store - список рабочих столов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(RES.DASHBOARDS) && dispatch(getDashboardsList()); // получаем список рабочих столов

		// при уходе со страницы
		return () => {
			dispatch(clearState()); // очищаем state RES
		};
	}, []);

	// следим за активной ссылкой доски и авторизуемся
	// useEffect(() => {
	// dashboardsList.activeDashboardLink && getSrc(dashboardsList.activeDashboardLink);
	// }, [dashboardsList.activeDashboardLink]);

	// async function getSrc(url: string) {
	// 	try {
	// 		const response = await axios.get(url, {
	// 			headers: {
	// 				'x-access-token': tokenAuth.access,
	// 			}
	// 		});
	// 		if (iframeRef.current) iframeRef.current.src = response.config.url || ''; // устанавливаем ссылку в iframe
	// 	} catch (error) {
	// 		if (error) console.error(error);
	// 	}
	// }

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.RES, RES.DASHBOARDS]}>
			<>
				<ReportsControls setShowPage={setShowPage} />
				<ReportNavbar />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper}>
						{dashboardsList.status === RequestStatus.IDLE && dashboardsList.activeDashboardLink ?
							<iframe
								className={styles.iframe}
								ref={iframeRef}
								title="Dashboard"
								src={`${window.location.protocol}//${window.location.hostname}:5602${dashboardsList.activeDashboardLink}`}
							/>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</Fade>
			</>
		</PageWrapper>
	);
};

export default Reports;
