import { useEffect, useMemo, useRef, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, ColumnDef, getFilteredRowModel, SortingState, getSortedRowModel } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDataElem, getEndpoint, selectDataList, selectEndpointList } from '../../../store/sesSlice';
import { selectActiveRobotId } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { DATA_ELEM_TYPE_DICTIONARY, ENPOINT_TYPES_DICTIONARY } from '../../../constants/robotConfigLists';
import { RobotDataRow } from '../../../types/tableTypes';
import { IRobotDataProps } from './RobotData.props';
import styles from './RobotData.module.scss';

const RobotData = ({ type, mainfilter, additionFilter, setShowElem }: IRobotDataProps) => {
	const [data, setData] = useState<RobotDataRow[]>([]); // данные, преобразованные для работы в таблице
	const [sorting, setSorting] = useState<SortingState>([]); // сортированные данные
	const tableContainerRef = useRef<HTMLDivElement>(null); // ссылка на контейнер таблицы
	const tBodyRef = useRef<HTMLTableSectionElement>(null); // ссылка на body таблицы

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const dataElemList = useAppSelector(selectDataList); // список конечных точек
	const endpointList = useAppSelector(selectEndpointList); // список элементов данных

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за данными
	useEffect(() => {
		if (type === 'data') {
			setData(dataElemList.data.map(({ id, name, type, rtype }) => {
				return { id, name, type: translate(DATA_ELEM_TYPE_DICTIONARY[type]), rtype: translate(ENPOINT_TYPES_DICTIONARY[rtype]) };
			})); // преобразуем в формат таблицы
		} else {
			setData(endpointList.data.map(({ id, name, type, entryType, entry, run, actions }) => {
				const listOfEntryVariables: string[] = [];
				const listOfRunVariables: string[] = [];
				const listOfActionsVariables: string[] = [];
				const variables: string[] = [];
				entryType === 'conditions' && entry.map(condition => condition.conditions).flat().forEach(conditionBlock => {
					conditionBlock.type === 'variable' && listOfEntryVariables.push(`${conditionBlock.id}: ${conditionBlock.value}`);
				});
				entryType === 'conditions' && entry.map(condition => condition.run).flat(2).forEach(conditionBlock => {
					conditionBlock.type === 'variable' && listOfRunVariables.push(`${conditionBlock.id}: ${conditionBlock.value}`);
				});
				entryType === 'inactivity' && run?.flat().forEach(conditionBlock => {
					conditionBlock.type === 'variable' && listOfRunVariables.push(`${conditionBlock.id}: ${conditionBlock.value}`);
				});
				Object.keys(actions || {}).forEach(channelId => {
					actions[channelId].forEach(action => {
						'conditions' in action && action.conditions?.flat().forEach(conditionBlock => {
							conditionBlock.type === 'variable' && listOfActionsVariables.push(`${conditionBlock.id}: ${conditionBlock.value}`);
						});
					});
				});
				listOfEntryVariables.length > 0 && variables.push(`${translate('title_entrance')}: ${listOfEntryVariables.length}`);
				listOfRunVariables.length > 0 && variables.push(`${translate('title_collectingInfo')}: ${listOfRunVariables.length}`);
				listOfActionsVariables.length > 0 && variables.push(`${translate('title_actions')}: ${listOfActionsVariables.length}`);
				return {
					id,
					name,
					type: translate(ENPOINT_TYPES_DICTIONARY[type]),
					entry: entryType === 'conditions' ? entry.map(condition => condition.conditions).flat().map(condition => dataElemList.dictionary[condition.id] || condition.id).toString() : '',
					entryVariables: listOfEntryVariables,
					runVariables: listOfRunVariables,
					actionsVariables: listOfActionsVariables,
					variables: variables.length > 0 ? variables.join(', ') : '',
				};
			})); // преобразуем в формат таблицы
		}
	}, [dataElemList.data, endpointList.data]);

	useEffect(() => {
		if (type === 'data') {
			table.getHeaderGroups()[0].headers[0].column.setFilterValue(mainfilter);
		} else {
			table.getHeaderGroups()[0].headers[0].column.setFilterValue(mainfilter);
			table.getHeaderGroups()[0].headers[3].column.setFilterValue(additionFilter);
		}
	}, [mainfilter, additionFilter]);

	// обработчик нажатия мыши на строку
	const clickHandler = (id: string): void => {
		// для элементов данных
		if (type === 'data') {
			isAccess(SES.DATA_GET) && activeRobotId && dispatch(getDataElem({ robotId: activeRobotId, elementId: id })); // получаем
		}
		// для конечных точек
		if (type === 'endpoints') {
			isAccess(SES.ENDPOINT_GET) && activeRobotId && dispatch(getEndpoint({ robotId: activeRobotId, endpointId: id })); // получаем
		}
		setShowElem(true); // открываем вкладку
	};

	const columns = useMemo<ColumnDef<RobotDataRow, string>[]>(() => [
		{
			id: 'name',
			header: translate('columnTitle_name'),
			accessorKey: 'name',
			size: 200,
		},
		{
			id: 'type',
			header: translate('columnTitle_type'),
			accessorKey: 'type',
			size: 50,
		},
		{
			id: 'rtype',
			header: translate('columnTitle_subType'),
			accessorKey: 'rtype',
			size: 50,
		},
		{
			id: 'entry',
			// header: translate('entry'),
			accessorKey: 'entry',
			size: 0,
		},
		{
			id: 'entryVariables',
			// header: translate('var entry'),
			accessorKey: 'entryVariables',
			size: 0,
		},
		{
			id: 'runVariables',
			// header: translate('var run'),
			accessorKey: 'runVariables',
			size: 0,
		},
		{
			id: 'actionsVariables',
			// header: translate('var actions'),
			accessorKey: 'actionsVariables',
			size: 0,
		},
		{
			id: 'variables',
			header: translate('columnTitle_variables'),
			accessorKey: 'variables',
			size: 50,
		},
	], []);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		filterFns: {
			myCustomFilter: () => {
				return false;
			}
		},
		enableColumnResizing: true, // изменение ширины столбца
		onSortingChange: setSorting, // для сортировки
		getSortedRowModel: getSortedRowModel(), // для сортировки
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(), // для фильтрации
		columnResizeMode: 'onChange', // изменение ширины столбца в runtime
	});

	return (
		<div className={styles.wrapper}>
			<div className={styles.container} ref={tableContainerRef}>
				<table className={styles.table} style={{ width: table.getCenterTotalSize() }}>
					<thead className={cn(styles.thead, {
						[styles.theadBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollTop > 0, // тень из под шапки и размытие под ним, когда скроллим таблицу
					})}>
						{table.getHeaderGroups().map(headerGroup => (
							<tr className={styles.tr} key={headerGroup.id}>
								{headerGroup.headers.map(header =>
									header.id !== 'entry' && !(type === 'endpoints' && header.id === 'rtype') && header.id !== 'entryVariables' && header.id !== 'runVariables' && header.id !== 'actionsVariables' && !(type === 'data' && header.id === 'variables') && (
										<th className={styles.th} {...{
											key: header.id,
											colSpan: header.colSpan,
											style: { width: header.getSize() }
										}}>
											{!header.isPlaceholder &&
												<div onClick={header.column.getToggleSortingHandler()} className={styles.thTitle}>
													{flexRender(header.column.columnDef.header, header.getContext())}
													{{ asc: <FontAwesomeIcon icon={faArrowUp} />, desc: <FontAwesomeIcon icon={faArrowDown} /> }[header.column.getIsSorted() as string] ?? null}
												</div>
											}
										</th>
									))}
							</tr>
						))}
					</thead>
					<tbody className={styles.tbody} ref={tBodyRef}>
						{table.getRowModel().rows.map((row) => (
							<tr className={styles.tr} key={row.id}>
								{row.getVisibleCells().map(cell =>
									cell.column.id !== 'entry' && !(type === 'endpoints' && cell.column.id === 'rtype') && cell.column.id !== 'entryVariables' && cell.column.id !== 'runVariables' && cell.column.id !== 'actionsVariables' && !(type === 'data' && cell.column.id === 'variables') && (
										<td
											className={cn(styles.td, {
												[styles.tdName]: cell.column.id === 'name',
											})}
											{...{
												key: cell.id,
												style: {
													width: cell.column.getSize()
												},
											}}
											onClick={() => cell.column.id === 'name' && clickHandler(row.original.id)}
											title={cell.getValue() !== undefined && cell.column.id !== 'variables' ? String(cell.getValue()) : ''}
											data-id={cell.column.id}
										>
											<div className={styles.tdText}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
											<div className={styles.tdPopup}>
												{row.original.entryVariables && row.original.entryVariables?.length > 0 &&
													<>
														<div className={styles.tdPopupTitle}>{translate('title_entrance')}:</div>
														{row.original.entryVariables.map((variable, idx) => (
															<div className={styles.tdPopupItem} key={variable + idx}>{variable}</div>
														))}
													</>
												}
												{row.original.runVariables && row.original.runVariables?.length > 0 &&
													<>
														<div className={styles.tdPopupTitle}>{translate('title_collectingInfo')}:</div>
														{row.original.runVariables.map((variable, idx) => (
															<div className={styles.tdPopupItem} key={variable + idx}>{variable}</div>
														))}
													</>
												}
												{row.original.actionsVariables && row.original.actionsVariables?.length > 0 &&
													<>
														<div className={styles.tdPopupTitle}>{translate('title_actions')}:</div>
														{row.original.actionsVariables.map((variable, idx) => (
															<div className={styles.tdPopupItem} key={variable + idx}>{variable}</div>
														))}
													</>
												}
											</div>
										</td>
									))}
							</tr>
						))}
					</tbody>
				</table>

				{table.getRowModel().rows.length === 0 && <div className={styles.notFound}>{translate('title_notFound')}</div>}
			</div>
		</div>
	);
};

export default RobotData;
