import { FormEvent, useRef, useState } from 'react';
import { Button, Fade, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addFragmentForSelection, clearFragmentForSelection, clearFragmentToQuestion, getFragment, selectCategoriesList, selectDocument, selectFragmentToQuestion } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IFragmentToQuestionProps } from './FragmentToQuestion.props';
import styles from './FragmentToQuestion.module.scss';

const FragmentToQuestion = ({ fileHandler }: IFragmentToQuestionProps): JSX.Element => {
	const [inputQuestion, setInputQuestion] = useState<string>(''); // вопрос
	const [selectCategoryId, setSelectCategoryId] = useState<string>(''); // id категории
	const [inputMaxDocs, setInputMaxDocs] = useState<number>(1); // кол-во документов в результате поиска
	const inputQuestionRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода вопроса

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const { fileId } = useAppSelector(selectDocument); // store - документ (файл)
	const fragments = useAppSelector(selectFragmentToQuestion); // store - данные поиска фрагмента на вопрос

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обрабочик отправки вопроса
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		(fragments.data || fragments.status !== RequestStatus.IDLE) && dispatch(clearFragmentToQuestion()); // удаление данных поиска фрагмента
		dispatch(getFragment({
			question: inputQuestion,
			categoryId: selectCategoryId ? selectCategoryId : undefined,
			maxDocs: inputMaxDocs !== 1 ? inputMaxDocs : undefined,
		})); // поиск фрагмента на вопрос
	};

	// обработчик открытия документа и выделения фрагмента
	const highlightHandler = (id: string, text: string) => {
		dispatch(clearFragmentForSelection()); // очищаем фрагмент из store для выделения
		dispatch(addFragmentForSelection(text.trim())); // добавляем фрагмент в store без крайних пробелов
		id !== fileId && fileHandler(id); // открываем документ если, еще не был открыт
	};

	return (
		<Fade in={true} timeout={500}>
			<div className={styles.wrapper}>
				<form onSubmit={e => submitHandler(e)}>
					<h3 className={styles.header}>{translate('title_searchFragments')}</h3>
					{/* вопрос */}
					<FormControl fullWidth margin='dense'>
						<TextField
							inputRef={inputQuestionRef}
							required
							id="question"
							label={translate('input_question')}
							variant="outlined"
							multiline
							rows={6}
							disabled={fragments.status === RequestStatus.LOADING}
							value={inputQuestion}
							onChange={(e) => setInputQuestion(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end" >
										{inputQuestion.length > 0 &&
											<FontAwesomeIcon
												icon={faXmark}
												onClick={() => {
													if (fragments.status !== RequestStatus.LOADING) {
														setInputQuestion('');
														inputQuestionRef.current?.focus();
													}
												}}
												style={{ cursor: fragments.status !== RequestStatus.LOADING ? 'pointer ' : 'not-allowed' }}
											/>
										}
									</InputAdornment>
								),
								style: {
									fontSize: 13,
									padding: 8,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>

					{/* категория */}
					{categoriesList.data.length > 0 &&
						<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
							<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
							<Select
								labelId="category-label"
								id="category"
								label={translate('select_category')}
								disabled={fragments.status === RequestStatus.LOADING}
								value={selectCategoryId}
								onChange={(e) => setSelectCategoryId(e.target.value)}
								style={{ fontSize: 13, height: 33, color: colorPrimary }}
							>
								<MenuItem value='' sx={{ fontSize: 13 }}>{translate('selectItem_notSelected')}</MenuItem>
								{categoriesList.data.map((category) => (
									<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					}

					{/* кол-во документов в результате поиска */}
					<FormControl fullWidth margin='dense'>
						<TextField
							label={translate('input_numberOfDocumentsInSearchResult')}
							variant="outlined"
							type='number'
							value={inputMaxDocs}
							onChange={(e) => setInputMaxDocs(+e.target.value)}
							error={inputMaxDocs < 1 || inputMaxDocs > 10}
							disabled={fragments.status === RequestStatus.LOADING}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
								inputProps: { min: 1, max: 10 },
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>

					<FormControl fullWidth margin='dense'>
						<Button
							id='searchFragmentBtn'
							variant="outlined"
							sx={{ fontSize: 11 }}
							type='submit'
							disabled={fragments.status === RequestStatus.LOADING}
						>
							{translate('button_search')}
							{fragments.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
						</Button>
					</FormControl>
				</form>

				<div className={styles.data}>
					{fragments.status === RequestStatus.LOADING &&
						<div><ProgressCircle title={translate('spinnerTitle_search')} /></div>
					}

					{(fragments.status === RequestStatus.FAILED || (fragments.data && typeof fragments.data === 'object' && 'error' in fragments.data && fragments.data.error === ResponseStatus.FAILED)) &&
						<p>{translate((fragments.data && typeof fragments.data === 'object' && 'message' in fragments.data) ? fragments.data.message : 'title_loadFailed')}</p>
					}

					{fragments.status === RequestStatus.IDLE && fragments.data && 'fragments' in fragments.data && Array.isArray(fragments.data.fragments) &&
						<Fade in={true} timeout={500}>
							<div>
								{fragments.data.fragments.map((fragment, idx) =>
									<div className={styles.dataFragment} key={idx}>
										<p className={styles.dataFragmentTitle}>
											<span className={styles.dataFragmentKey}>{translate('title_document')}:</span> <span className={isAccess(QAS.DOC_GET) ? styles.dataFragmentLink : ''} onClick={() => isAccess(QAS.DOC_GET) && highlightHandler(fragment.id, fragment.text)}>{fragment.title}</span>
										</p>
										<p>
											<span className={styles.dataFragmentKey}>{translate('title_category')}:</span> {categoriesList.data.find(categoryItem => categoryItem.id === fragment.category)?.name || `${fragment.category} (${translate('title_notFound')})`}
										</p>
										<p>
											<span className={styles.dataFragmentKey}>{translate('title_similarity')}:</span> {fragment.similarity.toFixed(2)}
										</p>
										<p className={styles.dataFragmentText}>{fragment.text}</p>
									</div>
								)}
							</div>
						</Fade>
					}
				</div>
			</div>
		</Fade>
	);
};

export default FragmentToQuestion;
