import { Autocomplete, FormControl, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faRotate } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../store/hooks';
import { selectDebuggerAnswer } from '../../../store/sesSlice';
import { selectChannelList } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { backgroundColor, colorPrimary } from '../../../constants/colors';
import { RequestStatus } from '../../../types/statusTypes';
import AudioVisualizer from '../../AudioVisualizer/AudioVisualizer';
import { IControlsProps } from './Controls.props';
import styles from './Controls.module.scss';

const Controls = ({ setShowJsonBlock, conversationFlg, mediaRecorder, inputChannel, setInputChannel, clearDebuggerData }: IControlsProps): JSX.Element => {

	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	return (
		<div className={styles.controls}>
			{/* кнопка открытия данных сессии */}
			{isAccess(SES.SESSION) &&
				<button
					className={styles.button}
					type='button'
					title={translate('buttonTitle_sessionData')}
					onClick={() => setShowJsonBlock(prev => !prev)}
				>
					<FontAwesomeIcon icon={faCode} color={backgroundColor} size="lg" />
				</button>
			}

			{/* аудио-визуализатор / канал */}
			{conversationFlg ?
				<AudioVisualizer mediaRecorder={mediaRecorder} />
				:
				<FormControl fullWidth>
					<Autocomplete
						options={channelList.data.map(channel => channel.name)}
						value={inputChannel}
						onChange={(_, value) => setInputChannel(value ? value : '')}
						disabled={debuggerAnswer.status === RequestStatus.LOADING || debuggerAnswer.session !== ''}
						renderInput={(params) =>
							<TextField
								{...params}
								label={translate('input_channel')}
								onChange={(e) => setInputChannel(e.target.value)}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						}
						sx={{
							".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
							".MuiInputBase-input": { marginTop: -1 },
						}}
						getOptionLabel={option => option}
						renderOption={(props, option) => {
							return (
								<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
									{option}
								</span>
							);
						}}
					/>
				</FormControl>
			}

			{/* кнопка перезагрузки сессии */}
			<button
				className={styles.button}
				type='button'
				title={translate('buttonTitle_restartSession')}
				disabled={conversationFlg && !debuggerAnswer.endOfSession}
				onClick={() => clearDebuggerData({ includesVariables: false })}
			>
				<FontAwesomeIcon icon={faRotate} color={backgroundColor} size="lg" />
			</button>
		</div>
	);
};

export default Controls;
