import { useEffect, useState } from 'react';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addingDataElemAction, addingEndpointAction, addingEndpointEntryAction, selectDataElem, selectEndpointElem } from '../../../store/sesSlice';
import { addingRobotAction, addingRobotException } from '../../../store/sesRobotSlice';
import { selectScriptList } from '../../../store/sesScriptSlice';
import { selectClusterServer } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { ACTION_EXTENDED_LIST, ACTION_TYPE_EXTENDED_LIST, ActionExtended, ActionTypeExtended, SERVICE_TYPE_EXTENDED_LIST, ServiceTypeExtended } from '../../../constants/robotConfigLists';
import { SES } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import { ActionVariantType } from '../../../types/sesTypes';
import { RequestStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingActionProps } from './FormAddingAction.props';
import styles from './FormAddingAction.module.scss';

const FormAddingAction = ({ channel, changeFlg, setChangeFlg, actionFor }: IFormAddingActionProps): JSX.Element => {
	const [selectAction, setSelectAction] = useState<ActionExtended>((actionFor.for === 'robotEvent' && actionFor.event === 'hangup') ? 'say' : 'chooseAction'); // действие
	const [inputVoice, setInputVoice] = useState<string>(''); // голос
	const [selectActionType, setSelectActionType] = useState<ActionTypeExtended>((actionFor.for === 'robotEvent' && actionFor.event === 'hangup') ? 'external' : 'chooseActionType'); // тип действия
	const [selectScript, setSelectScript] = useState<string>('chooseScript'); // скрипт
	const [selectService, setSelectService] = useState<ServiceTypeExtended>('chooseService'); // сервис

	const dispatch = useAppDispatch();
	const dataElem = useAppSelector(selectDataElem); // store - элемент данных
	const endpointElem = useAppSelector(selectEndpointElem); // store - конечная точка
	const scriptList = useAppSelector(selectScriptList); // store - список скриптов
	const { tts: clusterServerTts } = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за событием и каналом и сбрасываем поля
	useEffect(() => {
		resetInputAndSelect();
	}, [actionFor, channel]);

	// следим за действием, типом действия, скриптом и сервисом, и создаем и передаем объект действия в функцию добавления
	useEffect(() => {
		if (selectAction === 'end') {
			addActionHandler({
				action: selectAction,
			});
		} else if (selectAction === 'transfer') {
			addActionHandler({
				action: selectAction,
				destination: '',
			});
		} else if (selectAction === 'say' && (selectActionType === 'linear' || selectActionType === 'random')) {
			addActionHandler({
				action: selectAction,
				voice: inputVoice,
				type: selectActionType,
				text: [''],
			});
		} else if (selectAction === 'say' && selectActionType === 'external' && selectScript !== 'chooseScript') {
			addActionHandler({
				action: selectAction,
				voice: inputVoice,
				type: selectActionType,
				script: selectScript,
			});
		} else if (selectAction === 'say' && selectActionType === 'internal' && selectService === 'qas-ask') {
			addActionHandler({
				action: selectAction,
				voice: inputVoice,
				type: selectActionType,
				service: selectService,
				categories: {},
				useCache: 'checked',
				useGPT: 'no',
				creative: 'no',
				addAlias: 'no',
				model: 'default',
				maxDocs: 1,
			});
		} else if (selectAction === 'say' && selectActionType === 'internal' && selectService === 'qas-chat') {
			addActionHandler({
				action: selectAction,
				voice: inputVoice,
				type: selectActionType,
				service: selectService,
				depth: 3,
				model: 'default',
			});
		}
	}, [selectAction, selectActionType, selectScript, selectService]);

	// функция сброса всех полей
	const resetInputAndSelect = (): void => {
		setSelectAction((actionFor.for === 'robotEvent' && actionFor.event === 'hangup') ? 'say' : 'chooseAction');
		setInputVoice('');
		setSelectActionType((actionFor.for === 'robotEvent' && actionFor.event === 'hangup') ? 'external' : 'chooseActionType');
		setSelectScript('chooseScript');
		setSelectService('chooseService');
	};

	// обработчик добавления действия
	const addActionHandler = (actionObj: ActionVariantType): void => {
		resetInputAndSelect(); // очищаем поля
		if (actionFor.for === 'robotException') {
			dispatch(addingRobotException({ exception: actionFor.exception, channel, action: actionObj })); // добавление исключения роботу в store
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(addingEndpointEntryAction({ channel, action: actionObj })); // добавление действия входа КТ в store
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(addingRobotAction({ actionEvent: actionFor.event, channel, action: actionObj })); // добавление действия роботу в store
			actionFor.for === 'dataElement' && dispatch(addingDataElemAction({ actionEvent: actionFor.event, channel, action: actionObj })); // добавление действия элементу в store
			actionFor.for === 'endpoint' && dispatch(addingEndpointAction({ channel, action: actionObj })); // добавление действия конечной точке в store
			(dataElem.element?.id || endpointElem.endpoint?.id || actionFor.for === 'robotEvent') && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	return (
		<div className={cn(styles.container, {
			[styles.containerOpacity]: selectAction === 'chooseAction'
		})}>
			{/* верхний блок для всех, кроме настроек робота с действием "Завершение разгвора" */}
			{!(actionFor.for === 'robotEvent' && actionFor.event === 'hangup') &&
				<div className={styles.action}>
					{/* действие */}
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('select_action')}</InputLabel>
						<Select
							label={translate('select_action')}
							value={selectAction}
							disabled={channel === ''}
							onChange={(e) => setSelectAction(e.target.value as ActionExtended)}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{ACTION_EXTENDED_LIST.map(({ action, translation }) =>
								<MenuItem key={action} value={action} sx={{ fontSize: 13, color: colorPrimary }} disabled={action === 'chooseAction'}>
									{translate(translation)}
								</MenuItem>
							)}
						</Select>
					</FormControl>

					{/* голос */}
					{selectAction === 'say' &&
						<FormControl fullWidth margin='dense'>
							<Autocomplete
								options={['', ...clusterServerTts.modelList]}
								value={inputVoice}
								onChange={(_, value) => setInputVoice(value ? value : '')}
								noOptionsText={<div className={styles.actionNoDataTitle}>{translate('title_notFound')}</div>}
								renderInput={(params) =>
									<TextField
										{...params}
										label={translate('input_voice')}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										InputProps={{
											...params.InputProps, // важно прокинуть параметры
											endAdornment: (
												<div style={{ marginTop: '-7px' }}>
													{clusterServerTts.status === RequestStatus.LOADING &&
														<ProgressCircle isBtnDisabled />
													}
													{params.InputProps.endAdornment} {/* важно дописать параметры */}
												</div>
											),
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								}
								sx={{
									".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
									".MuiInputBase-input": { marginTop: -1 },
								}}
								getOptionLabel={option => option}
								renderOption={(props, option) => {
									return (
										<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
											{option === '' ? translate('selectItem_byDefault') : option}
										</span>
									);
								}}
							/>
						</FormControl>
					}

					{/* тип действия */}
					{selectAction === 'say' &&
						<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, opacity: selectActionType === 'chooseActionType' ? 0.5 : 1 }}>
							<InputLabel sx={{ fontSize: 13 }}>{translate('select_actionType')}</InputLabel>
							<Select
								label={translate('select_actionType')}
								value={selectActionType}
								onChange={(e) => setSelectActionType(e.target.value as ActionTypeExtended)}
								style={{ fontSize: 13, height: 33, color: colorPrimary }}
							>
								{ACTION_TYPE_EXTENDED_LIST.map(({ actionType, translation }) =>
									<MenuItem
										key={actionType}
										value={actionType}
										disabled={(actionType === 'external' && !isAccess(SES.SCRIPT_LIST)) || actionType === 'chooseActionType' || (actionType === 'internal' && actionFor.for === 'dataElement')}
										sx={{ fontSize: 13, color: colorPrimary }}
									>
										{translate(translation)}
									</MenuItem>
								)}
							</Select>
						</FormControl>
					}
				</div>
			}

			{/* скрипт */}
			{selectAction === 'say' && selectActionType === 'external' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, opacity: selectScript === 'chooseScript' ? 0.5 : 1 }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_script')}</InputLabel>
					<Select
						label={translate('select_script')}
						value={selectScript}
						onChange={(e) => setSelectScript(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='chooseScript' sx={{ fontSize: 13, color: colorPrimary }} disabled>
							{translate('selectItem_selectScript')}
						</MenuItem>
						{scriptList.data.map(({ id, name }) => (
							<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
						))}
					</Select>
				</FormControl>
			}

			{/* сервис */}
			{selectAction === 'say' && selectActionType === 'internal' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, opacity: selectService === 'chooseService' ? 0.5 : 1 }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_service')}</InputLabel>
					<Select
						label={translate('select_service')}
						value={selectService}
						onChange={(e) => setSelectService(e.target.value as ServiceTypeExtended)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{SERVICE_TYPE_EXTENDED_LIST.map(({ serviceType, translation }) =>
							<MenuItem key={serviceType} value={serviceType} sx={{ fontSize: 13, color: colorPrimary }} disabled={serviceType === 'chooseService'}>
								{translate(translation)}
							</MenuItem>
						)}
					</Select>
				</FormControl>
			}
		</div>
	);
};

export default FormAddingAction;
