import { useEffect, useRef } from 'react';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { additionLastReplicaWithText, addReplicaInDialog, deletionAudioFromPlaybackQueue, deletionLastReplicaFromDialog, selectDebuggerAnswer } from '../../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { getPostfixRobotId } from '../ChatWidget';
import { ISendSessionData } from '../../../types/sesTypes';
import { RequestStatus } from '../../../types/statusTypes';
import AudioPlayer from '../../AudioPlayer/AudioPlayer';
import { IMessageListProps } from './MessageList.props';
import styles from './MessageList.module.scss';

const regexp = /{button:.+?=.+?}/g;

// парсинг кнопок из фразы
const parsingButtons = (message: string): { buttonName: string, messageToSend: string }[] | undefined => {
	const buttons = message.match(regexp)?.map(button => {
		const endIndexName = button.indexOf('=');
		const buttonName = button.slice(8, endIndexName);
		const messageToSend = button.slice(endIndexName + 1, -1);
		return { buttonName, messageToSend };
	});
	return buttons;
};

const MessageList = ({ inputMessageRef, socket, indexPlayingAudio, setIndexPlayingAudio, autoPlay, setAudioPlaybackStatusForVad, submitHandler, inputChannel, conversationFlg, setConversationFlg }: IMessageListProps): JSX.Element => {
	const messageListRef = useRef<HTMLDivElement>(null); // ссылка на блок сообщений

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота

	const translate = useTranslate(); // hook для перевода текста

	// следим за диалогом
	useEffect(() => {
		messageListRef.current?.scrollTo({
			top: messageListRef.current.scrollHeight,
			behavior: "smooth",
		}); // скролл в конец диалога
		inputMessageRef.current?.focus(); // ставим фокус в поле сообщения
	}, [debuggerAnswer.dialog]);

	// следим за статусом ответа
	useEffect(() => {
		// при ошибке - добавляем сообщение об ошибке
		if (debuggerAnswer.status === RequestStatus.FAILED) {
			dispatch(addReplicaInDialog({
				who: 'responseText',
				message: `<${translate('title_error')}: ${translate(debuggerAnswer.message || 'title_errorOccurred')}>`,
			})); // добавление реплики в диалог
		}
	}, [debuggerAnswer.status]);

	// следим за ответом
	useEffect(() => {
		if (debuggerAnswer.dialog.length > 0 && debuggerAnswer.dialog[debuggerAnswer.dialog.length - 1].message === '') {
			(debuggerAnswer.question || !conversationFlg) ?
				dispatch(additionLastReplicaWithText(debuggerAnswer.question || translate('title_notRecognized'))) // дописываем в последнюю реплику распознанный текст (не при разговоре)
				:
				dispatch(deletionLastReplicaFromDialog()); // удаление последней реплики (при разговоре)
		}
		// если пустой ответ
		if (debuggerAnswer.answer?.length === 0 && !conversationFlg) {
			conversationFlg ?
				dispatch(deletionLastReplicaFromDialog()) // удаление последней реплики (при разговоре)
				:
				dispatch(addReplicaInDialog({ who: 'responseText', message: translate('title_noAnswer') })); // добавление реплики в диалог
		} else {
			(async function () {
				if (Array.isArray(debuggerAnswer.answer)) {
					for (const answerItem of debuggerAnswer.answer) {
						for (let messageIdx = 0; messageIdx < answerItem.messages.length; messageIdx++) {
							const message = answerItem.messages[messageIdx];
							// если есть аудиоответ
							if (Array.isArray(answerItem.audio) && answerItem.audio[messageIdx]) {
								setIndexPlayingAudio(debuggerAnswer.dialog.length); // устанавливаем индекс следующего аудио, которое будет воспроизводиться
								const url = answerItem.audio[messageIdx]; // base64
								await fetch('data:audio/wav;base64,' + url)
									.then(res => res.blob())
									.then(blob => {
										dispatch(addReplicaInDialog({
											who: 'responseAudio',
											message: message.replace(regexp, '') || translate('title_noAnswer'),
											audioUrl: window.URL.createObjectURL(new Blob([blob], { type: "audio/wav" })),
											withButtons: parsingButtons(message), // парсим кнопки
										})); // добавление реплики в диалог
									});
							}
							// если есть перевод на оператора
							else if (answerItem.transfer) {
								dispatch(addReplicaInDialog({
									who: 'responseText',
									message: `${translate('title_transferDialogue')}: ${answerItem.transfer}`,
								})); // добавление реплики в диалог
							}
							// текстовые сообщения
							else {
								dispatch(addReplicaInDialog({
									who: 'responseText',
									message: message.replace(regexp, '') || translate('title_noAnswer'),
									withButtons: parsingButtons(message), // парсим кнопки
								})); // добавление реплики в диалог
							}
						}
					}
				}
			})();
		}
		// если есть id робота, есть ответ, id сессии
		if (activeRobotId && debuggerAnswer.answer && debuggerAnswer.session && socket?.readyState === 1) {
			const data: ISendSessionData = {
				path: 'session',
				robot: getPostfixRobotId(activeRobotVersion, activeRobotId),
				session: debuggerAnswer.session,
			};
			socket.send(JSON.stringify(data)); // обновляем данные сессии
		}
	}, [debuggerAnswer.answer]);

	// следим за очередью воспроизведения
	useEffect(() => {
		// только для голосового канала
		if (inputChannel === 'voice') {
			// когда очередь закончилась
			if (debuggerAnswer.playbackQueue.length === 0) {
				setAudioPlaybackStatusForVad('listening');
				debuggerAnswer.endOfSession && setConversationFlg(false); // в конце сессии - сбрасываем флаг разговора
			} else {
				setAudioPlaybackStatusForVad('playing');
			}
		}
	}, [debuggerAnswer.playbackQueue]);

	return (
		<div className={styles.messageList} ref={messageListRef}>
			{/* приветственное сообщение/статусы */}
			{debuggerAnswer.dialog.length === 0 &&
				<div className={styles.messageInit}>
					{socket?.readyState === 0 && translate('title_connection')}
					{socket?.readyState === 1 && translate('title_chatWidgetInitMessage')}
					{socket?.readyState === 2 && translate('title_closingConnection')}
					{socket?.readyState === 3 && translate('title_connectionClosed')}
				</div>
			}
			{/* диалог */}
			{debuggerAnswer.dialog.map((replica, idx) => (
				<div
					key={replica.message + replica.who + idx}
					className={cn(styles.messageItem, {
						[styles.messageItemClient]: replica.who === 'clientText' || replica.who === 'clientAudio',
						[styles.messageItemHidden]: replica.message === '',
					})}
				>
					<div
						className={cn(styles.messageText, {
							[styles.messageTextClient]: replica.who === 'clientText' || replica.who === 'clientAudio',
							[styles.messageTextAudio]: replica.who === 'clientAudio' || replica.who === 'responseAudio',
						})}
					>
						{(replica.who === 'clientAudio' || replica.who === 'responseAudio') ?
							<>
								<AudioPlayer
									url={replica.audioUrl || null}
									downloadOption={false} // отключение опции скачивания
									index={idx} // индекс для идентификации
									setIndexPlayingAudio={replica.who === 'responseAudio' ? setIndexPlayingAudio : undefined} // установка воспроизведения по порядку
									play={(autoPlay || inputChannel === 'voice') && replica.who === 'responseAudio' && indexPlayingAudio === idx} // команда старта воспроизведения
									smallSize
								/>
								<Fade in={replica.message.length > 0} timeout={300} mountOnEnter unmountOnExit>
									<p className={styles.messageTextAudioTranscript}>
										{replica.message}
										{replica.withButtons && replica.withButtons.length > 0 &&
											<div className={styles.messageTextButtons}>
												{replica.withButtons.map(({ buttonName, messageToSend }) => (
													<button
														key={buttonName}
														className={styles.messageTextButton}
														type='button'
														onClick={() => submitHandler({ message: messageToSend })}
														disabled={debuggerAnswer.dialog.length - 1 !== idx}
													>
														{buttonName}
													</button>
												))}
											</div>
										}
									</p>
								</Fade>
							</>
							:
							<>
								{replica.message === '' ?
									<>
										<span className={styles.messageLoaderDots}></span>
										<span className={styles.messageLoaderDots}></span>
										<span className={styles.messageLoaderDots}></span>
									</>
									:
									<Fade in={true} timeout={300} mountOnEnter unmountOnExit>
										<span>{replica.message}</span>
									</Fade>
								}
								{replica.withButtons && replica.withButtons.length > 0 &&
									<div className={styles.messageTextButtons}>
										{replica.withButtons.map(({ buttonName, messageToSend }) => (
											<button
												key={buttonName}
												className={styles.messageTextButton}
												type='button'
												onClick={() => submitHandler({ message: messageToSend })}
												disabled={debuggerAnswer.dialog.length - 1 !== idx}
											>
												{buttonName}
											</button>
										))}
									</div>
								}
							</>
						}
					</div>
				</div>
			))}

			{/* аудио воспроизведение */}
			{debuggerAnswer.playbackQueue.length > 0 &&
				<div className={styles.messageItemAudioHidden}>
					<AudioPlayer
						url={debuggerAnswer.playbackQueue[0]}
						downloadOption={false} // отключение опции скачивания
						index={indexPlayingAudio} // индекс для идентификации
						setIndexPlayingAudio={setIndexPlayingAudio} // установка воспроизведения по порядку
						play={true} // команда старта воспроизведения
						smallSize
						onPlaybackEnd={() => {
							dispatch(deletionAudioFromPlaybackQueue());
						}}
					/>
				</div>
			}
		</div>
	);
};

export default MessageList;
