import { useEffect, useState } from 'react';
import { Autocomplete, Button, ButtonGroup, Chip, createFilterOptions, Fade, FormControl, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addConditionEntry, addConditionRun, addEndpoint, changeTimerInactivity, clearEndpointElem, clearEntry, deleteEndpoint, editEndpoint, editEndpointActions, editEndpointEntry, editEndpointName, editEndpointRun, editEndpointType, replaceEndpointActionList, replaceEndpointEntryActionList, replaceEndpointRunInEntryList, selectDataList, selectEditingEndpoint, selectEndpointElem } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion, } from '../../store/sesRobotSlice';
import { selectChannelList } from '../../store/qasSlice';
import { getClusterServerModels, selectClusterServer } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SERVER, SES } from '../../constants/accessRights';
import { ENPOINT_ENTRY_TYPES_LIST, ENPOINT_TYPES_LIST } from '../../constants/robotConfigLists';
import { colorPrimary, colorSecondary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ConditionBlock from '../ConditionBlock/ConditionBlock';
import ActionEvent from '../ActionEvent/ActionEvent';
import FormAddingAction from '../Forms/FormAddingAction/FormAddingAction';
import FormAddingCondition from '../Forms/FormAddingCondition/FormAddingCondition';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { ConditionVariantType, EndpointType, EntryType, IConditionData, IConditionVariable, IEntryConditions, IEntryInactivity } from '../../types/sesTypes';
import { IConfigEndpointProps } from './ConfigEndpoint.props';
import styles from './ConfigEndpoint.module.scss';

const ConfigEndpoint = ({ showEndpointElem, setShowEndpointElem }: IConfigEndpointProps): JSX.Element => {
	const [inputEndpointName, setInputEndpointName] = useState<string>(''); // название конечной точки
	const [selectEndpointType, setSelectEndpointType] = useState<EndpointType | ''>(''); // тип конечной точки

	const [showComment, setShowComment] = useState<boolean>(false); // показ комментария
	const [inputComment, setInputComment] = useState<string>(''); // комментарий

	const [selectEntryType, setSelectEntryType] = useState<EntryType | ''>(''); // тип входа
	const [timer, setTimer] = useState<number>(0); // таймер неактивности
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал
	const [inputFilterByDataElem, setInputFilterByDataElem] = useState<string[]>([]); // значения для фильтрации по элементам

	const [visibleBlockConfig, setVisibleBlockConfig] = useState<'entry' | 'run' | 'actions'>('entry'); // отображение условий/действий
	const [changeFlg, setChangeFlg] = useState<{ thisIs: boolean, listOfChanges: string[] }>({ thisIs: false, listOfChanges: [] }); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения
	const [showAlertDialogDel, setShowAlertDialogDel] = useState<boolean>(false); // показ диалогового окна при удалении конечной точки
	const [showAlertDialogChangeEntryData, setShowAlertDialogChangeEntryData] = useState<{ isShow: boolean, entryType: EntryType }>({ isShow: false, entryType: 'conditions' }); // показ диалогового окна при смене данных входа

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const endpointElem = useAppSelector(selectEndpointElem); // store - конечная точка
	const editingEndpoint = useAppSelector(selectEditingEndpoint); // store - статус изменения конечной точки
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const { tts: clusterServerTts } = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за открытием вкладки
	useEffect(() => {
		// при закрытии
		if (!showEndpointElem) {
			dispatch(clearEndpointElem()); // очищаем данные конечной точки
			setVisibleBlockConfig('entry'); // сбрасываем tab
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг возможности сохранения
			// очищаем поля
			setInputEndpointName('');
			setSelectEndpointType('');
			setShowComment(false);
			setInputComment('');
			setSelectEntryType('');
			setTimer(0);
			setInputChannel('default');
			setInputFilterByDataElem([]);
		}
	}, [showEndpointElem]);

	// следим за получением КТ
	useEffect(() => {
		// если есть данные - вписываем в поля
		if (endpointElem.endpoint) {
			!inputEndpointName && setInputEndpointName(endpointElem.endpoint.name);
			!selectEndpointType && setSelectEndpointType(endpointElem.endpoint.type);
			!inputComment && endpointElem.endpoint.comment && setInputComment(endpointElem.endpoint.comment);
			!selectEntryType && setSelectEntryType(endpointElem.endpoint.entryType);
			if (isAccess(SERVER.MODEL_LIST)) {
				clusterServerTts.modelList.length === 0 && dispatch(getClusterServerModels({ serviceType: 'tts' })); // получаем список моделей tts
			}
		}
		// для отложенных сообщений
		if (visibleBlockConfig === 'entry' && (endpointElem.endpoint?.entryType === 'inactivity' || selectEntryType === 'inactivity')) {
			setTimer((endpointElem.endpoint?.entry as IEntryInactivity[])?.find(entryData => entryData.channel === inputChannel)?.timer || 0); // пишем таймер
		}
	}, [endpointElem.endpoint]);

	// следим за каналом
	useEffect(() => {
		// для отложенных сообщений
		if (visibleBlockConfig === 'entry' && selectEntryType === 'inactivity') {
			setTimer((endpointElem.endpoint?.entry as IEntryInactivity[])?.find(entryData => entryData.channel === inputChannel)?.timer || 0); // пишем таймер
		}
	}, [inputChannel]);

	// следим за статусом сохранения КТ
	useEffect(() => {
		// если изменение конечной точки прошло успешно
		if (editingEndpoint.status === RequestStatus.IDLE && editingEndpoint.error === ResponseStatus.SUCCESS && editingEndpoint.message !== '') {
			if (endpointElem.endpoint) {
				changeFlg.listOfChanges.includes('name') && dispatch(editEndpointName({ endpointId: endpointElem.endpoint.id, endpointName: inputEndpointName })); // если изменилось имя - меняем в store
				changeFlg.listOfChanges.includes('type') && dispatch(editEndpointType({ endpointId: endpointElem.endpoint.id, endpointType: selectEndpointType as EndpointType })); // если изменился тип - меняем в store
				changeFlg.listOfChanges.includes('entry') && dispatch(editEndpointEntry({ endpointId: endpointElem.endpoint.id, conditionEntry: endpointElem.endpoint.entry, entryType: selectEntryType as EntryType })); // если изменились условия ENTRY - меняем в store
				changeFlg.listOfChanges.includes('run') && dispatch(editEndpointRun({ endpointId: endpointElem.endpoint.id, conditionRun: endpointElem.endpoint.run })); // если изменились условия RUN - меняем в store
				changeFlg.listOfChanges.includes('actions') && dispatch(editEndpointActions({ endpointId: endpointElem.endpoint.id, actions: endpointElem.endpoint.actions })); // если изменились действия - меняем в store
			}
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
		}
	}, [editingEndpoint]);

	// обработчик изменения данных входа
	const changeEntryDataHandler = (): void => {
		setSelectEntryType(showAlertDialogChangeEntryData.entryType); // меняем тип входа
		dispatch(clearEntry()); // очистка данных входа
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entryType')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entryType'] })); // ставим флаг о несохраненных данных
		setShowAlertDialogChangeEntryData(prev => ({ ...prev, isShow: false })); // закрываем диалогове окно
	};

	// функция, определяющая отфильтрованные параметры, которые будут отображаться при поиске
	const filter = createFilterOptions<string>();

	// обработчик добавления КТ
	const addEndpointHandler = (): void => {
		activeRobotId && dispatch(addEndpoint({
			robotId: activeRobotId,
			data: {
				name: inputEndpointName,
				entryType: selectEntryType as EntryType,
				entry: endpointElem.endpoint?.entry || [],
				run: endpointElem.endpoint?.run || [],
				actions: endpointElem.endpoint?.actions || {},
				type: selectEndpointType as EndpointType,
				comment: inputComment,
			}
		})); // добавляем точку
	};

	// обработчик сохранения КТ
	const editEndpointHandler = (): void => {
		endpointElem.endpoint && activeRobotId && dispatch(editEndpoint({
			robotId: activeRobotId,
			endpointId: endpointElem.endpoint.id,
			data: {
				name: changeFlg.listOfChanges.includes('name') ? inputEndpointName : undefined,
				entryType: changeFlg.listOfChanges.includes('entryType') ? selectEntryType as EntryType : undefined,
				entry: changeFlg.listOfChanges.includes('entryType') || changeFlg.listOfChanges.includes('entry') ? endpointElem.endpoint.entry : undefined,
				run: changeFlg.listOfChanges.includes('run') ? endpointElem.endpoint.run : undefined,
				actions: changeFlg.listOfChanges.includes('actions') ? endpointElem.endpoint.actions : undefined,
				type: changeFlg.listOfChanges.includes('type') ? selectEndpointType as EndpointType : undefined,
				comment: changeFlg.listOfChanges.includes('comment') ? inputComment : undefined,
			}
		})); // изменяем КТ
	};

	// обработчик удаления КТ
	const deleteEndpointHandler = (): void => {
		setShowAlertDialogDel(false); // закрываем диалоговое окно
		endpointElem.endpoint && activeRobotId && dispatch(deleteEndpoint({ robotId: activeRobotId, endpointId: endpointElem.endpoint.id })); // удаление конечной точки
		closeHandler(true); // закрываем вкладку
	};

	// обработчик добавления условия ENTRY
	const addConditionEntryHandler = (conditionBlockIdx: number, data?: ConditionVariantType): void => {
		dispatch(addConditionEntry({
			conditionBlockIdx,
			data: data as (IConditionData | IConditionVariable) || {
				type: 'data',
				depth: 5,
				id: '',
				operation: 'exists',
				value: '',
			}
		})); // добавляем условие
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик добавления условия RUN
	const addConditionRunHandler = (conditionBlockIdx: number, data?: ConditionVariantType): void => {
		dispatch(addConditionRun({
			conditionBlockIdx,
			data: data as (IConditionData | IConditionVariable) || {
				type: 'data',
				depth: 5,
				id: '',
				operation: 'exists',
				value: '',
			}
		})); // добавляем условие }));
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик перемещения действий
	const moveAction = (dragIndex: number, hoverIndex: number) => {
		if (endpointElem.endpoint) {
			dispatch(replaceEndpointActionList({
				channel: inputChannel,
				actionList: update(endpointElem.endpoint.actions[inputChannel], {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, endpointElem.endpoint.actions[inputChannel][dragIndex]],
					],
				}),
			})); // изменение последовательности действий
			endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] })); // ставим флаг о несохраненных данных
		}
	};

	// обработчик перемещения условий
	const moveCondition = (dragIndex: number, hoverIndex: number, conditionEntryBlockIdx: number) => {
		if (endpointElem.endpoint) {
			dispatch(replaceEndpointRunInEntryList({
				conditionEntryBlockIdx,
				runList: update((endpointElem.endpoint.entry as IEntryConditions[])[conditionEntryBlockIdx].run, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, (endpointElem.endpoint.entry as IEntryConditions[])[conditionEntryBlockIdx].run[dragIndex]],
					],
				}),
			})); // изменение последовательности условий
			endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] })); // ставим флаг о несохраненных данных
		}
	};

	// обработчик перемещения действий входа
	const moveActionEntry = (dragIndex: number, hoverIndex: number) => {
		if (endpointElem.endpoint) {
			const entryInactivityList = (endpointElem.endpoint.entry as IEntryInactivity[]);
			const indexInactivityItem = entryInactivityList.findIndex(inactivityItem => inactivityItem.channel === inputChannel);
			dispatch(replaceEndpointEntryActionList({
				channel: inputChannel,
				actionList: update(entryInactivityList[indexInactivityItem].actions, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, entryInactivityList[indexInactivityItem].actions[dragIndex]],
					],
				}),
			})); // изменение последовательности действий
			endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] })); // ставим флаг о несохраненных данных
		}
	};

	// обработчик закрытия вкладки
	const closeHandler = (force: boolean = false): void => {
		// если нет изменений или принудительное закрытие
		(!changeFlg.thisIs || force) && setShowEndpointElem(false);
	};

	return (
		<Slide direction="up" in={showEndpointElem} mountOnEnter unmountOnExit>
			<div className={cn(styles.modal, {
				[styles.modalDarkBackground]: changeFlg.thisIs, // темный фон, когда есть изменения
			})} onMouseDown={() => closeHandler()}>
				<div className={styles.data} onMouseDown={(e) => e.stopPropagation()}>

					{/* загрузка конечной точки */}
					{endpointElem.status === RequestStatus.LOADING &&
						<div className={styles.loading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* ошибка получения конечной точки */}
					{(endpointElem.status === RequestStatus.FAILED || endpointElem.error === ResponseStatus.FAILED) &&
						<div className={styles.failed}>
							{translate(endpointElem.message || 'title_loadFailed')}
						</div>
					}

					{/* конечная точка */}
					{endpointElem.endpoint &&
						<Fade in={true} timeout={500}>
							<div className={styles.dataWrapper}>
								<div className={styles.dataTop}>
									<div className={styles.dataTopMain}>
										<div className={styles.dataTopMainBlock}>
											{/* название */}
											<FormControl sx={{ marginTop: '8px', flexGrow: 1 }}>
												<TextField
													required
													label={translate('input_name')}
													variant="outlined"
													disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
													value={inputEndpointName}
													onChange={(e) => {
														setInputEndpointName(e.target.value);
														endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('name')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'name'] }));  // ставим флаг о несохраненных данных;
													}}
													InputProps={{
														style: {
															height: 33,
															fontSize: 13,
															color: colorPrimary,
														},
													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											</FormControl>

											{/* тип */}
											<FormControl sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, marginTop: '8px', flexShrink: 0, '.MuiSelect-select': { paddingBlock: 0 }, }}>
												<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
												<Select
													label={translate('select_type')}
													value={selectEndpointType}
													onChange={(e) => {
														setSelectEndpointType(e.target.value as EndpointType);
														endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('type')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'type'] })); // ставим флаг о несохраненных данных
													}}
													disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
													style={{ fontSize: 13, height: 33, color: colorPrimary }}
												>
													{ENPOINT_TYPES_LIST.map(({ type, translation }) =>
														<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
													)}
												</Select>
											</FormControl>

											{/* тип входа */}
											<FormControl sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, marginTop: '8px' }}>
												<InputLabel sx={{ fontSize: 13 }}>{translate('select_entry')}</InputLabel>
												<Select
													label={translate('select_entry')}
													value={selectEntryType}
													onChange={(e) => setShowAlertDialogChangeEntryData({ isShow: true, entryType: e.target.value as EntryType })}
													disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
													style={{ fontSize: 13, height: 33, color: colorPrimary }}
												>
													{ENPOINT_ENTRY_TYPES_LIST.map(({ type, translation }) =>
														<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
													)}
												</Select>
											</FormControl>

											{/* кнопка показа комментария */}
											<FontAwesomeIcon
												icon={inputComment ? faCommentDots : faComment}
												color={inputComment ? colorSecondary : colorPrimary}
												size="2xl"
												onClick={() => setShowComment(prev => !prev)}
												title={translate(showComment ? 'buttonTitle_hideComment' : 'buttonTitle_showComment')}
												style={{ cursor: 'pointer', marginTop: '4px' }}
											/>
										</div>

										{/* комментарий */}
										{showComment &&
											<FormControl fullWidth>
												<TextField
													multiline
													maxRows={3}
													label={translate('input_comment')}
													variant="outlined"
													disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
													value={inputComment}
													onChange={(e) => {
														setInputComment(e.target.value);
														endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('comment')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'comment'] }));  // ставим флаг о несохраненных данных
													}}
													InputProps={{
														style: {
															padding: '8px 13px',
															fontSize: 13,
															color: colorPrimary,
														},
													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											</FormControl>
										}

										<ButtonGroup fullWidth>
											<Button
												variant={visibleBlockConfig === 'entry' ? "contained" : "outlined"}
												sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('entry')}
											>
												{translate(selectEntryType === 'conditions' ? 'button_conditionsEntryAndRun' : 'button_entryConditions')}
												{endpointElem.endpoint.entryType === 'conditions' && endpointElem.endpoint.entry.length > 0 &&
													<> ({endpointElem.endpoint.entry.length})</>
												}
											</Button>
											{selectEntryType === 'inactivity' &&
												<Button
													variant={visibleBlockConfig === 'run' ? "contained" : "outlined"}
													sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
													onClick={() => setVisibleBlockConfig('run')}
												>
													{translate('button_runConditions')}
													{endpointElem.endpoint.run.length > 0 &&
														<> ({endpointElem.endpoint.run.length})</>
													}
												</Button>
											}
											<Button
												variant={visibleBlockConfig === 'actions' ? "contained" : "outlined"}
												sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('actions')}
											>
												{translate('button_actions')}
											</Button>
										</ButtonGroup>

										{/* для таба "условия входа" */}
										{visibleBlockConfig === 'entry' &&
											<>
												{/* фильтр условий входа */}
												{endpointElem.endpoint.id && selectEntryType === 'conditions' &&
													<Autocomplete
														multiple
														freeSolo
														autoHighlight
														openOnFocus
														options={dataElemList.data.map(dataElemItem => dataElemItem.name)}
														value={inputFilterByDataElem}
														onChange={(_, value) => setInputFilterByDataElem(value)}
														filterSelectedOptions
														filterOptions={(options, state) => {
															const filtered = filter(options, state);
															if (state.inputValue.length > 0 && options.findIndex(className => className === state.inputValue) === -1) filtered.push(state.inputValue);
															return filtered;
														}}
														renderInput={(params) =>
															<TextField
																{...params}
																label={translate('input_filterByDataElements')}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														}
														sx={{
															".MuiInputBase-root": { minHeight: 33, fontSize: 13, color: colorPrimary },
															".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "30px!important" },
															flexGrow: 1,
														}}
														getOptionLabel={option => option}
														renderOption={(props, option, _state, ownerState) => {
															const match = ownerState.options.filter(classItem => classItem === option);
															return (
																<li {...props} style={{ padding: '2px 5px', textAlign: 'left', fontSize: 12, color: colorPrimary }}>
																	{match.length === 0 ?
																		<>{translate('selectItem_addFilter')} "{option}"</>
																		:
																		<>{option}</>
																	}
																</li>
															);
														}}
														renderTags={(value: readonly string[], getTagProps) =>
															value.map((option: string, index: number) => (
																<Chip label={option} {...getTagProps({ index })} sx={{ height: 17, color: colorPrimary }} />
															))
														}
													/>
												}
												{selectEntryType === 'inactivity' &&
													<div className={styles.dataTopMainBlock}>
														{/* канал */}
														<FormControl sx={{ flexGrow: 1 }}>
															<Autocomplete
																freeSolo={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
																options={Array.from(
																	new Set(
																		((endpointElem.endpoint.entry as IEntryInactivity[]).map(entryData => entryData.channel))
																			.concat(channelList.data.map(channel => channel.name), 'default')
																	))}
																value={inputChannel}
																onChange={(_, value) => setInputChannel(value ? value : '')}
																noOptionsText={<div className={styles.dataTopActionsNoDataTitle}>{translate('title_notFound')}</div>}
																renderInput={(params) =>
																	<TextField
																		{...params}
																		label={translate('input_channel')}
																		onChange={(e) => isAccess(SES.ENDPOINT_EDIT) && setInputChannel(e.target.value)}
																		InputLabelProps={{
																			style: {
																				fontSize: 13,
																			},
																		}}
																		sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
																	/>
																}
																sx={{
																	".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
																	".MuiInputBase-input": { marginTop: -1 },
																}}
																getOptionLabel={option => option}
																renderOption={(props, option) => {
																	return (
																		<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																			{option}
																		</span>
																	);
																}}
															/>
														</FormControl>
														{/* таймер */}
														<FormControl sx={{ width: 100 }}>
															<TextField
																label={translate('input_timer')}
																variant="outlined"
																type='number'
																value={timer}
																onChange={(e) => setTimer(+e.target.value)}
																onBlur={e => {
																	dispatch(changeTimerInactivity({ channel: inputChannel, timer: +e.target.value }));
																	endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
																}}
																error={timer < 0}
																disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
																InputProps={{
																	style: {
																		height: 33,
																		fontSize: 13,
																		color: colorPrimary,
																	},
																	inputProps: { min: 0 },
																}}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														</FormControl>
													</div>
												}
											</>
										}

										{/* для таба "действия" */}
										{visibleBlockConfig === 'actions' &&
											// канал
											<FormControl fullWidth>
												<Autocomplete
													freeSolo={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
													options={Array.from(
														new Set(
															(Object.keys(endpointElem.endpoint.actions || {}))
																.concat(channelList.data.map(channel => channel.name), 'default')
														))}
													value={inputChannel}
													onChange={(_, value) => setInputChannel(value ? value : '')}
													noOptionsText={<div className={styles.dataTopActionsNoDataTitle}>{translate('title_notFound')}</div>}
													renderInput={(params) =>
														<TextField
															{...params}
															label={translate('input_channel')}
															onChange={(e) => isAccess(SES.ENDPOINT_EDIT) && setInputChannel(e.target.value)}
															InputLabelProps={{
																style: {
																	fontSize: 13,
																},
															}}
															sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
														/>
													}
													sx={{
														".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
														".MuiInputBase-input": { marginTop: -1 },
													}}
													getOptionLabel={option => option}
													renderOption={(props, option) => {
														return (
															<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																{option}
															</span>
														);
													}}
												/>
											</FormControl>
										}
									</div>

									{visibleBlockConfig === 'entry' &&
										<div className={styles.dataTopEntry}>
											{selectEntryType === 'conditions' &&
												<>
													<DndProvider backend={HTML5Backend}>
														{(endpointElem.endpoint.entry as IEntryConditions[]).map((conditionDataBlock, conditionBlockIdx, arrayConditionsData) => {
															// фильтрация по элементам данных
															const suitableCondition = inputFilterByDataElem.filter(filterName => conditionDataBlock.conditions
																.map(condition => dataElemList.dictionary[condition.id] || condition.id) // перевод id -> name
																.find(dataElemName => dataElemName.toLowerCase().includes(filterName.toLowerCase()))
															).length === inputFilterByDataElem.length;
															if (suitableCondition) {
																return (
																	// блоки ИЛИ
																	<ConditionBlock
																		conditionDataBlock={conditionDataBlock.conditions}
																		conditionBlockIdx={conditionBlockIdx}
																		isDisable={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
																		isAvailable={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
																		changeFlg={changeFlg}
																		setChangeFlg={setChangeFlg}
																		conditionFor={{
																			for: 'endpointEntry',
																			moveCondition,
																		}}
																		addConditionRunHandler={addConditionEntryHandler}
																		arrayConditionsData={arrayConditionsData}
																		key={conditionDataBlock.toString() + conditionBlockIdx}
																	/>
																);
															} else return undefined;
														})}
													</DndProvider>

													{/* форма добавления условия */}
													{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' && inputFilterByDataElem.length === 0 &&
														<FormAddingCondition
															conditionForFormVariant={endpointElem.endpoint.entry.length === 0}
															addCondition={(data) => addConditionEntryHandler(endpointElem.endpoint?.entry?.length || 0, data)}
															listOfSkipConditions={['interval']}
														/>
													}
												</>
											}
											{selectEntryType === 'inactivity' &&
												<div className={styles.dataTopActionsList}>
													<>
														<DndProvider backend={HTML5Backend}>
															{(endpointElem.endpoint.entry as IEntryInactivity[])?.length > 0 && (endpointElem.endpoint.entry as IEntryInactivity[]).find(entryData => entryData.channel === inputChannel)?.actions?.map((action, idx) => (
																<ActionEvent
																	key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}${action.action === 'transfer' && action.destination}`}
																	action={action}
																	idx={idx}
																	channel={inputChannel}
																	changeFlg={changeFlg}
																	setChangeFlg={setChangeFlg}
																	actionFor={{
																		for: 'endpointEntry',
																	}}
																	moveAction={moveActionEntry}
																/>
															))}
														</DndProvider>

														{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
															<FormAddingAction
																channel={inputChannel}
																changeFlg={changeFlg}
																setChangeFlg={setChangeFlg}
																actionFor={{
																	for: 'endpointEntry',
																}}
															/>
														}
													</>
												</div>
											}
										</div>
									}

									{visibleBlockConfig === 'run' &&
										<div className={styles.dataTopRun}>
											<DndProvider backend={HTML5Backend}>
												{endpointElem.endpoint.run.map((conditionDataBlock, conditionBlockIdx, arrayConditionsData) => (
													// блоки ИЛИ
													<ConditionBlock
														conditionDataBlock={conditionDataBlock}
														conditionBlockIdx={conditionBlockIdx}
														isDisable={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
														isAvailable={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
														conditionFor={{
															for: 'endpointRun',
														}}
														addConditionRunHandler={addConditionRunHandler}
														arrayConditionsData={arrayConditionsData}
														key={conditionDataBlock.toString() + conditionBlockIdx}
													/>
												))}
											</DndProvider>

											{/* форма добавления условия */}
											{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
												<FormAddingCondition
													conditionForFormVariant={endpointElem.endpoint.run.length === 0}
													addCondition={(data) => addConditionRunHandler(endpointElem.endpoint?.run?.length || 0, data)}
													listOfSkipConditions={['interval']}
												/>
											}
										</div>
									}

									{visibleBlockConfig === 'actions' &&
										<div className={styles.dataTopActions}>

											{/* действия */}
											<div className={styles.dataTopActionsList}>
												<DndProvider backend={HTML5Backend}>
													{endpointElem.endpoint.actions[inputChannel]?.length > 0 && endpointElem.endpoint.actions[inputChannel].map((action, idx) => (
														<ActionEvent
															key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}${action.action === 'transfer' && action.destination}`}
															action={action}
															idx={idx}
															channel={inputChannel}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
															actionFor={{
																for: 'endpoint',
															}}
															moveAction={moveAction}
														/>
													))}
												</DndProvider>

												{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
													<FormAddingAction
														channel={inputChannel}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
														actionFor={{
															for: 'endpoint',
														}}
													/>
												}
											</div>
										</div>
									}
								</div>

								{activeRobotVersion === 'draft' &&
									<div className={styles.dataButtons}>
										{endpointElem.endpoint.id ?
											<>
												{/* сохранить */}
												{isAccess(SES.ENDPOINT_EDIT) &&
													<FormControl fullWidth>
														<Button
															variant="outlined"
															disabled={!changeFlg.thisIs}
															sx={{ fontSize: 11 }}
															onClick={editEndpointHandler}
														>
															{translate('button_save')}
														</Button>
													</FormControl>
												}
												{/* закрыть */}
												<FormControl fullWidth>
													<Button
														variant="outlined"
														sx={{ fontSize: 11 }}
														onClick={() => closeHandler(true)}
													>
														{translate('button_close')}
													</Button>
												</FormControl>
												{/* удалить */}
												{isAccess(SES.ENDPOINT_DELETE) &&
													<FormControl fullWidth>
														<Button
															variant="outlined"
															sx={{ fontSize: 11 }}
															onClick={() => setShowAlertDialogDel(true)}
															color='error'
														>
															{translate('button_delete')}
														</Button>
													</FormControl>
												}
											</>
											:
											// добавить
											isAccess(SES.ENDPOINT_ADD) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													sx={{ fontSize: 11 }}
													onClick={addEndpointHandler}
												>
													{translate('button_addEndpoint')}
												</Button>
											</FormControl>
										}
									</div>
								}
							</div>
						</Fade>
					}

					{/* смена типа условий входа */}
					<AlertDialog
						showAlertDialog={showAlertDialogChangeEntryData.isShow}
						closeHandler={() => setShowAlertDialogChangeEntryData(prev => ({ ...prev, isShow: false }))}
						submitHandler={changeEntryDataHandler}
						title='dialog_changeEntryType'
						description='dialog_changeEntryTypeConfirm'
						name={endpointElem.endpoint?.name}
					/>

					{/* удаление конечной точки */}
					<AlertDialog
						showAlertDialog={showAlertDialogDel}
						setShowAlertDialog={setShowAlertDialogDel}
						submitHandler={deleteEndpointHandler}
						title='dialog_deleteEndpoint'
						description='dialog_deleteEndpointConfirm'
						name={endpointElem.endpoint?.name}
					/>
				</div>
			</div>
		</Slide>
	);
};

export default ConfigEndpoint;
