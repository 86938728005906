import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_SERVER, URL_SERVER } from '../constants/api_endpoints';
import { IGetServersProps, IDataServers, ServerResponseType, IServerState, IGetTrainerServerModelsProps, IGetClusterServerModelsProps } from '../types/serverTypes';
import { IResponse, RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: IServerState = {
  dataServers: null,
  trainerServer: {
    data: null,
    status: RequestStatus.IDLE,
  },
  clusterServer: {
    smc: {
      data: null,
      modelList: [],
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    see: {
      data: null,
      modelList: [],
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    spr: {
      data: null,
      modelList: [],
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    sbs: {
      data: null,
      modelList: [],
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    tts: {
      data: null,
      modelList: [],
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    qas: {
      data: null,
      modelList: [],
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    ses: {
      data: null,
      modelList: [],
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    res: {
      data: null,
      modelList: [],
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
  },
};

// получение данных по всем серверам сервиса
export const getDataServers = createAsyncThunk(
  'servers/getServers',
  async ({ serviceType }: IGetServersProps): Promise<IDataServers> => {
    const response: AxiosResponse<IDataServers> = await axiosApi.get(`${URL_SERVER}/${ENDPOINTS_SERVER.ADDRESSES}/${serviceType}`);
    return response.data;
  }
);

// получение списка моделей серверов обучения (не используется)
export const getTrainerServerModels = createAsyncThunk(
  'servers/getTrainerServerModels',
  async ({ serviceType }: IGetTrainerServerModelsProps): Promise<ServerResponseType> => {
    const response: AxiosResponse<ServerResponseType> = await axiosApi.get(`${URL_SERVER}/${ENDPOINTS_SERVER.MODELS}/${serviceType}/trainer`);
    return response.data;
  }
);

// получение списка моделей серверов обработки
export const getClusterServerModels = createAsyncThunk(
  'servers/getClusterServerModels',
  async ({ serviceType }: IGetClusterServerModelsProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ServerResponseType | IResponse> = await axiosApi.get(`${URL_SERVER}/${ENDPOINTS_SERVER.MODELS}/${serviceType}/cluster`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const serverSlice = createSlice({
  name: 'servers',
  initialState,
  reducers: {
    // очистка данных по серверам
    clearDataServers: (state) => {
      state.dataServers = initialState.dataServers;
    },
    // очистка списка моделей серверов обучения
    clearTrainerServer: (state) => {
      state.trainerServer = initialState.trainerServer;
    },
    // очистка списка моделей серверов обработки
    clearClusterServer: (state) => {
      state.clusterServer = initialState.clusterServer;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataServers.fulfilled, (state, action) => {
        state.dataServers = action.payload;
      })
      .addCase(getDataServers.rejected, (state) => {
        state.dataServers = null;
      })
      .addCase(getTrainerServerModels.pending, (state) => {
        state.trainerServer.status = RequestStatus.LOADING;
      })
      .addCase(getTrainerServerModels.fulfilled, (state, action) => {
        state.trainerServer.status = RequestStatus.IDLE;
        state.trainerServer.data = action.payload;
      })
      .addCase(getTrainerServerModels.rejected, (state) => {
        state.trainerServer.status = RequestStatus.FAILED;
        state.trainerServer.data = initialState.trainerServer.data;
      })
      .addCase(getClusterServerModels.pending, (state, action) => {
        state.clusterServer[action.meta.arg.serviceType].status = RequestStatus.LOADING;
      })
      .addCase(getClusterServerModels.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && !('message' in action.payload)) {
          state.clusterServer[action.meta.arg.serviceType].status = RequestStatus.IDLE;
          state.clusterServer[action.meta.arg.serviceType].data = action.payload;
          if (Object.keys(action.payload).length > 0) {
            const clusterServers = Object.keys(action.payload); // сервера cluster'а
            const modelList: string[] = []; // список моделей
            clusterServers.forEach(server => {
              (action.payload as ServerResponseType)[server]?.forEach(modelName => {
                !modelList.includes(modelName) && modelList.push(modelName); // оставляем все уникальные модели
              });
            });
            state.clusterServer[action.meta.arg.serviceType].modelList = modelList.sort();
          }
        } else {
          state.clusterServer[action.meta.arg.serviceType].status = RequestStatus.FAILED;
        }
      })
      .addCase(getClusterServerModels.rejected, (state, action) => {
        state.clusterServer[action.meta.arg.serviceType].status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.clusterServer[action.meta.arg.serviceType].error = action.payload.response?.data.error;
          state.clusterServer[action.meta.arg.serviceType].message = action.payload.response?.data.message;
        }
      });
  },
});

export const { clearDataServers, clearTrainerServer, clearClusterServer } = serverSlice.actions;

export const selectDataServers = (state: RootState) => state.server.dataServers;
export const selectTrainerServer = (state: RootState) => state.server.trainerServer;
export const selectClusterServer = (state: RootState) => state.server.clusterServer;

export default serverSlice.reducer;
