import { useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { changeTimeInterval, deleteTimeInterval, selectActiveRobotVersion } from '../../../../store/sesRobotSlice';
import useAccessRight from '../../../../hooks/useAccessRight';
import useTranslate from '../../../../hooks/useTranslate';
import { SES } from '../../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../../constants/colors';
import { ISliderProps } from './Slider.props';
import styles from './Slider.module.scss';

// функция перевода минут в формат HH:MM
const convertMinutesToFormatHHMM = (value: number): string => {
	const correctedTime = value >= 1440 ? 1439 : value;
	let hours: string | number = Math.trunc(correctedTime / 60);
	let minutes: string | number = correctedTime % 60;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	if (hours < 10) hours = '0' + hours;
	else if (hours === 24) hours = '00';
	return hours + ':' + minutes;
};

// функция перевода формата HH:MM в минуты
const convertFormatHHMMToMinutes = (value: string): number => {
	const hours = +(value[0] + value[1]);
	const minutes = +(value[3] + value[4]);
	const times = hours * 60 + minutes;
	return times;
};

const Slider = ({ intervalName, time, idx, array, setChangeFlg }: ISliderProps): JSX.Element => {
	const [startTime, setStartTime] = useState<string>(convertMinutesToFormatHHMM(time[0])); // время начала интервала
	const [endTime, setEndTime] = useState<string>(convertMinutesToFormatHHMM(time[1])); // время конца интервала

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const translate = useTranslate(); // hook для перевода текста
	const isAccess = useAccessRight(); // hook для проверки прав доступа

	// обработчик изменения времени
	const changeTimeHandler = (): void => {
		// если были изменения
		if (convertMinutesToFormatHHMM(time[0]) !== startTime || convertMinutesToFormatHHMM(time[1]) !== endTime) {
			const convertedStartTime = convertFormatHHMMToMinutes(startTime); // начальное время в мин
			const convertedEndTime = convertFormatHHMMToMinutes(endTime); // конечное время в мин
			// если начальное время больше или равно конечному
			if (convertedStartTime >= convertedEndTime) {
				setStartTime(endTime);
				dispatch(changeTimeInterval({
					intervalName,
					data: [convertedEndTime, convertedEndTime],
					idx
				}));
			} else {
				dispatch(changeTimeInterval({
					intervalName,
					data: [convertedStartTime, convertedEndTime],
					idx
				}));
			}
			setChangeFlg(true);
		}
	};

	// обработчик удаления времени
	const deleteTimeHandler = (): void => {
		dispatch(deleteTimeInterval({ intervalName, idx }));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.sliderBlock}>
			<FormControl fullWidth margin='dense'>
				<TextField
					required
					variant="outlined"
					type='time'
					value={startTime}
					onChange={(e) => setStartTime(e.target.value)}
					onBlur={changeTimeHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>
			-
			<FormControl fullWidth margin='dense'>
				<TextField
					required
					variant="outlined"
					type='time'
					value={endTime}
					onChange={(e) => setEndTime(e.target.value)}
					onBlur={changeTimeHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>

			{/* удаление периода */}
			{isAccess(SES.ROBOT_EDIT) && array.length > 1 &&
				<FontAwesomeIcon
					icon={faTrashCan}
					color={colorRed}
					size='lg'
					onClick={deleteTimeHandler}
					title={translate('buttonTitle_deletePeriod')}
					style={{ cursor: 'pointer' }}
				/>
			}
		</div>
	);
};

export default Slider;
