import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Divider, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faHouse, faImage, faKey, faLaptopCode, faRightFromBracket, faTable, faUser } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearAuthData, selectVersion } from '../../../../store/authSlice';
import { clearChangePasswordStatus, selectChangePasswordStatus, selectCurrentUserInfo } from '../../../../store/userSlice';
import { selectWallpaper } from '../../../../store/langSlice';
import useAccessRight from '../../../../hooks/useAccessRight';
import useTranslate from '../../../../hooks/useTranslate';
import { LOGIN, ROBOTS } from '../../../../constants/routes';
import { USER } from '../../../../constants/accessRights';
import { ACCESS_TOKEN, BACKGROUND_IMAGE, CLOUD_DATA_PRESENTATION, REFRESH_TOKEN } from '../../../../constants/cookieNames';
import { backgroundColor, colorPrimary, colorRed, colorSecondary } from '../../../../constants/colors';
import FormChangePassword from '../../../Forms/FormChangePassword/FormChangePassword';
import Notification from '../../../Notification/Notification';
import { IUserMenuProps } from './UserMenu.props';
import styles from './UserMenu.module.scss';

const UserMenu = ({ setShowPage }: IUserMenuProps): JSX.Element => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // якорь для меню пользователя
	const [showModalChangePass, setShowModalChangePass] = useState<boolean>(false); // показ формы для изменения пароля
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const dispatch = useAppDispatch();
	const currentUserInfo = useAppSelector(selectCurrentUserInfo); // store - информация о текущем пользователе
	const wallpaper = useAppSelector(selectWallpaper); // store - обои
	const version = useAppSelector(selectVersion); // store - информация о версии системы

	const navigate = useNavigate(); // hook для навигации
	const location = useLocation(); // hook для определения адреса
	const [cookies, setCookie, removeCookies] = useCookies([ACCESS_TOKEN, REFRESH_TOKEN, BACKGROUND_IMAGE, CLOUD_DATA_PRESENTATION]); // hook для работы с cookie

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик открытия формы изменения пароля
	const changePasswordHandler = (): void => {
		setAnchorEl(null); // закрываем меню
		setShowModalChangePass(true); // открываем форму
	};

	// logout
	const logout = (): void => {
		setAnchorEl(null); // закрываем меню
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			removeCookies(ACCESS_TOKEN, { path: '/' });
			removeCookies(REFRESH_TOKEN, { path: '/' });
			dispatch(clearAuthData());
			navigate(LOGIN, { state: location.pathname });
		}, 500); // после скрытия страницы переходим через 500мс
	};

	// навигация домой
	const navigateToHome = (): void => {
		setAnchorEl(null); // закрываем меню
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate('/');
		}, 500); // после скрытия страницы переходим через 500мс
	};

	// вкл/выкл фонового изображения
	const toggleBackgroundImage = (): void => {
		setCookie(
			BACKGROUND_IMAGE,
			cookies.backgroundImage === 'false' ? 'true' : 'false',
			{ path: '/', maxAge: 31_536_000 }
		); // устанавливаем cookie на год
	};

	// переключение облачного/табличного представления данных робота
	const toggleDataPresentation = (): void => {
		setCookie(
			CLOUD_DATA_PRESENTATION,
			cookies.cloudDataPresentation === 'false' ? 'true' : 'false',
			{ path: '/', maxAge: 31_536_000 }
		); // устанавливаем cookie на год
	};

	return (
		<>
			<button
				onClick={e => setAnchorEl(e.currentTarget)}
				title={translate('buttonTitle_userMenu')}
			>
				{currentUserInfo.image ?
					<div className={styles.image}>
						<img src={currentUserInfo.image} alt="avatar" />
					</div>
					:
					<FontAwesomeIcon icon={faUser} size="xl" color={backgroundColor} />
				}
			</button>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				<div>
					<Typography color={colorSecondary} marginBottom={1} padding='0 10px'>
						{currentUserInfo.fullName}
					</Typography>
					<Divider />
					{/* версия ups */}
					{version && 'version' in version &&
						<>
							<Typography color={colorPrimary} textAlign='left' padding='6px 16px'>
								<FontAwesomeIcon icon={faLaptopCode} size="lg" color={colorPrimary} style={{ marginRight: '11px' }} />
								{translate('title_version')}: {version.version}
							</Typography>
							<Divider />
						</>
					}
					{/* смена пароля */}
					{isAccess(USER.PASSWORD) &&
						<MenuItem onClick={changePasswordHandler} sx={{ color: colorPrimary }}>
							<ListItemIcon>
								<FontAwesomeIcon icon={faKey} size='lg' color={colorPrimary} />
							</ListItemIcon>
							{translate('selectItem_changePassword')}
						</MenuItem>
					}
					{/* вкл/выкл фона */}
					{wallpaper.dataUrl &&
						<MenuItem onClick={toggleBackgroundImage} sx={{ color: colorPrimary }}>
							<ListItemIcon>
								<FontAwesomeIcon icon={faImage} size="lg" color={colorPrimary} />
							</ListItemIcon>
							{translate(cookies.backgroundImage === 'false' ? 'selectItem_showBackground' : 'selectItem_hideBackground')}
						</MenuItem>
					}
					{/* переключение облачного/табличного представления данных робота */}
					{location.pathname.includes(ROBOTS) &&
						<MenuItem onClick={toggleDataPresentation} sx={{ color: colorPrimary }}>
							<ListItemIcon>
								<FontAwesomeIcon icon={cookies.cloudDataPresentation === 'false' ? faCloud : faTable} size="lg" color={colorPrimary} />
							</ListItemIcon>
							{translate(cookies.cloudDataPresentation === 'false' ? 'selectItem_cloudyView' : 'selectItem_tableView')}
						</MenuItem>
					}
					{/* на главную */}
					<MenuItem onClick={navigateToHome} sx={{ color: colorPrimary }}>
						<ListItemIcon>
							<FontAwesomeIcon icon={faHouse} size="lg" color={colorPrimary} />
						</ListItemIcon>
						{translate('selectItem_toMainPage')}
					</MenuItem>
					{/* logout */}
					<MenuItem onClick={logout} sx={{ color: colorRed }}>
						<ListItemIcon>
							<FontAwesomeIcon icon={faRightFromBracket} size='lg' color={colorRed} />
						</ListItemIcon>
						{translate('selectItem_logout')}
					</MenuItem>
				</div>
			</Menu>

			{showModalChangePass &&
				<FormChangePassword
					showModal={showModalChangePass}
					setShowModal={setShowModalChangePass}
					setShowNotification={setShowNotification}
				/>
			}

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={selectChangePasswordStatus}
					clearDataResponse={clearChangePasswordStatus}
					titleFailed='noticeChange_failed'
					titleSuccess='noticeChange_success'
				/>
			}
		</>
	);
};

export default UserMenu;
