import { useEffect, useMemo, useRef, useState } from 'react';
import { useMicVAD, utils } from "@ricky0123/vad-react";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { useAppSelector } from '../../../../store/hooks';
import { selectDebuggerAnswer } from '../../../../store/sesSlice';
import { IAudioSegment, IPreSpeechPadFrames, IVadProps } from './Vad.props';

// соединение двух Float32Array
const joinTwoFloat32Array = (a: Float32Array, b: Float32Array) => {
	const c = new Float32Array(a.length + b.length);
	c.set(a);
	c.set(b, a.length);
	return c;
};

// настройки vad по умолчанию
const vadDefaultSettings = {
	POSITIVE_SPEECH_THRESHOLD: 0.5, // определяет порог, при превышении которого считается вероятность, указывающая на наличие речи
	NEGATIVE_SPEECH_THRESHOLD: 0.35, // определяет порог, ниже которого считается вероятность, указывающая на отсутствие речи
	REDEMPTION_FRAMES: 16, // кол-во кадров с отрицательным значением речи, которое необходимо ожидать перед окончанием речевого фрагмента
	FRAME_SAMPLES: 480, // размер кадра в сэмплах
	PRE_SPEECH_PAD_FRAMES: 5, // кол-во звуковых кадров для добавления во фрагмент речи (до старта обнаружения)
	MIN_SPEECH_FRAMES: 3, // минимальное кол-во позитивных кадров речи для фрагмента речи
};

const Vad = ({ submitHandler, webSocketStatus, vadServerSettings, showChatWidget }: IVadProps): JSX.Element => {
	const vadSettings = useMemo(() => {
		const data = {
			positiveSpeechThreshold: vadDefaultSettings.POSITIVE_SPEECH_THRESHOLD,
			negativeSpeechThreshold: vadDefaultSettings.NEGATIVE_SPEECH_THRESHOLD,
			redemptionFrames: vadDefaultSettings.REDEMPTION_FRAMES,
			frameSamples: vadDefaultSettings.FRAME_SAMPLES,
			preSpeechPadFrames: vadDefaultSettings.PRE_SPEECH_PAD_FRAMES,
			minSpeechFrames: vadDefaultSettings.MIN_SPEECH_FRAMES,

			maxLength: vadServerSettings?.maxLength || 7000, // максимальная длина фрагмента, мс
			minSpeech: vadServerSettings?.minSpeech || 120, // мин. продолжительность, меньше которого не будет считаться речью, мс
			skipSilence: vadServerSettings?.skipSilence || 60, // допустимый пропуск тишины во фрагменте речи, мс
			maxSilence: vadServerSettings?.maxSilence || 700, // макс. продолжительность тишины, после которой заканчивается фрагмент, мс
			maxNumberOfFramesInSegment: 0, // max кол-во кадров во фрагменте
			maxNumberOfFramesSkipSilence: 0, // max допустимое кол-во кадров тишины
		};

		const frameLength = 1000 / (16000 / data.frameSamples); // длина кадра, мс (30мс)
		data.redemptionFrames = Math.round(data.maxSilence / frameLength); // макс кол-во кадров для тишины, после которой заканчивается фрагмент (23)
		data.minSpeechFrames = Math.round(data.minSpeech / frameLength); // мин кол-во кадров, считаемые речью (4)
		data.maxNumberOfFramesInSegment = Math.round(data.maxLength / frameLength); // 233
		data.maxNumberOfFramesSkipSilence = Math.round(data.skipSilence / frameLength); // 2
		return data;
	}, [vadServerSettings]);

	const [loadedFfmpeg, setLoadedFfmpeg] = useState(false); // статус загрузки FFmpeg
	const ffmpegRef = useRef(new FFmpeg()); // экземпляр FFmpeg

	useEffect(() => {
		loadFfmpeg();
	}, []);

	// загрузка ffmpeg
	const loadFfmpeg = async () => {
		const ffmpeg = ffmpegRef.current;
		await ffmpeg.load();
		ffmpeg.loaded && setLoadedFfmpeg(true);
	};

	// кодирование wav в opus
	const transcodeWavToOpus = async (file: Blob) => {
		const ffmpeg = ffmpegRef.current;
		await ffmpeg.writeFile('input.wav', await fetchFile(file)); // пишем исходник
		await ffmpeg.exec(['-i', 'input.wav', '-ac', '1', '-ar', '16000', '-ab', '14k', '-f', 'opus', 'output.opus']); // перекодирование
		const data = await ffmpeg.readFile('output.opus'); // чтение
		const blob = new Blob([data], { type: 'audio/webm;codecs=opus' });
		submitHandler({ audio: blob }); // отправка .opus
	};

	const [preSpeechPadFrames, setPreSpeechPadFrames] = useState<IPreSpeechPadFrames>({ frames: [], frameCounter: 0 }); // предзаписанные кадры для вставки в начало фрагмента
	const [audioSegment, setAudioSegment] = useState<IAudioSegment>({ frames: [], frameCounter: 0, leftToRecord: vadSettings.redemptionFrames }); // обнаруженный аудиофрагмент

	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота

	// сборка фрагмента
	const collectAudioSegment = (): Float32Array => {
		let counterSkipSilence = 0; // счетчик допускаемых кадров пропуска тишины
		let framesReserve: Float32Array[] = []; // резерв кадров
		let a = new Float32Array();
		// предзаписанные кадры в начале фрагмента
		preSpeechPadFrames.frames.forEach(frame => {
			a = joinTwoFloat32Array(a, frame);
		});
		// основной фрагмент 
		audioSegment.frames.forEach(([frame, isSpeech]) => {
			// если снижается вероятность речи
			if (isSpeech < vadSettings.negativeSpeechThreshold) {
				// и позволяет счетчик пропуска тишины
				if (counterSkipSilence < vadSettings.maxNumberOfFramesSkipSilence) {
					counterSkipSilence++; // увеличение счетчика тишины
					a = joinTwoFloat32Array(a, frame);
				} else {
					framesReserve.push(frame); // резервируем кадр
				}
			}
			// если продолжается речь
			else {
				// если есть резерв кадров
				if (framesReserve.length > 0) {
					// приписываем пару кадров из резерва
					framesReserve.slice(-(2/* vadSettings.maxNumberOfFramesSkipSilence */)).forEach(frame => {
						a = joinTwoFloat32Array(a, frame);
					});
					framesReserve = [];
				}
				counterSkipSilence = 0; // сброс счетчика тишины
				a = joinTwoFloat32Array(a, frame);
			}
		});
		return a;
	};

	// очистка списков
	const clearLists = (): void => {
		setPreSpeechPadFrames({ frames: [], frameCounter: 0 });
		setAudioSegment({ frames: [], frameCounter: 0, leftToRecord: vadSettings.redemptionFrames });
	};

	const vad = useMicVAD({
		// onSpeechStart: () => { }, // старт обнаружения голоса
		// событие короткого фрагмента
		onVADMisfire: () => {
			clearLists();
		},
		// событие каждого кадра
		onFrameProcessed({ isSpeech }, frame) {
			// если не превышает максимально допустимой длины записи
			if ((preSpeechPadFrames.frameCounter + audioSegment.frameCounter) <= vadSettings.maxNumberOfFramesInSegment) {
				// обнаружение речи
				if (isSpeech > vadSettings.positiveSpeechThreshold) {
					setAudioSegment(prev => ({ frames: [...prev.frames, [frame, isSpeech]], frameCounter: ++prev.frameCounter, leftToRecord: vadSettings.redemptionFrames })); // записываем кадры во фрагмент
				} else if (isSpeech < vadSettings.negativeSpeechThreshold && audioSegment.frameCounter > 0) {
					// дописывание остатка в конец фрагмента
					if (audioSegment.leftToRecord > 0) {
						setAudioSegment(prev => ({ ...prev, frames: [...prev.frames, [frame, isSpeech]], frameCounter: ++prev.frameCounter, leftToRecord: --prev.leftToRecord })); // дописываем кадры во фрагмент
					}
				} else if (audioSegment.frameCounter === 0) {
					// добавление предзаписанных кадров в память
					if (preSpeechPadFrames.frameCounter < vadSettings.preSpeechPadFrames) {
						setPreSpeechPadFrames(prev => ({ frames: [...prev.frames, frame], frameCounter: ++prev.frameCounter })); // записываем
					} else {
						setPreSpeechPadFrames(prev => {
							prev.frames.shift();
							return { ...prev, frames: [...prev.frames, frame] };
						}); // перезаписываем
					}
				}
			} else {
				vad.pause();
			}
		},
		// конец обнаружения голоса
		onSpeechEnd: (_audio) => {
			// только при открытом чате с рабочим соединением
			if (showChatWidget && webSocketStatus === 1) {
				vad.start(); // возобновляем слушание после отключения по максимальной длине аудио
				const speechSegment = collectAudioSegment();
				// samples: Float32Array, format?: number (1 === PCM), sampleRate?: number, numChannels?: number, bitDepth?: number
				const wavBuffer = utils.encodeWAV(speechSegment, /*  1, 8000, 1, 32 */);
				const file = new Blob([wavBuffer]);
				if (loadedFfmpeg) transcodeWavToOpus(file); // если загружен ffmpeg - кодируем и отправляем
				else submitHandler({ audio: file }); // иначе отправка .wav
			}
			clearLists();
		},
		positiveSpeechThreshold: vadSettings.positiveSpeechThreshold,
		negativeSpeechThreshold: vadSettings.negativeSpeechThreshold,
		redemptionFrames: vadSettings.redemptionFrames,
		frameSamples: vadSettings.frameSamples,
		preSpeechPadFrames: vadSettings.preSpeechPadFrames,
		minSpeechFrames: vadSettings.minSpeechFrames,
		submitUserSpeechOnPause: true, // срабатывание события onSpeechEnd при остановке vad
	});

	// следим за концом сессии и статусом соединения
	useEffect(() => {
		// если конец сессии или нет соединения
		if (debuggerAnswer.endOfSession || webSocketStatus !== 1) {
			vad.listening && vad.pause(); // выключаем микрофон, если включен
		}
		// если соединение восстановлено
		if (webSocketStatus === 1) {
			!vad.listening && !vad.loading && vad.start();
		}
	}, [debuggerAnswer.endOfSession, webSocketStatus]);

	// следим за открытием чата
	useEffect(() => {
		showChatWidget ? vad.start() : vad.pause();
	}, [showChatWidget]);

	return (
		<>
		</>
	);
};

export default Vad;
