import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Popover, Select, TextField } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faGear, faMicrophone, faPaperPlane, faPause, faStop } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addDataUrl, clearRecognitionData, recognizeSpeech, selectRecognitionData, setAsyncMode } from '../../../store/sprSlice';
import { clearApplyResponse, clearDeleteResponse, deleteModel, selectApplyStatus, selectDeleteStatus } from '../../../store/modelSlice';
import { selectClusterServer } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { MODEL } from '../../../constants/accessRights';
import { backgroundColor } from '../../../constants/colors';
import { RequestStatus } from '../../../types/statusTypes';
import AudioVisualizer from '../../AudioVisualizer/AudioVisualizer';
import AudioPlayer from '../../AudioPlayer/AudioPlayer';
import PopupResult from './PopupResult/PopupResult';
import Import from '../../Controls/Buttons/Import/Import';
import Delete from '../../Controls/Buttons/Delete/Delete';
import styles from './FormSendAudioSpr.module.scss';

const FormSendAudioSpr = (): JSX.Element => {
	const [selectModel, setSelectModel] = useState<string>(''); // имя продовой модели

	const [file, setFile] = useState<File | null>(); // аудио-файл
	const [definitionOfSpeakers, setDefinitionOfSpeakers] = useState<boolean>(false); // флаг определения спикеров
	const [normalization, setNormalization] = useState<boolean>(false); // флаг нормализации
	const [punctuation, setPunctuation] = useState<boolean>(true); // флаг пунктуации
	const [speakerCounterFlg, setSpeakerCounterFlg] = useState<boolean>(false); // флаг указания кол-ва спикеров
	const [speakerCounter, setSpeakerCounter] = useState<number>(1); // кол-во спикеров
	const [toxicity, setToxicity] = useState<boolean>(true); // анализ на токсичность
	const [voiceAnalyzer, setVoiceAnalyzer] = useState<boolean>(true); // голосовой анализ
	const [emotion, setEmotion] = useState<boolean>(true); // текстовый анализ

	const settingsMenuAnchorRef = useRef<HTMLButtonElement>(null); // якорь для меню настроек
	const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState<HTMLElement | null>(null); // блок меню настроек

	const formRef = useRef<HTMLFormElement>(null); // ссылка на форму
	const [openPopup, setOpenPopup] = useState(false); // показ всплывающего окна с распознаванием
	const [microphoneRecordingTime, setMicrophoneRecordingTime] = useState<number>(0); // время записи микрофона, сек

	const [cookies, setCookie] = useCookies(['sprModelName']); // hook для работы с cookie
	const { startRecording, stopRecording, isRecording, isPaused, recordingBlob, recordingTime, togglePauseResume, mediaRecorder } = useAudioRecorder(); // hook для записи аудио

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	const dispatch = useAppDispatch();
	const recognitionData = useAppSelector(selectRecognitionData); // store - распознавание речи
	const { spr: clusterServerSpr } = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster

	// следим за списком продовых моделей с серверов cluster
	useEffect(() => {
		// если есть запись в cookie и список моделей содержит эту запись, то устанавливаем ее в роли активной, иначе выбираем первую модель из списка
		if (cookies.sprModelName && clusterServerSpr.modelList.includes(cookies.sprModelName)) {
			setSelectModel(cookies.sprModelName);
		} else if (clusterServerSpr.modelList.length > 0) {
			setSelectModel(clusterServerSpr.modelList[0]);
			setCookie('sprModelName', clusterServerSpr.modelList[0], { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
		}
	}, [clusterServerSpr.modelList]);

	const selectAudioToSend = (): Blob | File | undefined => {
		if (file) return file;
		else if (recordingBlob) return recordingBlob;
		else return undefined;
	};

	// добавление url аудио и установка async режима в зависимости от продолжительности
	const addDataUrlAndAsyncModeInStore = (file: Blob | File, is: 'file' | 'microphone'): void => {
		const url = window.URL.createObjectURL(file); // создаем ссылку на файл
		dispatch(addDataUrl(url)); // добавляем ссылку в store
		// если файл
		if (is === 'file') {
			const audioEl = new Audio(url);
			audioEl.addEventListener('canplaythrough', () => {
				if (audioEl.duration > 30) dispatch(setAsyncMode(true)); // установка async-режима
			});
			setMicrophoneRecordingTime(0); // сбрасываем время записи микрофона
		} else {
			if (microphoneRecordingTime > 30) dispatch(setAsyncMode(true)); // установка async-режима
		}
	};

	// очистка данных и закрытие popup с распознаванием
	const clearDataAndClosePopup = (): void => {
		setFile(null); // очищаем файл
		formRef.current?.reset(); // сбрасываем форму
		dispatch(clearRecognitionData()); // очищаем данные распознавания
		setOpenPopup(false); // закрываем popup
	};

	// следим за записью с микрофона и добавляем ссылку
	useEffect(() => {
		if (recordingBlob) addDataUrlAndAsyncModeInStore(recordingBlob, 'microphone');
	}, [recordingBlob]);

	// обработчик отправки аудиозаписи
	const submitHandler = (): void => {
		if (recognitionData.status === RequestStatus.LOADING) return; // если идет загрузка - выходим

		const audioFile = selectAudioToSend();
		// если есть запись (файл или с микрофона)
		if (audioFile) {
			const formData = new FormData();
			formData.append('file', audioFile); // создаем пару
			!recognitionData.asyncMode && setOpenPopup(true); // открываем popup, если sync режим
			dispatch(recognizeSpeech({
				modelName: selectModel,
				formData,
				asyncMode: recognitionData.asyncMode ? 1 : 0,
				speakers: definitionOfSpeakers ? 1 : 0,
				normalization: normalization && selectModel !== 'big' ? 1 : 0,
				punctuation: punctuation && selectModel !== 'big' ? 1 : 0,
				vadType: 'webrtc',
				speakerCounter: speakerCounterFlg && speakerCounter > 0 ? speakerCounter : undefined,
				toxicity: toxicity ? 1 : 0,
				voiceAnalyzer: voiceAnalyzer ? 1 : 0,
				emotion: emotion ? 1 : 0,
			})); // распознавание речи
		}
	};

	// обработчик удаления модели
	const deleteModelHandler = (): void => {
		cookies.sprModelName && dispatch(deleteModel({ modelName: cookies.sprModelName, serviceType: 'spr' }));
	};

	return (
		<form className={styles.form} ref={formRef}>
			{/* старт */}
			{!isRecording &&
				<button
					className={styles.formButton}
					type='button'
					onClick={() => {
						clearDataAndClosePopup(); // очистка данных
						startRecording();
					}}
					disabled={recognitionData.status === RequestStatus.LOADING || !selectModel}
					title={translate('buttonTitle_startRecording')}
				>
					<FontAwesomeIcon icon={faMicrophone} size="lg" color={backgroundColor} />
				</button>
			}
			{/* пауза */}
			{isRecording && !isPaused &&
				<button
					className={styles.formButton}
					type='button'
					onClick={togglePauseResume}
					disabled={recognitionData.status === RequestStatus.LOADING}
					title={translate('buttonTitle_pause')}
				>
					<FontAwesomeIcon icon={faPause} size="lg" color={backgroundColor} />
				</button>
			}
			{/* продолжение */}
			{isRecording && isPaused &&
				<button
					className={styles.formButton}
					type='button'
					onClick={togglePauseResume}
					disabled={recognitionData.status === RequestStatus.LOADING}
					title={translate('buttonTitle_continue')}
				>
					<FontAwesomeIcon icon={faMicrophone} size="lg" color={backgroundColor} />
				</button>
			}
			{/* стоп */}
			<button
				className={styles.formButton}
				type='button'
				onClick={() => {
					setMicrophoneRecordingTime(recordingTime); // сохраняем продолжительность записи
					stopRecording();
				}}
				disabled={recognitionData.status === RequestStatus.LOADING || !isRecording}
				title={translate('buttonTitle_stopRecording')}
			>
				<FontAwesomeIcon icon={faStop} size="lg" color={backgroundColor} />
			</button>

			{/* аудио-файл */}
			<input
				className={styles.formInputFile}
				type="file"
				id='fileSpr'
				required
				accept="audio/wav, audio/mp3"
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					if (e.target.files) {
						setFile(e.target.files[0]);
						addDataUrlAndAsyncModeInStore(e.target.files[0], 'file'); // добавление url в store
					}
				}}
				disabled={recognitionData.status === RequestStatus.LOADING || isRecording}
				tabIndex={-1}
			/>

			<label htmlFor='fileSpr' className={styles.formLabelFile}>
				<div
					className={cn(styles.formLabelFileIcon, {
						[styles.formLabelFileIconNotAllow]: recognitionData.status === RequestStatus.LOADING || isRecording,
					})}
					onClick={clearDataAndClosePopup}
					title={translate('input_audioFile')}
					tabIndex={0}
				>
					<FontAwesomeIcon icon={faEllipsis} color={backgroundColor} size="2xl" />
				</div>
			</label>

			<div className={styles.formAudioWrapper}>
				{isRecording
					? <AudioVisualizer mediaRecorder={mediaRecorder} />
					: recognitionData.audioUrl
						? <AudioPlayer
							url={recognitionData.audioUrl}
							sendTimestamp='recognitionSync'
							timestamp={recognitionData.timestamp}
							downloadOption={false} // отключение опции скачивания
							index={1}
							squarePlayIcon
						/>
						: <span>{translate('title_selectAudioFileOrRecordFromMicrophone')}</span>
				}
			</div>

			{/* pop-up с распознаванием */}
			<PopupResult anchorRef={settingsMenuAnchorRef} open={openPopup} setOpen={setOpenPopup} />

			{/* кнопка настроек */}
			<button
				className={styles.formButton}
				ref={settingsMenuAnchorRef}
				type="button"
				onClick={e => setSettingsMenuAnchorEl(e.currentTarget)}
				disabled={recognitionData.status === RequestStatus.LOADING || isRecording}
				title={translate('buttonTitle_settings')}
			>
				<FontAwesomeIcon icon={faGear} color={backgroundColor} size="lg" />
			</button>

			{/* настройки */}
			<Popover
				anchorEl={settingsMenuAnchorEl}
				open={Boolean(settingsMenuAnchorEl)}
				onClose={() => setSettingsMenuAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<div className={styles.formSetting}>
					<div className={styles.formSettingModel}>
						{/* модель */}
						<FormControl fullWidth sx={{ marginBottom: '8px', '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
							<InputLabel sx={{ fontSize: 13 }}>{translate('select_model')}</InputLabel>
							<Select
								required
								labelId="modelName-label"
								label={translate('select_model')}
								value={selectModel}
								onChange={(e) => {
									setSelectModel(e.target.value);
									setCookie('sprModelName', e.target.value, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
								}}
								style={{ fontSize: 13, height: 33, textAlign: 'left' }}
							>
								{clusterServerSpr.modelList.map((modelName) => (
									<MenuItem key={modelName} value={modelName} sx={{ fontSize: 13 }}>{modelName}</MenuItem>
								))}
							</Select>
						</FormControl>

						<div className={styles.formSettingModelBlock}>
							{isAccess([MODEL.IMPORT, MODEL.INSTALL, MODEL.APPLY]) &&
								<Import
									isAvailable={clusterServerSpr.status === RequestStatus.IDLE}
									selectDataResponse={selectApplyStatus}
									clearDataResponse={clearApplyResponse}
									placeOfImport='model'
									iconSize='lg'
									serviceType='spr'
								/>
							}
							{isAccess(MODEL.DELETE) &&
								<Delete
									isAvailable={clusterServerSpr.modelList.length > 0 && selectModel !== ''}
									dataResponse={selectDeleteStatus}
									clearDataResponse={clearDeleteResponse}
									submitHandler={deleteModelHandler}
									name={cookies.sprModelName || ''}
									buttonTitle='buttonTitle_deleteModel'
									iconSize='lg'
									dialogTitle='dialog_deleteModel'
									dialogConfirm='dialog_deleteClusterModelConfirm'
								/>
							}
						</div>

					</div>

					{selectModel !== 'big' &&
						<>
							{/* нормализация */}
							<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
								<Checkbox
									checked={normalization}
									onChange={e => setNormalization(e.target.checked)}
									size='small'
									disabled={recognitionData.status === RequestStatus.LOADING}
								/>
							} label={translate('checkbox_normalization')} />

							{/* пунктуация */}
							<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
								<Checkbox
									checked={punctuation}
									onChange={e => setPunctuation(e.target.checked)}
									size='small'
									disabled={recognitionData.status === RequestStatus.LOADING}
								/>
							} label={translate('checkbox_punctuation')} />
						</>
					}

					{/* анализ на токсичность */}
					<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={toxicity}
							onChange={e => setToxicity(e.target.checked)}
							size='small'
							disabled={recognitionData.status === RequestStatus.LOADING}
						/>
					} label={translate('checkbox_toxicity')} />

					{/* голосовой анализ */}
					<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={voiceAnalyzer}
							onChange={e => setVoiceAnalyzer(e.target.checked)}
							size='small'
							disabled={recognitionData.status === RequestStatus.LOADING}
						/>
					} label={translate('checkbox_voiceAnalysis')} />

					{/* текстовый анализ */}
					<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={emotion}
							onChange={e => setEmotion(e.target.checked)}
							size='small'
							disabled={recognitionData.status === RequestStatus.LOADING}
						/>
					} label={translate('checkbox_textAnalysis')} />

					{/* определение говорящих */}
					<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={definitionOfSpeakers}
							onChange={e => setDefinitionOfSpeakers(e.target.checked)}
							size='small'
							disabled={recognitionData.status === RequestStatus.LOADING}
						/>
					} label={translate('checkbox_definitionOfSpeakers')} />

					{/* указать кол-во спикеров */}
					<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={speakerCounterFlg}
							onChange={e => setSpeakerCounterFlg(e.target.checked)}
							size='small'
							disabled={recognitionData.status === RequestStatus.LOADING}
						/>
					} label={translate('checkbox_specifySpeakerCounter')} />

					{/* кол-во спикеров */}
					{speakerCounterFlg &&
						<FormControl fullWidth margin='dense'>
							<TextField
								required
								label={translate('input_quantitySpeakers')}
								variant="outlined"
								type='number'
								disabled={recognitionData.status === RequestStatus.LOADING}
								value={speakerCounter}
								onChange={(e) => setSpeakerCounter(+e.target.value)}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
									},
									inputProps: { min: 1 }
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
					}
				</div>
			</Popover>

			{/* отправка файла */}
			<button
				className={styles.formButton}
				type="button"
				disabled={!(file || recordingBlob) || recognitionData.status === RequestStatus.LOADING || isRecording || !selectModel}
				title={translate('buttonTitle_send')}
				onClick={() => submitHandler()}
			>
				<FontAwesomeIcon icon={faPaperPlane} color={backgroundColor} size="lg" />
			</button>
		</form>
	);
};

export default FormSendAudioSpr;
