export const enum RequestStatus {
	IDLE = 'idle',
	LOADING = 'loading',
	FAILED = 'failed',
}

export const enum ResponseStatus {
	SUCCESS,
	FAILED,
}

export const enum ModelStatus {
	EMPTY = 'empty',
	TRAINING = 'training',
	TRAINED = 'trained',
	TESTING = 'testing',
	TESTED = 'tested',
	INSTALLED = 'installed',
	APPLIED = 'applied',
	FAILED = 'failed',
}

// response
export interface IResponse {
	error: ResponseStatus;
	message: string;
}
