import { Fragment, memo, useRef, useState } from 'react';
import cn from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addConditionRunInEntry, selectEndpointElem } from '../../store/sesSlice';
import useTranslate from '../../hooks/useTranslate';
import { ConditionVariantType, IConditionData, IConditionVariable, IEntryConditions } from '../../types/sesTypes';
import Condition from './Condition/Condition';
import DraggablePopoverComponent from '../DraggablePopoverComponent/DraggablePopoverComponent';
import FormAddingCondition from '../Forms/FormAddingCondition/FormAddingCondition';
import { DragItem, IConditionBlockProps } from './ConditionBlock.props';
import styles from './ConditionBlock.module.scss';

const ConditionBlock = ({ conditionDataBlock, conditionBlockIdx, arrayConditionsData, isDisable, isAvailable, changeFlg, setChangeFlg, conditionFor, addConditionRunHandler }: IConditionBlockProps): JSX.Element => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // якорь для RUN условий в ENTRY
	const fieldSetRef = useRef<HTMLFieldSetElement>(null); // ссылка на контейнер

	const dispatch = useAppDispatch();
	const endpointElem = useAppSelector(selectEndpointElem); // store - конечная точка

	const translate = useTranslate(); // hook для перевода текста

	// обработчик добавления условия RUN в ENTRY
	const addConditionRunInEntryHandler = (conditionRunBlockIdx: number, data?: ConditionVariantType): void => {
		dispatch(addConditionRunInEntry({
			conditionEntryBlockIdx: conditionBlockIdx,
			conditionRunBlockIdx: conditionRunBlockIdx,
			data: data as (IConditionData | IConditionVariable) || {
				type: 'data',
				depth: 5,
				id: '',
				operation: 'exists',
				value: '',
			}
		})); // добавляем условие
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
	};

	const [{ isDragging }, drag] = useDrag({
		type: 'condition',
		item: () => {
			return { id: conditionBlockIdx, index: conditionBlockIdx };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: 'condition',
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!fieldSetRef.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = conditionBlockIdx;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) return;

			// Determine rectangle on screen
			const hoverBoundingRect = fieldSetRef.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

			// Time to actually perform the action
			conditionFor.for === 'endpointRunInEntry' && conditionFor.moveCondition(dragIndex, hoverIndex, conditionFor.conditionEntryBlockIdx);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		}
	});

	conditionFor.for === 'endpointRunInEntry' && drag(drop(fieldSetRef));

	return (
		<>
			<fieldset className={cn(styles.conditionDataBlock, {
				[styles.conditionDataBlockActive]: anchorEl,
				[styles.conditionDataBlockCursorMove]: conditionFor.for === 'endpointRunInEntry',
				[styles.conditionDataBlockOpacity]: isDragging,
			})} ref={fieldSetRef} data-handler-id={handlerId}>
				<legend>
					<div className={styles.legendBlock}>
						<button type="button" disabled>{translate('title_conditionBlockAnd')}</button>
						<button type="button" onClick={() => addConditionRunHandler(conditionBlockIdx)} title={translate('buttonTitle_addCondition')}>+</button>
					</div>
					<div className={styles.legendBlock}>
						{conditionFor.for === 'endpointEntry' &&
							<button type="button" onClick={e => setAnchorEl(e.currentTarget)}>
								{translate('button_conditionsRun')}
								{endpointElem.endpoint && (endpointElem.endpoint.entry as IEntryConditions[])[conditionBlockIdx]?.run.length > 0 &&
									<> ({(endpointElem.endpoint.entry as IEntryConditions[])[conditionBlockIdx]?.run.length})</>
								}
							</button>
						}
					</div>
				</legend>
				{conditionDataBlock?.map((conditionData, conditionIdx) => (
					<Fragment key={conditionData.type + conditionData.id + conditionBlockIdx + conditionIdx + (conditionFor.for === 'categoryAction' && conditionFor.categoryId)}>
						{/* блоки И */}
						<Condition
							conditionData={conditionData}
							conditionBlockIdx={conditionBlockIdx}
							conditionIdx={conditionIdx}
							isDisable={isDisable}
							isAvailable={isAvailable}
							changeFlg={changeFlg}
							setChangeFlg={setChangeFlg}
							conditionFor={conditionFor}
						/>
					</Fragment>
				))}
			</fieldset>

			{/* разделитель ИЛИ */}
			{arrayConditionsData.length - 1 !== conditionBlockIdx &&
				<div className={styles.conditionDataBlockLine}>
					<span className={styles.conditionDataBlockLineTitle}>{translate('title_or').toUpperCase()}</span>
				</div>
			}

			{/* run в entry */}
			{conditionFor.for === 'endpointEntry' &&
				<DraggablePopoverComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl} header='title_conditionsRun'>
					<>
						{endpointElem.endpoint && (endpointElem.endpoint.entry as IEntryConditions[])[conditionBlockIdx]?.run?.map((conditionRunDataBlock, conditionRunBlockIdx, arrayConditionsData) => (
							// блоки ИЛИ
							<ConditionBlock
								conditionDataBlock={conditionRunDataBlock}
								conditionBlockIdx={conditionRunBlockIdx}
								isDisable={isDisable}
								isAvailable={isAvailable}
								changeFlg={changeFlg}
								setChangeFlg={setChangeFlg}
								conditionFor={{
									for: 'endpointRunInEntry',
									conditionEntryBlockIdx: conditionBlockIdx,
									moveCondition: conditionFor.moveCondition,
								}}
								addConditionRunHandler={addConditionRunInEntryHandler}
								arrayConditionsData={arrayConditionsData}
								key={conditionRunDataBlock.toString() + conditionRunBlockIdx}
							/>
						))}
						{/* форма добавления условия */}
						{isAvailable &&
							<FormAddingCondition
								conditionForFormVariant={endpointElem.endpoint !== null && (endpointElem.endpoint.entry as IEntryConditions[])[conditionBlockIdx]?.run?.length === 0}
								addCondition={(data) => endpointElem.endpoint && addConditionRunInEntryHandler((endpointElem.endpoint.entry as IEntryConditions[])[conditionBlockIdx]?.run?.length || 0, data)}
								listOfSkipConditions={['interval']}
							/>
						}
					</>
				</DraggablePopoverComponent>
			}
		</>
	);
};

export default memo(ConditionBlock);
