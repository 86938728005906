import { useRef } from 'react';
import Draggable from 'react-draggable';
import { Popover } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import { colorPrimary } from '../../constants/colors';
import { IDraggablePopoverComponentProps } from './DraggablePopoverComponent.props';
import styles from './DraggablePopoverComponent.module.scss';

const DraggablePopoverComponent = ({ children, header, anchorEl, setAnchorEl }: IDraggablePopoverComponentProps): JSX.Element => {
	const popoverNodeRef = useRef<HTMLDivElement>(null); // ссылка на popover

	const translate = useTranslate(); // hook для перевода текста

	return (
		<Draggable
			nodeRef={popoverNodeRef}
			handle="h3"
		>
			<Popover
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				ref={popoverNodeRef}
				// anchorOrigin={{
				// 	vertical: 'center',
				// 	horizontal: 'left',
				// }}
				// transformOrigin={{
				// 	vertical: 'center',
				// 	horizontal: 'right',
				// }}
				sx={{
					'& .MuiPaper-root': {
						padding: '16px',
						color: colorPrimary,
						textAlign: 'left',
					}
				}}
			>
				<h3 className={styles.areaToCaptureMovement}>
					{translate(header)}
				</h3>
				{children}
			</Popover>
		</Draggable>
	);
};

export default DraggablePopoverComponent;
