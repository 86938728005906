import { useEffect, useState } from 'react';
import { Autocomplete, Button, ButtonGroup, Fade, FormControl, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeMaxNumberException, changeSessionLifeTime, deleteRobot, editRobot, editRobotName, replaceRobotActionList, replaceRobotExceptionList, selectActiveRobotVersion, selectEditingRobot, selectRobot } from '../../store/sesRobotSlice';
import { clearClusterServer, getClusterServerModels, selectClusterServer } from '../../store/serverSlice';
import { selectChannelList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SERVER, SES } from '../../constants/accessRights';
import { ROBOT_EVENT_LIST, ROBOT_EXCEPTION_LIST, ROBOT_SERVICE_DATA_LIST } from '../../constants/robotConfigLists';
import { colorPrimary } from '../../constants/colors';
import { ExceptionType, RobotActionEventType, ServiceDataType } from '../../types/sesRobotTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ActionEvent from '../ActionEvent/ActionEvent';
import ServiceData from '../ServiceData/ServiceData';
import Models from './Models/Models';
import Phrase from './Phrase/Phrase';
import FormAddingAction from '../Forms/FormAddingAction/FormAddingAction';
import FormAddingServiceData from '../Forms/FormAddingServiceData/FormAddingServiceData';
import FormAddingModelConfig from '../Forms/FormAddingModelConfig/FormAddingModelConfig';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { ServiceTypeModelRobot } from '../../types/cloudTypes';
import { IConfigRobotProps } from './ConfigRobot.props';
import styles from './ConfigRobot.module.scss';

const ConfigRobot = ({ showSettings, setShowSettings }: IConfigRobotProps): JSX.Element => {
	const [inputRobotName, setInputRobotName] = useState<string>(''); // название робота
	const [inputDescription, setInputDescription] = useState<string>(''); // описание
	const [inputVoice, setInputVoice] = useState<string>(''); // голос
	const [inputMainRecognitionModel, setInputMainRecognitionModel] = useState<string>(''); // основная модель распознавания
	const [inputBackgroundRecognitionModel, setInputBackgroundRecognitionModel] = useState<string>(''); // фоновая модель распознавания
	const [inputSessionLifeTime, setInputSessionLifeTime] = useState<number>(0); // время жизни сессии, сек
	const [maxNumberExceptions, setMaxNumberExceptions] = useState<number>(0); // макс кол-во исключений подряд

	const [selectEvent, setSelectEvent] = useState<RobotActionEventType>('start'); // событие
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал
	const [selectException, setSelectException] = useState<ExceptionType>('silence'); // исключение
	const [selectServiceData, setSelectServiceData] = useState<ServiceDataType>('yes'); // сервисные данные

	const [visibleBlockConfig, setVisibleBlockConfig] = useState<'events' | 'exceptions' | 'serviceData' | 'models' | 'audio'>('events'); // табы
	const [changeFlg, setChangeFlg] = useState<{ thisIs: boolean, listOfChanges: string[] }>({ thisIs: false, listOfChanges: [] }); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения
	const [showAlertDialogDel, setShowAlertDialogDel] = useState<boolean>(false); // показ диалогового окна при удалении робота

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const editingRobot = useAppSelector(selectEditingRobot); // store - статус изменения данных робота
	const { smc: clusterServerSmc, see: clusterServerSee, spr: clusterServerSpr, tts: clusterServerTts } = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за открытием вкладки
	useEffect(() => {
		// при открытии
		if (showSettings) {
			if (isAccess(SERVER.MODEL_LIST)) {
				clusterServerSmc.modelList.length === 0 && dispatch(getClusterServerModels({ serviceType: 'smc' })); // получаем список моделей smc
				clusterServerSee.modelList.length === 0 && dispatch(getClusterServerModels({ serviceType: 'see' })); // получаем список моделей see
				clusterServerSpr.modelList.length === 0 && dispatch(getClusterServerModels({ serviceType: 'spr' })); // получаем список моделей spr
				clusterServerTts.modelList.length === 0 && dispatch(getClusterServerModels({ serviceType: 'tts' })); // получаем список моделей tts
			}
		}
	}, [showSettings]);

	// следим за id робота
	useEffect(() => {
		// сбрасываем select'ы
		setSelectEvent('start');
		setInputChannel('default');
		setSelectException('silence');
		setSelectServiceData('yes');
		setVisibleBlockConfig('events'); // сбрасываем tab
		// сбрасывааем поля
		setInputRobotName('');
		setInputDescription('');
		setInputVoice('');
		setInputMainRecognitionModel('');
		setInputBackgroundRecognitionModel('');
		setInputSessionLifeTime(0);
		setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
		dispatch(clearClusterServer()); // очищаем список моделей серверов обработки
	}, [robotInfo.data?.id]);

	// следим за получением данных робота
	useEffect(() => {
		// если есть данные - вписываем в поля
		if (robotInfo.data) {
			!inputRobotName && setInputRobotName(robotInfo.data.name);
			!inputDescription && setInputDescription(robotInfo.data.description);
			!inputVoice && setInputVoice(robotInfo.data.voice);
			!inputMainRecognitionModel && setInputMainRecognitionModel(robotInfo.data.recognition?.main);
			!inputBackgroundRecognitionModel && setInputBackgroundRecognitionModel(robotInfo.data.recognition?.background);
			!inputSessionLifeTime && setInputSessionLifeTime(robotInfo.data.session.lifetime);
		}
	}, [robotInfo.data]);

	// следим за временем жизни сессии
	useEffect(() => {
		// через пол-секунды бездействия после окончания ввода
		const handler = setTimeout(() => {
			if (robotInfo.data && robotInfo.data.session.lifetime !== inputSessionLifeTime) {
				dispatch(changeSessionLifeTime(inputSessionLifeTime)); // изменение времени жизни сессии
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('session')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'session'] }));  // ставим флаг о несохраненных данных
			}
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод
		};
	}, [inputSessionLifeTime]);

	// следим за статусом изменения робота
	useEffect(() => {
		// если изменение данных робота прошло успешно
		if (editingRobot.status === RequestStatus.IDLE && editingRobot.error === ResponseStatus.SUCCESS && editingRobot.message !== '') {
			robotInfo.data && robotInfo.data.name !== inputRobotName && dispatch(editRobotName({ robotId: robotInfo.data.id, robotName: inputRobotName })); // если изменилось имя - меняем в store
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
		}
	}, [editingRobot]);

	// следим за выбором события
	useEffect(() => {
		// если есть событие и в нем отсутствует выбранный канал - выбираем первый из возможных
		if (robotInfo.data && robotInfo.data.actions[selectEvent] && !robotInfo.data.actions[selectEvent][inputChannel]) {
			setInputChannel(Object.keys(robotInfo.data.actions[selectEvent])[0]);
		}
		// если нет события - выбираем default канал
		else if (robotInfo.data && !robotInfo.data.actions[selectEvent]) {
			setInputChannel('default');
		}
	}, [selectEvent]);

	// следим за выбором исключения
	useEffect(() => {
		if (robotInfo.data) {
			// если есть исключение и в нем отсутствует выбранный канал - выбираем первый из возможных
			if (robotInfo.data.exceptions[selectException] && 'actions' in robotInfo.data.exceptions[selectException] && !robotInfo.data.exceptions[selectException].actions[inputChannel]) {
				setInputChannel(Object.keys(robotInfo.data.actions[selectEvent])[0]);
			}
			// если нет исключения - выбираем default канал
			else if (!robotInfo.data.exceptions[selectException]) {
				setInputChannel('default');
			}
			// устанавливаем макс кол-во исключений подряд, если есть
			if (robotInfo.data.exceptions[selectException] && 'max' in robotInfo.data.exceptions[selectException]) {
				robotInfo.data.exceptions[selectException].max !== maxNumberExceptions && setMaxNumberExceptions(robotInfo.data.exceptions[selectException].max);
			} else setMaxNumberExceptions(0);
		}
	}, [selectException]);

	// обработчик сохранения данных робота
	const editRobotHandler = (): void => {
		robotInfo.data && dispatch(editRobot({
			robotId: robotInfo.data.id,
			data: {
				name: changeFlg.listOfChanges.includes('name') ? inputRobotName : undefined,
				description: changeFlg.listOfChanges.includes('description') ? inputDescription : undefined,
				voice: changeFlg.listOfChanges.includes('voice') ? inputVoice : undefined,
				actions: changeFlg.listOfChanges.includes('actions') ? robotInfo.data.actions : undefined,
				exceptions: changeFlg.listOfChanges.includes('exceptions') ? robotInfo.data.exceptions : undefined,
				servicedata: changeFlg.listOfChanges.includes('servicedata') ? robotInfo.data.servicedata : undefined,
				models: changeFlg.listOfChanges.includes('models') ? robotInfo.data.models : undefined,
				session: changeFlg.listOfChanges.includes('session') ? robotInfo.data.session : undefined,
				recognition: changeFlg.listOfChanges.includes('recognition')
					? { main: inputMainRecognitionModel, background: inputBackgroundRecognitionModel }
					: undefined,
				stopActionPhrases: changeFlg.listOfChanges.includes('stopActionPhrases') ? robotInfo.data.stopActionPhrases : undefined,
				stopWordList: changeFlg.listOfChanges.includes('stopWordList') ? robotInfo.data.stopWordList : undefined,
			}
		})); // изменяем данные робота
	};

	// обработчик удаления робота
	const deleteRobotHandler = (): void => {
		setShowAlertDialogDel(false); // закрываем диалоговое окно
		robotInfo.data && dispatch(deleteRobot(robotInfo.data.id)); // удаление робота
	};

	// обработчик перемещения действий
	const moveAction = (dragIndex: number, hoverIndex: number) => {
		if (robotInfo.data) {
			dispatch(replaceRobotActionList({
				actionEvent: selectEvent,
				channel: inputChannel,
				actionList: update(robotInfo.data.actions[selectEvent][inputChannel], {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, robotInfo.data.actions[selectEvent][inputChannel][dragIndex]],
					],
				}),
			})); // изменение последовательности действий
			robotInfo.data?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] })); // ставим флаг о несохраненных данных
		}
	};

	// обработчик перемещения исключений
	const moveException = (dragIndex: number, hoverIndex: number) => {
		if (robotInfo.data) {
			dispatch(replaceRobotExceptionList({
				exception: selectException,
				channel: inputChannel,
				actionList: update(robotInfo.data.exceptions[selectException].actions[inputChannel], {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, robotInfo.data.exceptions[selectException].actions[inputChannel][dragIndex]],
					],
				}),
			})); // изменение последовательности действий
			robotInfo.data?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] })); // ставим флаг о несохраненных данных
		}
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowSettings(false);
	};

	return (
		<Slide direction="left" in={showSettings} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.configRobot} onMouseDown={e => e.stopPropagation()}>

					{/* загрузка настроек робота */}
					{robotInfo.status === RequestStatus.LOADING &&
						<div className={styles.loading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* ошибка получения настроек робота */}
					{(robotInfo.status === RequestStatus.FAILED || robotInfo.error === ResponseStatus.FAILED) &&
						<div className={styles.failed}>
							{translate(robotInfo.message || 'title_loadFailed')}
						</div>
					}

					{/* настройки робота */}
					{robotInfo.status === RequestStatus.IDLE && robotInfo.data &&
						<Fade in={true} timeout={500}>
							<div className={styles.configRobotWrapper}>
								<div className={styles.configRobotTop}>
									<div className={styles.configRobotTopMain}>
										{/* название робота */}
										<FormControl fullWidth margin='dense'>
											<TextField
												required
												label={translate('input_name')}
												variant="outlined"
												disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
												value={inputRobotName}
												onChange={(e) => {
													setInputRobotName(e.target.value);
													(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('name')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'name'] }));  // ставим флаг о несохраненных данных
												}}
												InputProps={{
													style: {
														height: 33,
														fontSize: 13,
														color: colorPrimary,
													},
												}}
												InputLabelProps={{
													style: {
														fontSize: 13,
													},
												}}
												sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
											/>
										</FormControl>

										{/* описание */}
										<FormControl fullWidth margin='dense'>
											<TextField
												label={translate('input_description')}
												variant="outlined"
												disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
												value={inputDescription}
												onChange={(e) => {
													setInputDescription(e.target.value);
													(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('description')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'description'] }));  // ставим флаг о несохраненных данных
												}}
												InputProps={{
													style: {
														height: 33,
														fontSize: 13,
														color: colorPrimary,
													},
												}}
												InputLabelProps={{
													style: {
														fontSize: 13,
													},
												}}
												sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
											/>
										</FormControl>

										{/* табы */}
										<ButtonGroup fullWidth sx={{ marginTop: '8px' }}>
											<Button
												variant={visibleBlockConfig === 'events' ? "contained" : "outlined"}
												sx={{ overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('events')}
											>
												{translate('button_events')}
											</Button>
											<Button
												variant={visibleBlockConfig === 'exceptions' ? "contained" : "outlined"}
												sx={{ overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('exceptions')}
											>
												{translate('button_exceptions')}
											</Button>
											<Button
												variant={visibleBlockConfig === 'serviceData' ? "contained" : "outlined"}
												sx={{ overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('serviceData')}
											>
												{translate('button_data')}
											</Button>
											<Button
												variant={visibleBlockConfig === 'models' ? "contained" : "outlined"}
												sx={{ overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('models')}
											>
												{translate('button_models')}
											</Button>
											<Button
												variant={visibleBlockConfig === 'audio' ? "contained" : "outlined"}
												sx={{ overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('audio')}
											>
												{translate('button_audio')}
											</Button>
										</ButtonGroup>
									</div>

									{visibleBlockConfig === 'events' &&
										<div className={styles.configRobotTopEvents}>
											<div className={styles.configRobotTopEventsBlock}>
												{/* событие */}
												<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
													<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
													<Select
														label={translate('select_type')}
														value={selectEvent}
														onChange={(e) => setSelectEvent(e.target.value as RobotActionEventType)}
														style={{ fontSize: 13, height: 33, color: colorPrimary }}
													>
														{ROBOT_EVENT_LIST.map(({ event, translation }) =>
															<MenuItem key={event} value={event} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
														)}
													</Select>
												</FormControl>

												{/* канал */}
												<FormControl fullWidth>
													<Autocomplete
														freeSolo={isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft'}
														options={Array.from(
															new Set(
																(Object.keys(robotInfo.data.actions[selectEvent] || {}))
																	.concat(channelList.data.map(channel => channel.name), 'default')
															))}
														value={inputChannel}
														onChange={(_, value) => setInputChannel(value ? value : '')}
														noOptionsText={<div className={styles.configRobotNoDataTitle}>{translate('title_notFound')}</div>}
														renderInput={(params) =>
															<TextField
																{...params}
																label={translate('input_channel')}
																onChange={(e) => isAccess(SES.ROBOT_EDIT) && setInputChannel(e.target.value)}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														}
														sx={{
															".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
															".MuiInputBase-input": { marginTop: -1 },
														}}
														getOptionLabel={option => option}
														renderOption={(props, option) => {
															return (
																<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																	{option}
																</span>
															);
														}}
													/>
												</FormControl>
											</div>

											{/* действия */}
											<div className={styles.configRobotActions}>
												<DndProvider backend={HTML5Backend}>
													{robotInfo.data.actions[selectEvent]?.[inputChannel]?.map((action, idx) => (
														<ActionEvent
															key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}${action.action === 'transfer' && action.destination}`}
															action={action}
															idx={idx}
															channel={inputChannel}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
															actionFor={{
																for: 'robotEvent',
																event: selectEvent,
															}}
															moveAction={moveAction}
														/>
													))}
												</DndProvider>

												{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
													<FormAddingAction
														channel={inputChannel}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
														actionFor={{
															for: 'robotEvent',
															event: selectEvent,
														}}
													/>
												}
											</div>
										</div>
									}

									{visibleBlockConfig === 'exceptions' &&
										<div className={styles.configRobotTopException}>
											<div className={styles.configRobotTopExceptionBlock}>
												{/* время жизни сессии */}
												<FormControl fullWidth>
													<TextField
														label={translate('input_sessionLifetime')}
														variant="outlined"
														type='number'
														value={inputSessionLifeTime}
														onChange={(e) => setInputSessionLifeTime(Number(e.target.value))}
														disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
														InputProps={{
															style: {
																height: 33,
																fontSize: 13,
																color: colorPrimary,
															},
															inputProps: { step: 1, min: 0 }
														}}
														InputLabelProps={{
															style: {
																fontSize: 13,
															},
														}}
														sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
													/>
												</FormControl>
											</div>
											<div className={styles.configRobotTopExceptionBlock}>
												{/* исключение */}
												<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
													<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
													<Select
														label={translate('select_type')}
														value={selectException}
														onChange={(e) => setSelectException(e.target.value as ExceptionType)}
														style={{ fontSize: 13, height: 33, color: colorPrimary }}
													>
														{ROBOT_EXCEPTION_LIST.map(({ exception, translation }) =>
															<MenuItem key={exception} value={exception} sx={{ fontSize: 13, color: colorPrimary }}>
																{translate(translation)}
															</MenuItem>
														)}
													</Select>
												</FormControl>

												{/* макс. кол-во исключений подряд */}
												<FormControl fullWidth>
													<TextField
														required
														label={translate('input_maxCountExceptions')}
														variant="outlined"
														type='number'
														disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
														value={maxNumberExceptions}
														onChange={(e) => setMaxNumberExceptions(+e.target.value)}
														onBlur={() => {
															dispatch(changeMaxNumberException({ exception: selectException, max: maxNumberExceptions })); // изменияем максимальное количество исключений
															(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
														}}
														InputProps={{
															style: {
																height: 33,
																fontSize: 13,
																color: colorPrimary,
															},
															inputProps: { min: 1 }
														}}
														InputLabelProps={{
															style: {
																fontSize: 13,
															},
														}}
														sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
													/>
												</FormControl>

												{/* канал */}
												<FormControl fullWidth>
													<Autocomplete
														freeSolo={isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft'}
														options={Array.from(
															new Set(
																(Object.keys(robotInfo.data.exceptions[selectException]?.actions || {}))
																	.concat(channelList.data.map(channel => channel.name), 'default')
															))}
														value={inputChannel}
														onChange={(_, value) => setInputChannel(value ? value : '')}
														noOptionsText={<div className={styles.configRobotNoDataTitle}>{translate('title_notFound')}</div>}
														renderInput={(params) =>
															<TextField
																{...params}
																label={translate('input_channel')}
																onChange={(e) => isAccess(SES.ROBOT_EDIT) && setInputChannel(e.target.value)}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														}
														sx={{
															".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
															".MuiInputBase-input": { marginTop: -1 },
														}}
														getOptionLabel={option => option}
														renderOption={(props, option) => {
															return (
																<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																	{option}
																</span>
															);
														}}
													/>
												</FormControl>
											</div>

											{/* действия */}
											<div className={styles.configRobotActions}>
												<DndProvider backend={HTML5Backend}>
													{robotInfo.data.exceptions[selectException]?.actions?.[inputChannel]?.map((action, idx) => (
														<ActionEvent
															key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}${action.action === 'transfer' && action.destination}`}
															action={action}
															idx={idx}
															channel={inputChannel}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
															actionFor={{
																for: 'robotException',
																exception: selectException,
															}}
															moveAction={moveException}
														/>
													))}
												</DndProvider>

												{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
													<FormAddingAction
														channel={inputChannel}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
														actionFor={{
															for: 'robotException',
															exception: selectException,
														}}
													/>
												}
											</div>
										</div>
									}

									{visibleBlockConfig === 'serviceData' &&
										<div className={styles.configRobotTopServiceData}>
											<div className={styles.configRobotTopServiceDataBlock}>
												{/* сервисные данные */}
												<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
													<InputLabel sx={{ fontSize: 13 }}>{translate('select_serviceData')}</InputLabel>
													<Select
														label={translate('select_serviceData')}
														value={selectServiceData}
														onChange={(e) => setSelectServiceData(e.target.value as ServiceDataType)}
														style={{ fontSize: 13, height: 33, color: colorPrimary }}
													>
														{ROBOT_SERVICE_DATA_LIST.map(({ serviceData, translation }) =>
															<MenuItem key={serviceData} value={serviceData} sx={{ fontSize: 13, color: colorPrimary }}>
																{translate(translation)}
															</MenuItem>
														)}
													</Select>
												</FormControl>
											</div>

											<div className={styles.configRobotActions}>
												{robotInfo.data.servicedata[selectServiceData] ?
													<ServiceData
														data={robotInfo.data.servicedata[selectServiceData]}
														serviceDataName={selectServiceData}
														modelsList={robotInfo.data.servicedata[selectServiceData].type === 'smc' ? clusterServerSmc.modelList : clusterServerSee.modelList}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
													/>
													:
													isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
													<FormAddingServiceData
														serviceDataName={selectServiceData}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
													/>
												}
											</div>
										</div>
									}

									{visibleBlockConfig === 'models' &&
										<div className={styles.configRobotTopModels}>
											{Object.keys(robotInfo.data.models).map((serviceType) => {
												return robotInfo.data && Object.keys(robotInfo.data.models[serviceType as ServiceTypeModelRobot]).map((model => {
													if (robotInfo.data && (serviceType as ServiceTypeModelRobot) === 'smc') return (
														<Models
															key={model}
															serviceType={serviceType as ServiceTypeModelRobot}
															modelName={model}
															configData={robotInfo.data.models.smc[model]}
															modelsList={clusterServerSmc.modelList}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
														/>
													);
													else if (robotInfo.data && (serviceType as ServiceTypeModelRobot) === 'see') return (
														<Models
															key={model}
															serviceType={serviceType as ServiceTypeModelRobot}
															modelName={model}
															configData={robotInfo.data.models.see[model]}
															modelsList={clusterServerSee.modelList}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
														/>
													);
													else if (robotInfo.data && (serviceType as ServiceTypeModelRobot) === 'tts') return (
														<Models
															key={model}
															serviceType={serviceType as ServiceTypeModelRobot}
															modelName={model}
															configData={robotInfo.data.models.tts[model]}
															modelsList={clusterServerTts.modelList}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
														/>
													);
													else return undefined;
												}));
											})}

											{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
												<FormAddingModelConfig
													changeFlg={changeFlg}
													setChangeFlg={setChangeFlg}
													modelsListSmc={clusterServerSmc.modelList}
													modelsListSee={clusterServerSee.modelList}
													modelsListTts={clusterServerTts.modelList}
												/>
											}
										</div>
									}

									{visibleBlockConfig === 'audio' &&
										<div className={styles.configRobotTopAudio}>
											<div className={styles.configRobotTopAudioBlock}>
												{/* голос */}
												<FormControl fullWidth>
													<Autocomplete
														freeSolo
														options={clusterServerTts.modelList}
														value={inputVoice}
														onChange={(_, value) => {
															setInputVoice(value ? value : '');
															(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('voice')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'voice'] }));  // ставим флаг о несохраненных данных
														}}
														disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
														renderInput={(params) =>
															<TextField
																{...params}
																required
																label={translate('input_voice')}
																onChange={(e) => {
																	setInputVoice(e.target.value);
																	(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('voice')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'voice'] }));  // ставим флаг о несохраненных данных
																}}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																InputProps={{
																	...params.InputProps, // важно прокинуть параметры
																	endAdornment: (
																		<div style={{ marginTop: '-7px' }}>
																			{clusterServerTts.status === RequestStatus.LOADING &&
																				<ProgressCircle isBtnDisabled />
																			}
																			{params.InputProps.endAdornment} {/* важно дописать параметры */}
																		</div>
																	),
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														}
														sx={{
															".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
															".MuiInputBase-input": { marginTop: -1 },
														}}
														getOptionLabel={option => option}
														renderOption={(props, option) => {
															return (
																<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																	{option}
																</span>
															);
														}}
													/>
												</FormControl>
												{/* основная модель распознавания */}
												<FormControl fullWidth>
													<Autocomplete
														freeSolo
														options={clusterServerSpr.modelList}
														value={inputMainRecognitionModel}
														onChange={(_, value) => {
															setInputMainRecognitionModel(value ? value : '');
															(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('recognition')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'recognition'] }));  // ставим флаг о несохраненных данных
														}}
														disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
														renderInput={(params) =>
															<TextField
																{...params}
																required
																label={translate('input_mainRecognitionModel')}
																onChange={(e) => {
																	setInputMainRecognitionModel(e.target.value);
																	(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('recognition')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'recognition'] }));  // ставим флаг о несохраненных данных
																}}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																InputProps={{
																	...params.InputProps, // важно прокинуть параметры
																	endAdornment: (
																		<div style={{ marginTop: '-7px' }}>
																			{clusterServerSpr.status === RequestStatus.LOADING &&
																				<ProgressCircle isBtnDisabled />
																			}
																			{params.InputProps.endAdornment} {/* важно дописать параметры */}
																		</div>
																	),
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														}
														sx={{
															".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
															".MuiInputBase-input": { marginTop: -1 },
														}}
														getOptionLabel={option => option}
														renderOption={(props, option) => {
															return (
																<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																	{option}
																</span>
															);
														}}
													/>
												</FormControl>
												{/* фоновая модель распознавания */}
												<FormControl fullWidth>
													<Autocomplete
														freeSolo
														options={clusterServerSpr.modelList}
														value={inputBackgroundRecognitionModel}
														onChange={(_, value) => {
															setInputBackgroundRecognitionModel(value ? value : '');
															(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('recognition')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'recognition'] }));  // ставим флаг о несохраненных данных
														}}
														disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
														renderInput={(params) =>
															<TextField
																{...params}
																required
																label={translate('input_backgroundRecognitionModel')}
																onChange={(e) => {
																	setInputBackgroundRecognitionModel(e.target.value);
																	(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('recognition')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'recognition'] }));  // ставим флаг о несохраненных данных
																}}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																InputProps={{
																	...params.InputProps, // важно прокинуть параметры
																	endAdornment: (
																		<div style={{ marginTop: '-7px' }}>
																			{clusterServerSpr.status === RequestStatus.LOADING &&
																				<ProgressCircle isBtnDisabled />
																			}
																			{params.InputProps.endAdornment} {/* важно дописать параметры */}
																		</div>
																	),
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														}
														sx={{
															".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
															".MuiInputBase-input": { marginTop: -1 },
														}}
														getOptionLabel={option => option}
														renderOption={(props, option) => {
															return (
																<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																	{option}
																</span>
															);
														}}
													/>
												</FormControl>
											</div>

											<div className={styles.configRobotTopAudioBlockSeparation}>
												<div>
													<h3 className={styles.configRobotTopAudioTitle}>{translate('title_phrasesToStopRobot')}</h3>
													{robotInfo.data.stopWordList.map((word, idx) => (
														<Phrase
															key={word + idx}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
															phrase={word}
															indexPhrase={idx}
															isPhrase='stopWordList'
														/>
													))}
													{/* добавление новой стоп-фразы */}
													{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
														<Phrase
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
															phrase={''}
															indexPhrase={robotInfo.data.stopWordList.length}
															isPhrase='stopWordList'
															newPhraseFlg
														/>
													}
												</div>
												<div>
													<h3 className={styles.configRobotTopAudioTitle}>{translate('title_phrasesReactionsToRobotStopping')}</h3>
													{robotInfo.data.stopActionPhrases.map((phrase, idx) => (
														<Phrase
															key={phrase}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
															phrase={phrase}
															indexPhrase={idx}
															isPhrase='stopActionPhrases'
														/>
													))}
													{/* добавление новой фразы-реакции */}
													{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
														<Phrase
															key={robotInfo.data.stopActionPhrases.length}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
															phrase=''
															indexPhrase={robotInfo.data.stopActionPhrases.length}
															isPhrase='stopActionPhrases'
															newPhraseFlg
														/>
													}
												</div>
											</div>
										</div>
									}
								</div>

								{activeRobotVersion === 'draft' &&
									<div className={styles.configRobotBottomBtns}>
										{isAccess(SES.ROBOT_EDIT) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													disabled={!changeFlg.thisIs}
													sx={{ fontSize: 11 }}
													onClick={editRobotHandler}
												>
													{translate('button_save')}
												</Button>
											</FormControl>
										}
										{isAccess(SES.ROBOT_DELETE) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													sx={{ fontSize: 11 }}
													onClick={() => setShowAlertDialogDel(true)}
													color='error'
												>
													{translate('button_delete')}
												</Button>
											</FormControl>
										}
									</div>
								}
							</div>
						</Fade>
					}

					{/* подтверждение удаления робота */}
					<AlertDialog
						showAlertDialog={showAlertDialogDel}
						setShowAlertDialog={setShowAlertDialogDel}
						submitHandler={deleteRobotHandler}
						title='dialog_deleteRobot'
						description='dialog_deleteRobotConfirm'
						name={robotInfo.data?.name}
					/>

					<div className={styles.tagClose} onClick={closeHandler}>
						{translate('tag_close')}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default ConfigRobot;
