import { useEffect, useState } from 'react';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector, } from '../../store/hooks';
import { changeModelServiceData, changeParamServiceData, changeTypeServiceData, deleteServiceData, selectActiveRobotVersion } from '../../store/sesRobotSlice';
import { clearModel, getInfoModel, selectFullModel } from '../../store/modelSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { MODEL, SES } from '../../constants/accessRights';
import { ROBOT_SERVICE_TYPE_LIST } from '../../constants/robotConfigLists';
import { colorPrimary, colorRed } from '../../constants/colors';
import { ServiceTypeRobot } from '../../types/cloudTypes';
import { RequestStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IServiceDataProps } from './ServiceData.props';
import styles from './ServiceData.module.scss';

const ServiceData = ({ data, serviceDataName, modelsList, changeFlg, setChangeFlg }: IServiceDataProps): JSX.Element => {
	const [selectType, setSelectType] = useState<ServiceTypeRobot>(data.type); // тип
	const [inputModelName, setInputModelName] = useState<string>(''); // название модели
	const [inputClassname, setInputClassname] = useState<string>(''); // класс/сущность (param)
	const [classnamesList, setClassnamesList] = useState<string[]>([]); // список классов

	const dispatch = useAppDispatch();
	const fullModel = useAppSelector(selectFullModel); // store - модель
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за названием и сервисными данными и вписываем значения в поля
	useEffect(() => {
		setSelectType(data.type);
		setInputModelName(data.model);
		setInputClassname(data.param);
	}, [serviceDataName, data]);

	// следим за полем названия модели
	useEffect(() => {
		// через пол-секунды бездействия после окончания ввода названия модели
		const handler = setTimeout(() => {
			// если в списке моделей есть имя модели и есть тип
			if (modelsList.includes(inputModelName) && selectType) {
				dispatch(clearModel()); // очищаем данные модели
				isAccess(MODEL.INFO) && dispatch(getInfoModel({ modelName: inputModelName.replace(/(-new)|(-planned)/, ''), serviceType: selectType })); // получаем инфо о модели
			}
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод названия модели
		};
	}, [inputModelName]);

	// следим за данными модели
	useEffect(() => {
		// если в списке моделей есть имя модели
		if (modelsList.includes(inputModelName)) {
			// пишем классы модели в зависимости от выбранного типа
			if (inputModelName.includes('-new') || inputModelName.includes('-planned')) setClassnamesList(fullModel.fullModel.future?.classes || []);
			else setClassnamesList(fullModel.fullModel.current?.classes || []);
		}
	}, [fullModel.fullModel]);

	// обработчик изменения типа 
	const changeTypeHandler = (e: SelectChangeEvent<ServiceTypeRobot>): void => {
		dispatch(changeTypeServiceData({ serviceDataName, type: e.target.value as ServiceTypeRobot }));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('servicedata')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'servicedata'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик изменения модели 
	const changeModelHandler = (): void => {
		if (data.model !== inputModelName) {
			dispatch(changeModelServiceData({ serviceDataName, model: inputModelName }));
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('servicedata')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'servicedata'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения класса/сущности 
	const changeParamHandler = (): void => {
		if (data.param !== inputClassname) {
			dispatch(changeParamServiceData({ serviceDataName, param: inputClassname }));
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('servicedata')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'servicedata'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик удаления сервисных данных
	const deleteServiceDataHandler = () => {
		dispatch(deleteServiceData({ serviceDataName }));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('servicedata')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'servicedata'] }));  // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.container}>
			<div className={styles.serviceDataBlock}>
				{/* тип */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
					<Select
						required
						label={translate('select_type')}
						value={selectType}
						onChange={changeTypeHandler}
						disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{ROBOT_SERVICE_TYPE_LIST.map(({ type, translation }) =>
							<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
						)}
					</Select>
				</FormControl>

				{/* удаление сервисных данных */}
				{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
					<FontAwesomeIcon
						icon={faTrashCan}
						color={colorRed}
						size='lg'
						onClick={deleteServiceDataHandler}
						title={translate('serviceData_deleteDataTitle')}
						style={{ cursor: 'pointer' }}
					/>
				}
			</div>

			{/* модель */}
			<FormControl fullWidth margin='dense'>
				<Autocomplete
					freeSolo
					options={modelsList}
					value={inputModelName}
					onChange={(_, value) => {
						setInputModelName(value ? value : '');
						(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('servicedata')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'servicedata'] }));  // ставим флаг о несохраненных данных
					}}
					onBlur={changeModelHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					renderInput={(params) =>
						<TextField
							{...params}
							label={translate('input_model')}
							onChange={(e) => {
								setInputModelName(e.target.value);
								(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('servicedata')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'servicedata'] }));  // ставим флаг о несохраненных данных
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							// InputProps={{
							// 	...params.InputProps, // важно прокинуть параметры
							// 	endAdornment: (
							// 		<div style={{ marginTop: '-7px' }}>
							// 			{clusterServer.status === RequestStatus.LOADING &&
							// 				<ProgressCircle isBtnDisabled />
							// 			}
							// 			{params.InputProps.endAdornment} {/* важно дописать параметры */}
							// 		</div>
							// 	),
							// }}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					}
					sx={{
						".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
						".MuiInputBase-input": { marginTop: -1 },
					}}
					getOptionLabel={option => option}
					renderOption={(props, option) => {
						return (
							<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }}>
								{option}
							</span>
						);
					}}
				/>
			</FormControl>

			{/* класс/сущность */}
			<FormControl fullWidth margin='dense'>
				<Autocomplete
					freeSolo
					options={classnamesList}
					value={inputClassname}
					onChange={(_, value) => {
						setInputClassname(value ? value : '');
						(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('servicedata')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'servicedata'] }));  // ставим флаг о несохраненных данных
					}}
					onBlur={changeParamHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					renderInput={(params) =>
						<TextField
							{...params}
							label={translate(selectType === 'smc' ? 'input_class' : 'input_entity')}
							onChange={(e) => {
								setInputClassname(e.target.value);
								(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('servicedata')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'servicedata'] }));  // ставим флаг о несохраненных данных
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							InputProps={{
								...params.InputProps, // важно прокинуть параметры
								endAdornment: (
									<div style={{ marginTop: '-7px' }}>
										{fullModel.status === RequestStatus.LOADING &&
											<ProgressCircle isBtnDisabled />
										}
										{params.InputProps.endAdornment} {/* важно дописать параметры */}
									</div>
								),
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					}
					sx={{
						".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
						".MuiInputBase-input": { marginTop: -1 },
					}}
					getOptionLabel={option => option}
					renderOption={(props, option) => {
						return (
							<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }}>
								{option}
							</span>
						);
					}}
				/>
			</FormControl>
		</div>
	);
};

export default ServiceData;
