import { Dialog } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import DraggablePaperComponent from '../../components/DraggablePaperComponent/DraggablePaperComponent';
import { IModalFormWindowProps } from './ModalFormWindow.props';
import styles from './ModalFormWindow.module.scss';

const ModalFormWindow = ({ children, showModal, setShowModal, headerTitle, close }: IModalFormWindowProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	// обработчик закрытия модального окна
	const handleClose = (): void => {
		setShowModal && setShowModal(false);
	};

	return (
		<Dialog
			open={showModal}
			onClose={close || handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			PaperComponent={DraggablePaperComponent}
		>
			<div className={styles.container}>
				<h2 className={styles.header} id='title'>
					{translate(headerTitle)}
				</h2>
				{children}
			</div>
		</Dialog>
	);
};

export default ModalFormWindow;
