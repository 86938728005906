import { useEffect, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { v4 as uuidv4, } from 'uuid';
import { Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addingAudioToBackupPlaybackQueue, addingAudioToPlaybackQueue, addingChatData, addingSessionData, addReplicaInDialog, clearAudioPlaybackQueues, clearDebuggerAnswer, clearDebuggerAnswerError, clearDebuggerSession, movingAudioPlaybackQueueToBackup, movingBackupAudioPlaybackQueueToPriority, selectDebuggerAnswer, setEndOfSession } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion } from '../../store/sesRobotSlice';
import { selectCategoriesList } from '../../store/qasSlice';
import { selectDataServers } from '../../store/serverSlice';
import useTranslate from '../../hooks/useTranslate';
import Header from './Header/Header';
import Controls from './Controls/Controls';
import MessageList from './MessageList/MessageList';
import Setting from './Setting/Setting';
import SubmissionForm from './SubmissionForm/SubmissionForm';
import SessionData from './SessionData/SessionData';
import { AudioPlaybackStatusForVadType, IDebuggerAnswerResponseWebSocket, IDebuggerAudioResponseWebSocket, IDebuggerSessionResponseWebSocket, IResponseWebSocket, ISendAskData, ISendStatusData } from '../../types/sesTypes';
import { ActiveRobotVersionType } from '../../types/sesRobotTypes';
import { RequestStatus } from '../../types/statusTypes';
import { IChatWidgetProps, ISubmitHandlerProps } from './ChatWidget.props';
import styles from './ChatWidget.module.scss';

// получение постфикса id робота в зависимости от версии робота
export const getPostfixRobotId = (activeRobotVersion: ActiveRobotVersionType, roboId: string): string => {
	if (activeRobotVersion === 'prod') return roboId + '_prod';
	else if (activeRobotVersion === 'backup') return roboId + '_backup';
	else return roboId;
};

const ChatWidget = ({ showChatWidget, setShowChatWidget }: IChatWidgetProps): JSX.Element => {
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал
	const [inputMessage, setInputMessage] = useState<string>(''); // сообщение
	const inputMessageRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода сообщения

	const [showJsonBlock, setShowJsonBlock] = useState<boolean>(false); // показ JSON блока
	const [deltaPosition, setDeltaPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 }); // позиция чата

	const [audioAnswers, setAudioAnswers] = useState<boolean>(false); // аудио ответы
	const [autoPlay, setAutoPlay] = useState<boolean>(false); // автовоспроизведение

	const [indexPlayingAudio, setIndexPlayingAudio] = useState<number>(0); // индекс аудио, которое воспроизводится
	const [variablesData, setVariablesData] = useState<[string, string, number][]>([['', '', 0]]); // данные для блока переменных

	const [audioPlaybackStatusForVad, setAudioPlaybackStatusForVad] = useState<AudioPlaybackStatusForVadType>('listening'); // статус проигрывания аудио для vad
	const [conversationFlg, setConversationFlg] = useState<boolean>(false); // флаг разговора (vad)
	const [messageSendingMode, setMessageSendingMode] = useState<'single' | 'conversation'>('single'); // режим отправки сообщений

	const [socket, setSocket] = useState<null | WebSocket>(null); // websocket
	const [webSocketStatus, setWebSocketStatus] = useState<number>(0); // статус websocket
	const [uuid, setUuid] = useState<string>(uuidv4()); // uuid сессии websocket

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const dataServers = useAppSelector(selectDataServers); // store - данные о серверах

	const { startRecording, stopRecording, mediaRecorder } = useAudioRecorder(); // hook для записи аудио
	const translate = useTranslate(); // hook для перевода текста

	// следим за id и версией робота 
	useEffect(() => {
		clearDebuggerData({}); // очищаем данные ответа и сессии
		setShowChatWidget(false); // закрываем чат
		setShowJsonBlock(false); // закрываем JSON блок
		setDeltaPosition({ x: 0, y: 0 }); // обнуляем позицию
		socket && socket.close(3000, "The work is finished"); // закрытие webSocket
		setSocket(null);
		setWebSocketStatus(0);
		setUuid(uuidv4()); // смена идентификатора
	}, [activeRobotId, activeRobotVersion]);

	// следим за открытием чата
	useEffect(() => {
		if (showChatWidget) {
			dataServers && Array.isArray(dataServers.websockets) && dataServers.websockets.length > 0 && !socket &&
				setSocket(new WebSocket(`${defineProtocolForWebsocket()}${dataServers.websockets[0]}/${uuid}`)); // первое подключение по websocket
			inputMessageRef.current?.focus(); // ставим фокус в поле сообщения
		}
	}, [showChatWidget]);

	// следим за webSocket, статусом разговора, автовоспроизведением, режимом отправки сообщений, id сессии, id приоритетного сообщения
	useEffect(() => {
		if (socket) {
			// событие открытия WebSocket-соединения
			socket.onopen = function () {
				setWebSocketStatus(socket.readyState); // установка статуса соединения
			};

			// событие получения данных
			socket.onmessage = function (event) {
				const responseData: IDebuggerAnswerResponseWebSocket | IDebuggerAudioResponseWebSocket | IDebuggerSessionResponseWebSocket | IResponseWebSocket = JSON.parse(event.data);
				if (typeof responseData === 'object' && 'path' in responseData) {
					if (responseData.path === 'ask') dispatch(addingChatData(responseData)); // добавление данных чата в store
					else if (responseData.path === 'session') dispatch(addingSessionData({ data: responseData, categoryDictionary: categoriesList.dictionary })); // добавление данных сессии в store
					// для голосового канала в момент разговора или с флагом автовоспроизведения для одиночной отправки сообщений 
					else if (responseData.path === 'audio') {
						if ('audio' in responseData && (conversationFlg || (autoPlay && messageSendingMode === 'single'))) {
							(async function () {
								await fetch('data:audio/wav;base64,' + responseData.audio)
									.then(res => res.blob())
									.then(blob => {
										const url = window.URL.createObjectURL(new Blob([blob], { type: "audio/wav" }));
										// если uuid соответствует приоритетному сообщению
										if (responseData.uuid === debuggerAnswer.priorityMessageId) {
											dispatch(addingAudioToPlaybackQueue(url)); // добавление url аудио в приоритетную очередь
										} else if (responseData.uuid === debuggerAnswer.secondaryMessageId) {
											dispatch(addingAudioToBackupPlaybackQueue(url)); // добавление url аудио в резервную очередь
										}
									});
							})();
						}
					} else if (responseData.path === 'stop') {
						dispatch(movingAudioPlaybackQueueToBackup()); // перемещаем очередь воспроизведения в резерв
					} else if (responseData.path === 'resume') {
						dispatch(movingBackupAudioPlaybackQueueToPriority()); // перемещаем резервную очередь воспроизведения в приоритетную
					} else if (responseData.path === 'hangup') {
						dispatch(setEndOfSession()); // ставим флаг конца сессии
					}
				}
			};

			// событие закрытия WebSocket-соединения
			socket.onclose = function (event) {
				setWebSocketStatus(socket.readyState); // установка статуса соединения
				event.code !== 3000 && reconnectingWebsocket(event.code, debuggerAnswer.session); // переподключение, если закрылось не со сменой робота
			};
		}
	}, [socket, conversationFlg, autoPlay, messageSendingMode, debuggerAnswer.session, debuggerAnswer.priorityMessageId]);

	// следим за статусом воспроизведения аудио
	useEffect(() => {
		// только для голосового канала
		if (activeRobotId && inputChannel === 'voice') {
			const data: ISendStatusData = {
				path: 'status',
				robot: getPostfixRobotId(activeRobotVersion, activeRobotId),
				session: debuggerAnswer.session,
				value: audioPlaybackStatusForVad,
			};
			socket?.readyState === 1 && socket.send(JSON.stringify(data)); // отправка статуса vad
		}
	}, [audioPlaybackStatusForVad]);

	// следим за флагом разговора (vad)
	useEffect(() => {
		// если идет разговор
		if (conversationFlg) startRecording(); // старт визуализатора
		else stopRecording(); // остановка визуализатора
	}, [conversationFlg]);

	// определение протокола для websocket
	const defineProtocolForWebsocket = (): string => {
		const protocol = window.location.protocol;
		if (protocol === 'https:') return 'wss://';
		else if (protocol === 'http:') return 'ws://';
		else return '//';
	};

	// функция переподключения websocket
	const reconnectingWebsocket = (statusCode: number, sessionId: string): void => {
		// если есть список websocket-серверов и подключение было закрыто
		if (socket && dataServers && Array.isArray(dataServers.websockets) && dataServers.websockets.length > 0 && socket.readyState === 3) {
			const url = socket.url.replace(/(ws(s)?:\/\/)|(\/.*)/g, ''); // url подключения
			const index = dataServers.websockets.indexOf(url); // index в списке серверов websocket

			// если есть текущая сессия
			if (sessionId !== '') {
				setTimeout(() => {
					setSocket(new WebSocket(`${defineProtocolForWebsocket()}${dataServers.websockets![index]}/${uuid}`)); // подключение того же сервера c тем же uuid
				}, 2000);
			} else {
				const newUuid = uuidv4();
				setUuid(newUuid); // смена идентификатора

				if (statusCode === 1000) {
					setSocket(new WebSocket(`${defineProtocolForWebsocket()}${dataServers.websockets[index]}/${newUuid}`)); // подключение того же сервера по websocket
				} else if (index >= 0 && (index + 1) < dataServers.websockets.length) {
					setSocket(new WebSocket(`${defineProtocolForWebsocket()}${dataServers.websockets[index + 1]}/${newUuid}`)); // подключение следующего сервера по websocket
				} else {
					// через 5 сек
					setTimeout(() => {
						dataServers && Array.isArray(dataServers.websockets) && dataServers.websockets.length > 0 &&
							setSocket(new WebSocket(`${defineProtocolForWebsocket()}${dataServers.websockets[0]}/${newUuid}`)); // подключение первого сервера по websocket
					}, 5000);
				}
			}
		}
	};

	// перетаскивание чата
	const handleDrag = (_e: DraggableEvent, ui: DraggableData) => {
		setDeltaPosition(prev => ({
			x: prev.x + ui.deltaX,
			y: prev.y + ui.deltaY,
		}));
	};

	// отправка запроса
	const submitHandler = ({ e, audio, message, channel, session }: ISubmitHandlerProps): void => {
		e?.preventDefault();
		if (activeRobotId) {
			dispatch(addReplicaInDialog({
				who: (audio && inputChannel !== 'voice') ? 'clientAudio' : 'clientText',
				message: message ? message : inputMessage,
				audioUrl: audio ? window.URL.createObjectURL(audio) : undefined,
			})); // добавление реплики клиента в диалог

			// очистка ошибки ответа робота, если была
			if (debuggerAnswer.status === RequestStatus.FAILED) {
				dispatch(clearDebuggerAnswerError());
			}

			// переводим переменные в объект
			const variablesObj: Record<string, string> = {};
			variablesData.forEach(([variable, value]) => {
				if (variable !== '' && value !== '') variablesObj[variable] = value;
			});

			const data: ISendAskData = {
				path: 'ask',
				robot: getPostfixRobotId(activeRobotVersion, activeRobotId),
				session: session ?
					session
					:
					debuggerAnswer.session ?
						debuggerAnswer.session :
						undefined,
				channel: channel || inputChannel,
				tts: audioAnswers ? 1 : undefined,
				variables: (Object.keys(variablesObj).length > 0 && !debuggerAnswer.session) ? variablesObj : undefined,
				isDebugger: 1,
			};

			// аудио сообщение
			if (audio) {
				// для голосового канала - blob-формат
				if (inputChannel === 'voice') {
					socket?.readyState === 1 && socket.send(audio); // отправка аудио
					setAudioPlaybackStatusForVad('playing'); // смена статуса прослушивания
				}
				// для остальных каналов - base64-формат
				else {
					const reader = new FileReader();
					reader.readAsDataURL(audio); // читаем запись аудио с микрофона;
					// событие завершения чтения файла
					reader.onloadend = function () {
						const base64data = reader.result; // base64 с мета данными
						if (typeof base64data === 'string') {
							const formatBase64Data = base64data.split(',')[1]; // оставляем только base64
							data.audio = formatBase64Data; // добавление base64 аудио
							socket?.readyState === 1 && socket.send(JSON.stringify(data)); // отправка сообщения с настройками
						}
					};
					// событие ошибки чтения файла
					reader.onerror = function () {
						console.error('File read error');
					};
				}
			}
			// текстовое сообщение
			else {
				data.text = message ? message : inputMessage; // добавление сообщения
				socket?.readyState === 1 && socket.send(JSON.stringify(data)); // отправка сообщения с настройками
				inputMessage && setInputMessage(''); // очистка поля сообщения
			}
		}
	};

	// очистка данных ответа, сессии, индекса воспроизводимого аудио, переменных, режима отправки
	const clearDebuggerData = ({ includesVariables = true }: { includesVariables?: boolean }): void => {
		dispatch(clearDebuggerAnswer());
		dispatch(clearDebuggerSession());
		setIndexPlayingAudio(0);
		if (includesVariables) {
			setVariablesData([['', '', 0]]);
		} else {
			socket && socket.close(1000, "The work is finished");
		}
		setMessageSendingMode('single');
		conversationFlg && setConversationFlg(false);
		dispatch(clearAudioPlaybackQueues()); // очищаем очереди воспроизведения
	};

	return (
		<Draggable
			defaultClassName={styles.container}
			handle='h2' // за что таскаем
			bounds='html' // границы перетаскивания
			position={{ x: deltaPosition.x, y: deltaPosition.y }}
			onDrag={handleDrag}
		>
			<Fade in={showChatWidget} timeout={300}>
				<div>
					{/* переподключение */}
					{debuggerAnswer.dialog.length > 0 && socket?.readyState !== 1 &&
						<div className={styles.reconnection}>
							{socket?.readyState === 0 && translate('title_connection')}
							{socket?.readyState === 2 && translate('title_closingConnection')}
							{socket?.readyState === 3 && translate('title_connectionClosed')}
						</div>
					}

					<Header setShowChatWidget={setShowChatWidget} />

					<Controls
						setShowJsonBlock={setShowJsonBlock}
						conversationFlg={conversationFlg}
						mediaRecorder={mediaRecorder}
						inputChannel={inputChannel}
						setInputChannel={setInputChannel}
						clearDebuggerData={clearDebuggerData}
					/>

					<MessageList
						inputMessageRef={inputMessageRef}
						socket={socket}
						indexPlayingAudio={indexPlayingAudio}
						setIndexPlayingAudio={setIndexPlayingAudio}
						autoPlay={autoPlay}
						setAudioPlaybackStatusForVad={setAudioPlaybackStatusForVad}
						submitHandler={submitHandler}
						inputChannel={inputChannel}
						conversationFlg={conversationFlg}
						setConversationFlg={setConversationFlg}
					/>

					<Setting
						variablesData={variablesData}
						setVariablesData={setVariablesData}
						audioAnswers={audioAnswers}
						setAudioAnswers={setAudioAnswers}
						autoPlay={autoPlay}
						setAutoPlay={setAutoPlay}
					/>

					<SubmissionForm
						submitHandler={submitHandler}
						inputChannel={inputChannel}
						setInputChannel={setInputChannel}
						audioPlaybackStatusForVad={audioPlaybackStatusForVad}
						inputMessageRef={inputMessageRef}
						inputMessage={inputMessage}
						setInputMessage={setInputMessage}
						webSocketStatus={webSocketStatus}
						conversationFlg={conversationFlg}
						setConversationFlg={setConversationFlg}
						setMessageSendingMode={setMessageSendingMode}
						uuid={uuid}
						showChatWidget={showChatWidget}
					/>

					<SessionData
						showJsonBlock={showJsonBlock}
						deltaPosition={deltaPosition}
					/>
				</div>
			</Fade>
		</Draggable>
	);
};

export default ChatWidget;
