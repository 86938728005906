import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getRandomWallpaper, getTranslate, selectTranslate, selectTranslateStatus, selectWallpaper } from './store/langSlice';
import { getAccessRightsList, getVersion, refreshToken, selectAccessRights, selectAuthData, selectTokenAuth } from './store/authSlice';
import { clearCurrentUserInfo, getCurrentUserInfo, selectCurrentUserInfo } from './store/userSlice';
import { changeAnswerFilter, changeAnswerTypeFilter, changeCategoryFilter, changeQuestionFilter } from './store/qasSlice';
import useAccessRight from './hooks/useAccessRight';
import useTranslate from './hooks/useTranslate';
import { BIOMETRY, CLASSIFIER, CORPUS_LINK, ENTITIES, INTERACTIVE, LOGIN, MARKS_LINK, QUESTION, QUESTIONNAIRE, RECOGNIZER, REPORTS, ROBOTS, ROLES, SYNTHESIS, TRANSCRIPT, USERS } from './constants/routes';
import { QAS_FILTER_ANSWER, QAS_FILTER_ANSWER_TYPE, QAS_FILTER_CATEGORY, QAS_FILTER_QUESTION, REFRESH_TOKEN } from './constants/cookieNames';
import { USER } from './constants/accessRights';
import { RequestStatus } from './types/statusTypes';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Classifier from './pages/Classifier/Classifier';
import Entities from './pages/Entities/Entities';
import Corpus from './pages/Corpus/Corpus';
import Marks from './pages/Marks/Marks';
import Queue from './pages/Queue/Queue';
import Transcript from './pages/Transcript/Transcript';
import Biometry from './pages/Biometry/Biometry';
import Synthesis from './pages/Synthesis/Synthesis';
import Questionnaire from './pages/Questionnaire/Questionnaire';
import Question from './pages/Question/Question';
import Interactive from './pages/Interactive/Interactive';
import Robots from './pages/Robots/Robots';
import Reports from './pages/Reports/Reports';
import Users from './pages/Users/Users';
import Roles from './pages/Roles/Roles';
import NotFound from './pages/NotFound/NotFound';
import ScreenLock from './components/ScreenLock/ScreenLock';
import NoticeSingleAction from './components/Notification/NoticeSingleAction/NoticeSingleAction';

function App() {
  const [showNotificationLang, setShowNotificationLang] = useState<boolean>(false); // показ уведомлений статуса загрузки словаря для перевода текста

  const dispatch = useAppDispatch();
  const langStatus = useAppSelector(selectTranslateStatus); // store - статус загрузки словаря для перевода текста
  const langData = useAppSelector(selectTranslate); // store - словарь для перевода текста
  const authData = useAppSelector(selectAuthData); // store - данные авторизации
  const tokenAuth = useAppSelector(selectTokenAuth); // store - токены авторизации
  const accessRightsList = useAppSelector(selectAccessRights); // store - список всех прав доступа
  const currentUserInfo = useAppSelector(selectCurrentUserInfo); // store - инфо текущего пользователя
  const wallpaper = useAppSelector(selectWallpaper); // store - обои

  const navigate = useNavigate(); // hook для навигации
  const location = useLocation(); // hook для определения адреса
  const [cookies] = useCookies([REFRESH_TOKEN, QAS_FILTER_CATEGORY, QAS_FILTER_QUESTION, QAS_FILTER_ANSWER, QAS_FILTER_ANSWER_TYPE]); // hook для работы с cookie
  const isAccess = useAccessRight(); // hook для проверки прав доступа
  const translate = useTranslate(); // hook для перевода текста

  useEffect(() => {
    dispatch(getTranslate()); // получаем словарь для перевода текста
    dispatch(getRandomWallpaper()); // получаем обои
    // если нет refresh токена - перенаправляем на страницу авторизации, иначе получаем заново
    if (!cookies.refreshToken) navigate(LOGIN, { state: location.pathname });
    else dispatch(refreshToken());

    // пишем из cookie в store QAS фильтрацию
    cookies.qasFilterCategory && dispatch(changeCategoryFilter(cookies.qasFilterCategory));
    cookies.qasFilterQuestion && dispatch(changeQuestionFilter(cookies.qasFilterQuestion));
    cookies.qasFilterAnswer && dispatch(changeAnswerFilter(cookies.qasFilterAnswer));
    cookies.qasFilterAnswerType && dispatch(changeAnswerTypeFilter(cookies.qasFilterAnswerType));
  }, []);

  // следим за статусом авторизации
  useEffect(() => {
    // если ошибка - перенаправляем на страницу авторизации
    if (authData.status === RequestStatus.FAILED) navigate(LOGIN);
  }, [authData.status]);

  // следим за токеном авторизации
  useEffect(() => {
    // если есть
    if (tokenAuth.access && tokenAuth.refresh) {
      dispatch(getAccessRightsList()); // получаем список всех прав доступа
      dispatch(getCurrentUserInfo()); // получаем данные текущего пользователя
    } else {
      currentUserInfo.id && dispatch(clearCurrentUserInfo()); // иначе очищаем, если есть id пользователя
    }
  }, [tokenAuth]);

  // следим за получением прав доступа и в случаем ошибок - переводим на главную страницу
  useEffect(() => {
    (accessRightsList.status === RequestStatus.FAILED || currentUserInfo.status === RequestStatus.FAILED) && navigate('/');
    // когда получены общие права и права пользователя
    if (accessRightsList.data && currentUserInfo.id) {
      isAccess(USER.VERSION) && dispatch(getVersion()); // получаем версию системы
    }
  }, [accessRightsList, currentUserInfo]);

  // следим за статусом и данными перевода и в случае ошибок - включаем уведомление
  useEffect(() => {
    if (typeof langData === 'string' || langStatus === RequestStatus.FAILED) setShowNotificationLang(true);
  }, [langStatus, langData]);

  // если еще нет данных перевода или в статусе загрузки - показываем блокирующее окно загрузки
  if ((langData === null && langStatus !== RequestStatus.FAILED) || langStatus === RequestStatus.LOADING) return <ScreenLock title='Загрузка языка (RU)' />;

  // если идет получение прав доступа - показываем блокирующее окно загрузки
  if (accessRightsList.status === RequestStatus.LOADING || currentUserInfo.status === RequestStatus.LOADING) return <ScreenLock title={translate('spinnerTitle_obtainingAccessRights')} />;

  // если идет получение обоев - показываем блокирующее окно загрузки
  if (wallpaper.status === RequestStatus.LOADING) return <ScreenLock title={translate('spinnerTitle_loadingImages')} />;

  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={CLASSIFIER} element={<Classifier serviceType='smc' />} />
        <Route path={`${CLASSIFIER}/${CORPUS_LINK}`} element={<Corpus serviceType='smc' />} />
        <Route path={`${CLASSIFIER}/${MARKS_LINK}`} element={<Marks serviceType='smc' />} />
        <Route path={ENTITIES} element={<Entities serviceType='see' />} />
        <Route path={`${ENTITIES}/${CORPUS_LINK}`} element={<Corpus serviceType='see' />} />
        <Route path={RECOGNIZER} element={<Queue serviceType='spr' />} />
        <Route path={`${RECOGNIZER}/${TRANSCRIPT}`} element={<Transcript />} />
        <Route path={BIOMETRY} element={<Biometry serviceType='sbs' />} />
        <Route path={SYNTHESIS} element={<Synthesis serviceType='tts' />} />
        <Route path={QUESTIONNAIRE} element={<Questionnaire serviceType='qas' />} />
        <Route path={`${QUESTIONNAIRE}/${QUESTION}`} element={<Question serviceType='qas' />} />
        <Route path={`${QUESTIONNAIRE}/${INTERACTIVE}`} element={<Interactive serviceType='qas' />} />
        <Route path={ROBOTS} element={<Robots serviceType='ses' />} />
        <Route path={REPORTS} element={<Reports />} />
        <Route path={USERS} element={<Users />} />
        <Route path={`${USERS}/${ROLES}`} element={<Roles />} />
        <Route path='*' element={<NotFound />} />
      </Routes>

      {showNotificationLang &&
        <NoticeSingleAction
          showNotification={showNotificationLang}
          setShowNotification={setShowNotificationLang}
          title='Ошибка загрузки языка (RU)'
        />
      }
    </>
  );
}

export default App;
