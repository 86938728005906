import { useEffect } from 'react';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { analyze, clearAnalyze, clearNoiseLevel, getNoiseLevel, selectAnalyze, selectNoiseLevel } from '../../store/sbsSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SBS } from '../../constants/accessRights';
import { RequestStatus } from '../../types/statusTypes';
import FormSendAudio from '../Forms/FormSendAudio/FormSendAudio';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IAnalysisProps } from './Analysis.props';
import styles from './Analysis.module.scss';

const Analysis = ({ showAnalysis, setShowAnalysis }: IAnalysisProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const analyzeData = useAppSelector(selectAnalyze); // store - анализ речи
	const noiseLevel = useAppSelector(selectNoiseLevel); // store - уровень шума

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за данными анализа и очищаем при открытии/закрытии вкладки
	useEffect(() => {
		clearData();
	}, [showAnalysis]);

	// очистка данных
	const clearData = (): void => {
		(analyzeData.data || analyzeData.status === RequestStatus.FAILED) && dispatch(clearAnalyze());
		(noiseLevel.data || noiseLevel.status === RequestStatus.FAILED) && dispatch(clearNoiseLevel());
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowAnalysis(false);
	};

	return (
		<Slide direction="left" in={showAnalysis} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.analysis} onMouseDown={e => e.stopPropagation()}>
					<h3 className={styles.analysisTitle}>{translate('title_voiceFragmentAnalysis')}</h3>

					<FormSendAudio
						formFor='analysis'
						disableFile={analyzeData.status === RequestStatus.LOADING || noiseLevel.status === RequestStatus.LOADING}
						disableSubmit={analyzeData.status === RequestStatus.LOADING || noiseLevel.status === RequestStatus.LOADING}
						submitFunction={({ formData }) => {
							clearData(); // очистка данных анализа
							isAccess(SBS.ANALYZE) && dispatch(analyze(formData)); // анализ речи
							isAccess(SBS.NOISE_LEVEL) && dispatch(getNoiseLevel(formData)); // уровень шума
						}}
					/>

					<div className={styles.analysisResult}>
						{(analyzeData.status === RequestStatus.LOADING || noiseLevel.status === RequestStatus.LOADING) &&
							<div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_analysis')} /></div>
						}
						{analyzeData.data &&
							<div>
								<div className={styles.analysisResultPair}>
									<p>
										<span className={styles.analysisResultKey}>{translate('title_age')}: </span>{analyzeData.data.age.class}
									</p>
									<p>
										<span className={styles.analysisResultKey}>{translate('confidence')}: </span>{analyzeData.data.age.confidence.toFixed(3)}
									</p>
								</div>
								<div className={styles.analysisResultPair}>
									<p>
										<span className={styles.analysisResultKey}>{translate('title_emotion')}: </span>{translate(analyzeData.data.emotion.class)}
									</p>
									<p>
										<span className={styles.analysisResultKey}>{translate('confidence')}: </span>{analyzeData.data.emotion.confidence.toFixed(3)}
									</p>
								</div>
								<div className={styles.analysisResultPair}>
									<p>
										<span className={styles.analysisResultKey}>{translate('title_gender')}: </span>{translate(analyzeData.data.gender.class)}
									</p>
									<p>
										<span className={styles.analysisResultKey}>{translate('confidence')}: </span>{analyzeData.data.gender.confidence.toFixed(3)}
									</p>
								</div>
							</div>
						}
						{noiseLevel.data &&
							<div>
								<div className={styles.analysisResultPair}>
									<p>
										<span className={styles.analysisResultKey}>{translate('title_noiseLevel')}: </span>{noiseLevel.data.noise?.toFixed(3)}
									</p>
								</div>
							</div>
						}
						{(analyzeData.status === RequestStatus.FAILED || noiseLevel.status === RequestStatus.FAILED) &&
							<div className={styles.failedText}>{translate(analyzeData.message || noiseLevel.message || 'title_loadFailed')}</div>
						}
					</div>
				</div>

				<div className={styles.tagClose} onClick={closeHandler}>
					{translate('tag_close')}
				</div>
			</div>
		</Slide>
	);
};

export default Analysis;
