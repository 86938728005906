import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../store/hooks';
import { selectRobot } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { IHeaderProps } from './Header.props';
import styles from './Header.module.scss';

const Header = ({ setShowChatWidget }: IHeaderProps): JSX.Element => {

	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе

	const translate = useTranslate(); // hook для перевода текста

	return (
		<div className={styles.headerWrapper}>
			{/* кнопка закрытия чата */}
			<button
				className={styles.buttonCloseChat}
				onClick={() => setShowChatWidget(false)}
				title={translate('buttonTitle_close')}
			>
				<FontAwesomeIcon icon={faXmark} size='2x' color='#fff' />
			</button>
			<h2 className={styles.header}>{robotInfo.data?.name || translate('title_robot')}</h2>
		</div>
	);
};

export default Header;
