import { useRef } from 'react';
import Draggable from 'react-draggable';
import { Paper, PaperProps } from '@mui/material';

const DraggablePaperComponent = (props: PaperProps): JSX.Element => {
	const nodeRef = useRef<HTMLDivElement>(null);
	return (
		<Draggable
			nodeRef={nodeRef as React.RefObject<HTMLDivElement>}
			handle="#title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} ref={nodeRef} sx={{ minWidth: 500 }} />
		</Draggable>
	);
};

export default DraggablePaperComponent;
