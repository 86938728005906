import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addModelName, changeActiveType, clearImportResponse, clearModel, deleteModel, getAllModels, getInfoModel, selectAllModels, selectDeleteStatus, selectFullModel, selectImportStatus } from '../../../store/modelSlice';
import { getClusterServerModels, selectClusterServer } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { findServiceRightByType } from '../../../helpers/findServiceRightByType';
import { CORPUS, MODEL, SERVER } from '../../../constants/accessRights';
import { CORPUS_LINK, MARKS_LINK } from '../../../constants/routes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import FormImportModel from '../../Forms/FormImportModel/FormImportModel';
import ScreenLock from '../../ScreenLock/ScreenLock';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import { INavBarProps } from './ModelNavBar.props';
import styles from './ModelNavBar.module.scss';

const ModelNavBar = ({ serviceType, setShowPage }: INavBarProps): JSX.Element => {
	const [modelList, setModelList] = useState<string[]>([]); // список моделей
	const [activeModel, setActiveModel] = useState<string>(); // активная модель
	const [showModalImport, setShowModalImport] = useState<boolean>(false); // показ формы импортирования модели
	const [showScreenLock, setShowScreenLock] = useState<boolean>(false); // показ экрана блокировки
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	const [showMenu, setShowMenu] = useState<boolean>(false); // показ кастомного контекстного меню
	const [positionMenu, setPositionMenu] = useState<{ x: number, y: number }>({ x: 0, y: 0 }); // позиция кастомного контекстного меню
	const [promiseGetInfoModel, setPromiseGetInfoModel] = useState<Promise<any> | null>(null); // промис для отмены запроса получения инфо модели

	const dispatch = useAppDispatch();
	const allModels = useAppSelector(selectAllModels); // store - все модели
	const fullModel = useAppSelector(selectFullModel); // store - информация о модели
	const importStatus = useAppSelector(selectImportStatus); // store - статус импортирования модели
	const deleteStatus = useAppSelector(selectDeleteStatus); // store - статус удаления модели
	const { tts: clusterServer } = useAppSelector(selectClusterServer); // store - все продовые сервера с моделями

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const navigate = useNavigate(); // hook для навигации
	const translate = useTranslate(); // hook для перевода текста
	const [cookies, setCookie] = useCookies([`${serviceType}ModelName`]); // hook для работы с cookie
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список моделей

	// следим за статусом удаления модели
	useEffect(() => {
		if (deleteStatus.status === RequestStatus.LOADING) setShowScreenLock(true); // если идет удаление модели - включаем экран блокировки
		else setShowScreenLock(false); // иначе выключаем
		// если удаление прошло успешно
		if (deleteStatus.error === ResponseStatus.SUCCESS && deleteStatus.status === RequestStatus.IDLE && deleteStatus.message !== '') {
			dispatch(addModelName(null)); // очищаем из store имя активной модели
			dispatch(getAllModels({ serviceType })); // получаем заново все модели
		}
	}, [deleteStatus]);

	// следим за получением данных обо всех моделях
	useEffect(() => {
		// если есть данные и не в процессе загрузки - записываем в state список моделей, иначе обнуляем
		if (allModels.models !== null && allModels.status !== RequestStatus.LOADING && typeof (allModels.models) !== 'string') {
			setModelList(Object.keys(allModels.models));
		} else setModelList([]);
	}, [allModels]);

	// следим за списком продовых моделей
	useEffect(() => {
		// только для tts
		if (serviceType === 'tts' && clusterServer.modelList.length > 0) {
			setModelList(clusterServer.modelList); // пишем список моделей в state
		}
	}, [clusterServer.modelList]);

	// следим за списком моделей
	useEffect(() => {
		// только если список моделей не пустой
		if (modelList.length > 0) {
			// и если импортирование модели прошло успешно
			if (importStatus.error === ResponseStatus.SUCCESS && importStatus.message === 'success' && importStatus.status === RequestStatus.IDLE) {
				importStatus.newName && modelHandler(importStatus.newName); // передаем в обработчик выбора активной модели - имя импортируемой модели из store
				dispatch(clearImportResponse()); // очищаем статус импортирования модели
			} else {
				// иначе если есть запись в cookie и список моделей содержит эту запись, то передаем ее в обработчик выбора активной модели, иначе выбираем первую модель из списка
				modelList.includes(cookies[`${serviceType}ModelName`]) && cookies[`${serviceType}ModelName`] ?
					modelHandler(cookies[`${serviceType}ModelName`])
					:
					modelHandler(modelList[0]);
			}
		}
	}, [modelList]);

	// следим за статусом загрузки модели
	useEffect(() => {
		// если закончилась загрузка модели - сбрасываем промис для отмены запроса
		fullModel.status !== RequestStatus.LOADING && setPromiseGetInfoModel(null);
		// если ошибка и нет ответа от сервера (случай с отменой запроса) - запрашиваем
		fullModel.status === RequestStatus.FAILED && fullModel.error === ResponseStatus.SUCCESS && fullModel.message === '' && fullModel.modelName && modelHandler(fullModel.modelName);
	}, [fullModel.status]);

	// следим за информацией о модели и если есть список моделей и не загружаются данные модели
	useEffect(() => {
		if (allModels.models !== null && fullModel.status !== RequestStatus.LOADING) {
			fullModel.activeType !== 'future' && dispatch(changeActiveType('future')); // и если активный тип не черновик - ставим черновик
			fullModel.fullModel.future === null && dispatch(changeActiveType('current')); // и если нет данных черновика - ставим рабочую
			fullModel.fullModel.future === null && fullModel.fullModel.current === null && dispatch(changeActiveType('previous')); // и если нет данных черновика и рабочей модели - ставим архивную
		}
	}, [fullModel.fullModel]);

	// следим за активной моделью
	useEffect(() => {
		activeModel && listRef.current?.children[modelList.indexOf(activeModel)]?.scrollIntoView({ block: "center" }); // показ активной модели в центре списка с имеющейся полосой прокрутки
	}, [activeModel]);

	// обработчик выбора активной модели
	const modelHandler = (modelName: string): void => {
		// если сохранен промис для отмены запроса
		if (promiseGetInfoModel) {
			// @ts-ignore
			promiseGetInfoModel.abort(); // прерываем запрос
			setPromiseGetInfoModel(null); // обнуляем промис
			dispatch(addModelName(modelName)); // добавляем имя модели в store
			return;
		}
		serviceType !== 'tts' && dispatch(clearModel()); // очищаем все данные о модели
		setActiveModel(modelName); // устанавливаем имя активной модели
		dispatch(addModelName(modelName)); // добавляем имя модели в store
		// не для tts
		if (serviceType !== 'tts' && isAccess(MODEL.INFO)) {
			const promise = dispatch(getInfoModel({ modelName, serviceType })); // получаем модель
			setPromiseGetInfoModel(promise); // устанавливаем промис для случая с отменой запроса
		}
		setCookie(`${serviceType}ModelName`, modelName, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	// кастомное контекстное меню
	const showCustomContextMenu = (e: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
		if (isAccess(MODEL.DELETE) && fullModel.fullModel.current === null && fullModel.fullModel.future === null && fullModel.fullModel.previous === null) {
			e.preventDefault();
			setShowMenu(false); // сбрасываем показ контекстного меню
			const newPosition = {
				x: e.pageX,
				y: e.pageY,
			};
			setPositionMenu(newPosition); // устанавливаем новые координаты контекстного меню
			setShowMenu(true); // открываем контекстное меню
		}
	};

	// обработчик удаления модели
	const deleteHandler = (): void => {
		fullModel.modelName && dispatch(deleteModel({ modelName: fullModel.modelName, serviceType })); // удаление модели
		setShowMenu(false); // закрываем контекстное меню
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (link: string): void => {
		setShowPage && setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(link);
		}, 500);
	};

	return (
		<>
			<Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={800}>
				<div className={styles.sidebar} onClick={() => setShowMenu(false)}>
					<div className={styles.navbar}>
						{(allModels.status === RequestStatus.FAILED || typeof (allModels.models) === 'string' || (serviceType === 'tts' && clusterServer.status !== RequestStatus.LOADING && (typeof clusterServer.data === 'string' || !clusterServer.data || clusterServer.status === RequestStatus.FAILED))) &&
							<div className={styles.navbarFailedText}>
								{serviceType === 'tts' &&
									<>
										<span>{translate(clusterServer.message || 'title_loadFailed')}</span>
										{isAccess(SERVER.MODEL_LIST) &&
											<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getClusterServerModels({ serviceType }))}>{translate('link_update')}</span>
										}
									</>
								}
								{(serviceType === 'smc' || serviceType === 'see') &&
									<>
										<span>{translate(allModels.message || 'title_loadFailed')}</span>
										{isAccess(MODEL.INFO) &&
											<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getAllModels({ serviceType }))}>{translate('link_update')}</span>
										}
									</>
								}
							</div>
						}

						{((allModels.status === RequestStatus.IDLE && allModels.models && typeof (allModels.models) !== 'string' && Object.keys(allModels.models).length === 0) ||
							(serviceType === 'tts' && clusterServer.status === RequestStatus.IDLE && clusterServer.data !== null && typeof clusterServer.data !== 'string' && Object.keys(clusterServer.data).length > 0 && modelList.length === 0)) &&
							<div className={styles.navbarNoModels}>{translate('title_emptyList')}</div>
						}

						{(allModels.status === RequestStatus.LOADING || (serviceType === 'tts' && clusterServer.status === RequestStatus.LOADING)) &&
							<div className={cn(styles.navbarLoading, {
								[styles.navbarLoadingShort]: (serviceType === 'smc' || serviceType === 'see') && isAccess([findServiceRightByType(serviceType), CORPUS.LIST, CORPUS.INFO]),
							})}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>
						}

						<ul className={styles.navbarModelList} ref={listRef}>
							{modelList.map((modelName) => (
								<li
									className={styles.navbarModel}
									key={modelName}
									onContextMenu={e => serviceType !== 'tts' && fullModel.status === RequestStatus.IDLE && modelName === fullModel.modelName && showCustomContextMenu(e)}
								>
									<Fade in={allModels.status !== RequestStatus.LOADING || allModels.models !== null} timeout={500}>
										<div
											className={cn({
												[styles.navbarModelLink]: activeModel !== modelName,
												[styles.navbarModelLinkActive]: activeModel === modelName
											})}
											onClick={() => modelName !== activeModel && modelHandler(modelName)}
										>
											<div className={styles.navbarModelLinkLeftBlock} title={modelName}>
												{modelName}
											</div>
											<FontAwesomeIcon icon={faAngleRight} />
										</div>
									</Fade>
								</li>
							))}
						</ul>

						{showMenu && (
							<div
								style={{ top: positionMenu.y, left: positionMenu.x }}
								className={styles.navbarModelContextMenu}
							>
								<div className={styles.navbarModelContextMenuOption} onClick={deleteHandler}>
									{translate('title_deleteModel')}
								</div>
							</div>
						)}

						{serviceType !== 'tts' && (allModels.status === RequestStatus.IDLE || allModels.models !== null) &&
							<div className={styles.functionButtons}>
								{isAccess(MODEL.ADD) &&
									<Fade in={true} timeout={500}>
										<div className={styles.functionButtonsAddModel} onClick={() => setShowModalImport(true)}>
											{translate('link_addNewModel')}
										</div>
									</Fade>
								}
								{isAccess([findServiceRightByType(serviceType), CORPUS.LIST, CORPUS.INFO]) && (serviceType === 'smc' || serviceType === 'see') &&
									<div className={styles.functionButtonsTabs}>
										<div className={styles.functionButtonsTab}>{translate('tab_models')}</div>
										<div
											className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
											onClick={() => delayToHidePage(CORPUS_LINK)}>
											{translate('tab_corpus')}
										</div>
										{serviceType === 'smc' &&
											<div
												className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
												onClick={() => delayToHidePage(MARKS_LINK)}>
												{translate('tab_marks')}
											</div>
										}
									</div>
								}
							</div>
						}
					</div>

					{showModalImport && <FormImportModel showModal={showModalImport} setShowModal={setShowModalImport} create serviceType={serviceType} setShowNotification={setShowNotification} />}
				</div>
			</Slide>

			{(showScreenLock) && <ScreenLock title={translate('spinnerTitle_deletion')} />}
			{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectImportStatus} clearDataResponse={clearImportResponse} titleFailed='noticeAddition_failed' titleSuccess='noticeAddition_success' />}
		</>
	);
};

export default ModelNavBar;
