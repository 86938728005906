import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addCategoryDataElem, addCategoryDataEndpoint, addCategoryDataEndpointEntry, addConditionRunActionDataElem, addConditionRunActionEndpoint, addConditionRunActionEndpointEntry, changeActionDataElem, changeActionEndpoint, changeActionEndpointEntry, changeActionTypeDataElem, changeActionTypeEndpoint, changeActionTypeEndpointEntry, changeDestinationDataElem, changeDestinationEndpoint, changeDestinationEndpointEntry, changeScriptDataElem, changeScriptEndpoint, changeScriptEndpointEntry, changeServiceDataElem, changeServiceEndpoint, changeServiceEndpointEntry, changeSetAnswered, changeVoiceActionDataElem, changeVoiceActionEndpoint, changeVoiceEndpointEntry, deleteActionDataElem, deleteActionEndpoint, deleteActionEndpointEntry, } from '../../store/sesSlice';
import { addCategoryDataException, addCategoryDataRobot, addConditionRunActionExceptionRobot, addConditionRunActionRobot, changeActionException, changeActionRobot, changeActionTypeException, changeActionTypeRobot, changeDestinationException, changeDestinationRobot, changeScriptException, changeScriptRobot, changeServiceException, changeServiceRobot, changeVoiceActionRobot, changeVoiceException, deleteActionRobot, deleteRobotException, selectActiveRobotVersion } from '../../store/sesRobotSlice';
import { selectScriptList } from '../../store/sesScriptSlice';
import { selectCategoriesList, selectGptList } from '../../store/qasSlice';
import { selectClusterServer } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { ACTION_LIST, ACTION_TYPE_LIST, SERVICE_TYPE_LIST } from '../../constants/robotConfigLists';
import { ANSWER_CACHE_USAGE_LIST } from '../../constants/questionConfigLists';
import { colorPrimary, colorRed, colorSecondaryLight3 } from '../../constants/colors';
import { Action, ActionType, ActionVariantType, ConditionVariantType, IActionInternalAsk, IActionInternalChat, IConditionData, IConditionVariable, ServiceType } from '../../types/sesTypes';
import { CachingType } from '../../types/qasTypes';
import { RequestStatus } from '../../types/statusTypes';
import Phrase from './Phrase/Phrase';
import ConditionBlock from '../ConditionBlock/ConditionBlock';
import FormAddingCondition from '../Forms/FormAddingCondition/FormAddingCondition';
import DraggablePopoverComponent from '../DraggablePopoverComponent/DraggablePopoverComponent';
import FormCopyAndMoveAction from '../Forms/FormCopyAndMoveAction/FormCopyAndMoveAction';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { DragItem, IActionProps } from './ActionEvent.props';
import styles from './ActionEvent.module.scss';

const ActionEvent = ({ action, idx, channel, changeFlg, setChangeFlg, actionFor, moveAction }: IActionProps): JSX.Element => {
	const [selectAction, setSelectAction] = useState<Action>(action.action); // действие
	const [inputVoice, setInputVoice] = useState<string>(action.action === 'say' ? action.voice : ''); // голос
	const [selectActionType, setSelectActionType] = useState<ActionType>(action.action === 'say' ? action.type : 'linear'); // тип действия
	const [checkSetAnswered, setCheckSetAnswered] = useState<boolean>('setAnswered' in action && action.setAnswered ? action.setAnswered : false); // флаг "считать действие успешным прохождением точки" (только для действий КТ)
	const [selectScript, setSelectScript] = useState<string>(action.action === 'say' && action.type === 'external' ? action.script : ''); // скрипт
	const [selectService, setSelectService] = useState<ServiceType>(action.action === 'say' && action.type === 'internal' ? action.service : 'qas-ask'); // сервис
	const [selectCategoryId, setSelectCategoryId] = useState<string>((action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' && Object.keys(action.categories || {})[0]) || ''); // id категории
	const [selectCaching, setSelectCaching] = useState<CachingType>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' ? action.useCache : 'checked'); // кеширование
	const [checkGPT, setCheckGPT] = useState<boolean>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' && action.useGPT === 'yes' ? true : false); // использование gpt
	const [checkCreative, setCheckCreative] = useState<boolean>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' && action.creative === 'yes' ? true : false); // использование творческого режима
	const [checkAddAlias, setCheckAddAlias] = useState<boolean>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' && action.addAlias === 'yes' ? true : false); // добавление вопроса в похожие в кэше, если похожая формулировка найдена
	const [inputDepth, setInputDepth] = useState<number>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-chat' ? action.depth : 3); // глубина истории сообщений
	const [selectModelGPT, setSelectModelGPT] = useState<string>(action.action === 'say' && action.type === 'internal' ? action.model : ''); // модель gpt
	const [inputMaxDocs, setInputMaxDocs] = useState<number>((action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask') ? action.maxDocs : 1); // кол-во документов в результате поиска
	const [inputDestination, setInputDestination] = useState<string>(action.action === 'transfer' ? action.destination : ''); // место назначения

	const fieldSetRef = useRef<HTMLFieldSetElement>(null); // ссылка на контейнер
	const [anchorElConditionsRun, setAnchorElConditionsRun] = useState<HTMLElement | null>(null); // якорь для условий запуска действий
	const [anchorElCategoriesSettings, setAnchorElCategoriesSettings] = useState<HTMLElement | null>(null); // якорь для условий присвоения категорий

	const [showCopyAndMoveForm, setShowCopyAndMoveForm] = useState<{ isShown: boolean, type: 'copy' | 'move' }>({ isShown: false, type: 'copy' }); // показ формы копирования/перемещения действия
	const [showAlertDialogDelAction, setShowAlertDialogDelAction] = useState<boolean>(false); // показ диалогового окна при удалении действия

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const scriptList = useAppSelector(selectScriptList); // store - список скриптов
	const modelGptList = useAppSelector(selectGptList); // store - список моделей gpt
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const { tts: clusterServerTts } = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	const isDisabled = useMemo(() => {
		return ((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) ||
			(actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) ||
			((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) ||
			activeRobotVersion !== 'draft';
	}, []); // отключен

	const isEnabled = useMemo(() => {
		return (
			((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) ||
			(actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) ||
			((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))
		) && activeRobotVersion === 'draft';
	}, []); // включен

	// обработчик установки флага изменений
	const changeFlgHandler = (changed: 'exceptions' | 'actions' | 'entry'): void => {
		if (!changeFlg.thisIs || !changeFlg.listOfChanges.includes(changed)) {
			setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, changed] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения действия
	const changeActionHandler = (e: SelectChangeEvent<Action>): void => {
		const value = e.target.value as Action;
		const data = { channel, idx, action: value };
		setSelectAction(value);
		if (actionFor.for === 'robotException') {
			dispatch(changeActionException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeActionEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeActionRobot({ actionEvent: actionFor.event, ...data })); // робота
			actionFor.for === 'dataElement' && dispatch(changeActionDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeActionEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения голоса
	const changeVoiceHandler = (value: string | null): void => {
		const voice = value ? value : '';
		setInputVoice(voice);
		const data = { channel, idx, voice };
		if (actionFor.for === 'robotException') {
			dispatch(changeVoiceException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeVoiceEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeVoiceActionRobot({ actionEvent: actionFor.event, ...data })); // робота
			actionFor.for === 'dataElement' && dispatch(changeVoiceActionDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeVoiceActionEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения типа действия
	const changeActionTypeHandler = (e: SelectChangeEvent<ActionType>): void => {
		const value = e.target.value as ActionType;
		setSelectActionType(value);
		const data = { channel, idx, actionType: value };
		if (actionFor.for === 'robotException') {
			dispatch(changeActionTypeException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeActionTypeEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeActionTypeRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeActionTypeDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeActionTypeEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик удаления действия
	const deleteActionHandler = (): void => {
		const data = { channel, idx };
		if (actionFor.for === 'robotException') {
			dispatch(deleteRobotException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(deleteActionEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(deleteActionRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(deleteActionDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(deleteActionEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
		setShowAlertDialogDelAction(false); // закрываем диалоговое окно
	};

	// обработчик изменения флага "считать действие успешным прохождением точки" (только для действий КТ)
	const changeSetAnsweredHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setCheckSetAnswered(e.target.checked);
		actionFor.for === 'endpoint' && dispatch(changeSetAnswered({
			channel,
			idx,
			flag: e.target.checked,
		})); // КТ
		changeFlgHandler('actions'); // ставим флаг о несохраненных данных
	};

	// обработчик добавления фразы
	// const addPhraseHandler = (): void => {
	// 	if (action.action === 'say' && (action.type === 'linear' || action.type === 'random')) {
	// 		const data = { channel, idx };
	// 		if (actionFor.for === 'robotException') {
	// 			dispatch(addPhraseInException({ exception: actionFor.exception, ...data })); // перезапись действия исключения робота с добавлением фразы
	// 			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
	// 		} else if (actionFor.for === 'endpointEntry') {
	// 			dispatch(addPhraseInActionEndpointEntry({ ...data })); // перезапись действия входа КТ с добавлением фразы
	// 			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
	// 		} else {
	// 			actionFor.for === 'robotEvent' && dispatch(addPhraseInActionRobot({ actionEvent: actionFor.event, ...data })); // перезапись действия события робота с добавлением фразы
	// 			actionFor.for === 'dataElement' && dispatch(addPhraseInActionDataElem({ actionEvent: actionFor.event, ...data })); // перезапись действия ЭД с добавлением фразы
	// 			actionFor.for === 'endpoint' && dispatch(addPhraseInActionEndpoint({ ...data })); // перезапись действия КТ с добавлением фразы
	// 			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
	// 		}
	// 	}
	// };

	// обработчик изменения скрипта
	const changeScriptHandler = (e: SelectChangeEvent<string>): void => {
		setSelectScript(e.target.value);
		const data = { channel, idx, script: e.target.value };
		if (actionFor.for === 'robotException') {
			dispatch(changeScriptException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeScriptEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeScriptRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeScriptDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeScriptEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// сбор данных для типа действия "из внутреннего источника"
	const getServiceData = ({ service, model, useCache, useGPT, useCreative, useAddAlias, depth, maxDocs }: { service?: ServiceType, model?: string, useCache?: CachingType, useGPT?: boolean, useCreative?: boolean, useAddAlias?: boolean, depth?: number, maxDocs?: number }): Omit<IActionInternalAsk, 'action' | 'type' | 'categories' | 'conditions' | 'setAnswered'> | Omit<IActionInternalChat, 'action' | 'type'> => {
		const internalService = service || selectService;
		const gpt = typeof useGPT === 'boolean' ? useGPT : checkGPT;
		const creative = typeof useCreative === 'boolean' ? useCreative : checkCreative;
		const addAlias = typeof useAddAlias === 'boolean' ? useAddAlias : checkAddAlias;
		switch (internalService) {
			case 'qas-ask':
				return {
					service: internalService,
					useCache: useCache || selectCaching,
					useGPT: gpt ? 'yes' : 'no',
					creative: creative ? 'yes' : 'no',
					addAlias: addAlias ? 'yes' : 'no',
					model: model || selectModelGPT,
					voice: inputVoice,
					maxDocs: maxDocs || inputMaxDocs,
				};
			case 'qas-chat':
				return {
					service: internalService,
					depth: depth ?? inputDepth,
					model: model || selectModelGPT,
					voice: inputVoice,
				};
			default:
				const _: never = internalService;
				return _;
		}
	};

	// обработчик изменения сервиса
	const changeServiceHandler = (e: SelectChangeEvent<ServiceType>): void => {
		const value = e.target.value as ServiceType;
		setSelectService(value);
		const data = { channel, idx, ...getServiceData({ service: value }) };
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения модели генерации
	const changeModelGptHandler = (e: SelectChangeEvent<string>): void => {
		const value = e.target.value;
		setSelectModelGPT(value);
		const data = { channel, idx, ...getServiceData({ model: value }) };
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения кол-ва документов в результате поиска
	const changeMaxDocsHandler = (): void => {
		if (action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' && action.maxDocs === inputMaxDocs) return; // выходим, если не поменялось значение
		const data = { channel, idx, ...getServiceData({ maxDocs: inputMaxDocs }) };
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик добавления данных категории
	const addCategoryDataHandler = (categoryBlockIdx: number, data?: ConditionVariantType): void => {
		const conditionData = data as (IConditionData | IConditionVariable) || { type: 'data', depth: 5, id: '', operation: 'exists', value: '' };
		const payloadData = { channel, channelIdx: idx, categoryId: selectCategoryId, categoryBlockIdx, data: conditionData };
		if (actionFor.for === 'robotException') {
			dispatch(addCategoryDataException({ exception: actionFor.exception, ...payloadData })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(addCategoryDataEndpointEntry({ ...payloadData })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(addCategoryDataRobot({ actionEvent: actionFor.event, ...payloadData })); // события робота
			actionFor.for === 'dataElement' && dispatch(addCategoryDataElem({ actionEvent: actionFor.event, ...payloadData })); // ЭД
			actionFor.for === 'endpoint' && dispatch(addCategoryDataEndpoint({ ...payloadData })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения использования кэша ответов
	const changeCachingHandler = (e: SelectChangeEvent<CachingType>): void => {
		const value = e.target.value as CachingType;
		setSelectCaching(value);
		const data = { channel, idx, ...getServiceData({ useCache: value }) };
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения использования генерации
	const changeGPTHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		const value = e.target.checked;
		setCheckGPT(value);
		const data = { channel, idx, ...getServiceData({ useGPT: value }) };
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения использования творческого режима
	const changeCreativeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		const value = e.target.checked;
		setCheckCreative(value);
		const data = { channel, idx, ...getServiceData({ useCreative: value }) };
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения использования кэширования алиасов
	const changeAddAliasHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		const value = e.target.checked;
		setCheckAddAlias(value);
		const data = { channel, idx, ...getServiceData({ useAddAlias: value }) };
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения глубины истории сообщений
	const changeDepthHandler = (): void => {
		if (action.action === 'say' && action.type === 'internal' && action.service === 'qas-chat' && action.depth === inputDepth) return; // выходим, если не поменялось значение
		const data = { channel, idx, ...getServiceData({ depth: inputDepth }) };
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения места назначения
	const changeDestinationHandler = (): void => {
		if (action.action === 'transfer' && action.destination === inputDestination) return; // выходим, если не поменялось значение
		const data = { channel, idxAction: idx, destination: inputDestination };
		if (actionFor.for === 'robotException') {
			dispatch(changeDestinationException({ exception: actionFor.exception, ...data })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeDestinationEndpointEntry({ ...data })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeDestinationRobot({ actionEvent: actionFor.event, ...data })); // события робота
			actionFor.for === 'dataElement' && dispatch(changeDestinationDataElem({ actionEvent: actionFor.event, ...data })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeDestinationEndpoint({ ...data })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	// обработчик добавления условия запуска действия
	const addConditionRunActionHandler = (conditionBlockIdx: number, data?: ConditionVariantType): void => {
		const conditionData: ConditionVariantType = data || { type: 'interval', id: '' };
		const payloadData = { channel, channelIdx: idx, conditionBlockIdx, data: conditionData };
		if (actionFor.for === 'robotException') {
			dispatch(addConditionRunActionExceptionRobot({ exception: actionFor.exception, ...payloadData })); // исключения робота
			changeFlgHandler('exceptions'); // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(addConditionRunActionEndpointEntry({ ...payloadData })); // входа КТ
			changeFlgHandler('entry'); // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(addConditionRunActionRobot({ actionEvent: actionFor.event, ...payloadData })); // события робота
			actionFor.for === 'dataElement' && dispatch(addConditionRunActionDataElem({ actionEvent: actionFor.event, ...payloadData })); // ЭД
			actionFor.for === 'endpoint' && dispatch(addConditionRunActionEndpoint({ ...payloadData })); // КТ
			changeFlgHandler('actions'); // ставим флаг о несохраненных данных
		}
	};

	const [{ isDragging }, drag] = useDrag({
		type: 'action',
		item: () => {
			return { id: idx, index: idx };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: 'action',
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!fieldSetRef.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = idx;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) return;

			// Determine rectangle on screen
			const hoverBoundingRect = fieldSetRef.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

			// Time to actually perform the action
			moveAction(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	drag(drop(fieldSetRef));

	return (
		<fieldset className={cn(styles.container, {
			[styles.containerActive]: anchorElConditionsRun || anchorElCategoriesSettings || showCopyAndMoveForm.isShown || showAlertDialogDelAction,
			[styles.containerOpacity]: isDragging,
			[styles.containerPaddingTop]: selectActionType === 'internal' && selectAction === 'say' && selectService === 'qas-chat',
		})} ref={fieldSetRef} data-handler-id={handlerId}>
			<legend className={styles.legend}>
				{selectActionType === 'internal' && selectAction === 'say' && selectService === 'qas-ask' &&
					<button type="button" className={styles.legendButton} onClick={(e) => setAnchorElCategoriesSettings(e.currentTarget)}>
						{translate('button_conditionsCategories')}
					</button>
				}
				{selectService !== 'qas-chat' &&
					<button type="button" className={styles.legendButton} onClick={e => setAnchorElConditionsRun(e.currentTarget)}>
						{translate('button_conditionsRun')}
						{'conditions' in action && action.conditions && action.conditions?.length > 0 &&
							<> ({action.conditions.length})</>
						}
					</button>
				}
				{isEnabled &&
					<div className={styles.legendBlock}>
						<button
							type='button'
							className={styles.legendButton}
							onClick={() => setShowCopyAndMoveForm({ isShown: true, type: 'copy' })}
							title={translate('buttonTitle_copy')}
						>
							<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 448 512">
								{/* !Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. */}
								<path fill="#918c8c" d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" />
							</svg>
						</button>
						<button
							type='button'
							className={styles.legendButton}
							onClick={() => setShowCopyAndMoveForm({ isShown: true, type: 'move' })}
							title={translate('buttonTitle_move')}
						>
							<FontAwesomeIcon icon={faCopy} size='lg' color={colorPrimary} />
						</button>
						<button
							type='button'
							className={styles.legendButton}
							onClick={() => setShowAlertDialogDelAction(true)}
							title={translate('buttonTitle_deleteAction')}
						>
							<FontAwesomeIcon icon={faTrashCan} color={colorRed} size='lg' />
						</button>
					</div>
				}
			</legend>

			{/* верхний блок для всех, кроме настроек робота с действием "Завершение разгвора" */}
			{!(actionFor.for === 'robotEvent' && actionFor.event === 'hangup') &&
				<div className={styles.actionBlock}>
					{/* действие */}
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('select_action')}</InputLabel>
						<Select
							label={translate('select_action')}
							disabled={isDisabled}
							value={selectAction}
							onChange={changeActionHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{ACTION_LIST.map(({ action, translation }) =>
								<MenuItem key={action} value={action} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
							)}
						</Select>
					</FormControl>

					{/* голос */}
					{selectAction === 'say' &&
						<FormControl fullWidth margin='dense'>
							<Autocomplete
								options={['', ...clusterServerTts.modelList]}
								value={inputVoice}
								onChange={(_, value) => changeVoiceHandler(value)}
								noOptionsText={<div className={styles.actionBlockNoDataTitle}>{translate('title_notFound')}</div>}
								disabled={isDisabled}
								renderInput={(params) =>
									<TextField
										{...params}
										label={translate('input_voice')}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										InputProps={{
											...params.InputProps, // важно прокинуть параметры
											endAdornment: (
												<div style={{ marginTop: '-7px' }}>
													{clusterServerTts.status === RequestStatus.LOADING &&
														<ProgressCircle isBtnDisabled />
													}
													{params.InputProps.endAdornment} {/* важно дописать параметры */}
												</div>
											),
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								}
								sx={{
									".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
									".MuiInputBase-input": { marginTop: -1 },
								}}
								getOptionLabel={option => option}
								renderOption={(props, option) => {
									return (
										<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
											{option === '' ? translate('selectItem_byDefault') : option}
										</span>
									);
								}}
							/>
						</FormControl>
					}

					{/* тип действия */}
					{selectAction === 'say' &&
						<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
							<InputLabel sx={{ fontSize: 13 }}>{translate('select_actionType')}</InputLabel>
							<Select
								disabled={isDisabled}
								label={translate('select_actionType')}
								value={selectActionType}
								onChange={changeActionTypeHandler}
								style={{ fontSize: 13, height: 33, color: colorPrimary }}
							>
								{ACTION_TYPE_LIST.map(({ actionType, translation }) =>
									<MenuItem key={actionType}
										value={actionType}
										disabled={(actionType === 'external' && !isAccess(SES.SCRIPT_LIST)) || (actionType === 'internal' && actionFor.for === 'dataElement')}
										sx={{ fontSize: 13, color: colorPrimary }}
									>
										{translate(translation)}
									</MenuItem>
								)}
							</Select>
						</FormControl>
					}
				</div>
			}

			{/* передача диалога */}
			{action.action === 'transfer' &&
				<FormControl margin='dense'>
					<TextField
						label={translate('input_destination')}
						variant="outlined"
						value={inputDestination}
						onChange={(e) => setInputDestination(e.target.value)}
						onBlur={changeDestinationHandler}
						disabled={isDisabled}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
			}

			{/* считать действие успешным прохождением точки (только для действий КТ) */}
			{actionFor.for === 'endpoint' && selectService === 'qas-ask' &&
				<FormControlLabel sx={{ alignSelf: 'flex-start', overflow: 'hidden', marginTop: '-5px', marginBottom: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={checkSetAnswered}
						disabled={isDisabled}
						onChange={changeSetAnsweredHandler}
						size='small'
					/>
				} label={translate('checkbox_considerActionSuccessfulPassingEndpoint')} />
			}

			{/* фразы */}
			{action.action === 'say' && (action.type === 'linear' || action.type === 'random') &&
				<>
					{action.text.map((phrase, idxPhrase, arrayPhrases) => (
						<Phrase
							key={phrase + idxPhrase}
							phrase={phrase}
							idxPhrase={idxPhrase}
							arrayPhrases={arrayPhrases}
							channel={channel}
							idxAction={idx}
							actionFor={actionFor}
							changeFlgHandler={changeFlgHandler}
							isDisabled={isDisabled}
							isEnabled={isEnabled}
						/>
					))}
					{action.text[action.text.length - 1] !== '' &&
						<Phrase
							phrase={''}
							idxPhrase={action.text.length}
							arrayPhrases={action.text}
							channel={channel}
							idxAction={idx}
							actionFor={actionFor}
							changeFlgHandler={changeFlgHandler}
							isDisabled={isDisabled}
							isEnabled={isEnabled}
						/>
					}
				</>
			}

			{/* скрипт */}
			{selectActionType === 'external' && selectAction === 'say' &&
				<div className={styles.actionBlock}>
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('select_script')}</InputLabel>
						<Select
							label={translate('select_script')}
							disabled={isDisabled}
							value={selectScript}
							onChange={changeScriptHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{/* на случай, если не найден скрипт из списка */}
							{selectScript && !scriptList.data.find(scriptItem => scriptItem.id === selectScript) &&
								<MenuItem value={selectScript} sx={{ fontSize: 12, color: colorRed }}>{selectScript} ({translate('selectItem_notFound')})</MenuItem>
							}
							{scriptList.data.map(({ id, name }) => (
								<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			}

			{/* сервис */}
			{selectActionType === 'internal' && selectAction === 'say' &&
				<>
					{/* сервис */}
					<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('select_service')}</InputLabel>
						<Select
							label={translate('select_service')}
							disabled={isDisabled}
							value={selectService}
							onChange={changeServiceHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{SERVICE_TYPE_LIST.map(({ serviceType, translation }) =>
								<MenuItem key={serviceType} value={serviceType} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
							)}
						</Select>
					</FormControl>
					{/* модель gpt */}
					<div className={styles.actionBlock}>
						<FormControl margin='dense' sx={{
							'.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, flexGrow: 1,
						}}>
							<InputLabel id="modelGpt-label" sx={{ fontSize: 13 }}>{translate('select_generationModel')}</InputLabel>
							<Select
								labelId="modelGpt-label"
								id="modelGpt"
								label={translate('select_generationModel')}
								disabled={isDisabled}
								value={selectModelGPT}
								onChange={changeModelGptHandler}
								style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
							>
								<MenuItem value='default' sx={{ fontSize: 13, color: colorPrimary }}>default</MenuItem>
								{modelGptList.data.filter(model => model !== 'default').map((model) => (
									<MenuItem key={model} value={model} sx={{ fontSize: 13, color: colorPrimary }}>{model}</MenuItem>
								))}
							</Select>
						</FormControl>
						{/* глубина */}
						{selectService === 'qas-chat' &&
							<FormControl margin='dense'>
								<TextField
									label={translate('input_depth')}
									variant="outlined"
									type='number'
									value={inputDepth}
									onChange={(e) => setInputDepth(+e.target.value)}
									onBlur={changeDepthHandler}
									error={inputDepth < 1 || inputDepth > 10}
									disabled={isDisabled}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13,
											color: colorPrimary,
										},
										inputProps: { min: 1, max: 10 },
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
						}
						{/* кол-во документов в результате поиска */}
						{selectService === 'qas-ask' &&
							<FormControl margin='dense' sx={{ flexGrow: 1 }}>
								<TextField
									label={translate('input_numberOfDocumentsInSearchResult')}
									variant="outlined"
									type='number'
									value={inputMaxDocs}
									onChange={(e) => setInputMaxDocs(+e.target.value)}
									onBlur={changeMaxDocsHandler}
									error={inputMaxDocs < 1 || inputMaxDocs > 10}
									disabled={isDisabled}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13,
											color: colorPrimary,
										},
										inputProps: { min: 1, max: 10 },
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
						}
					</div>

					{selectService === 'qas-ask' &&
						<>
							{/* кэш ответов */}
							<FormControl margin='dense' sx={{
								'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
								'.MuiSelect-select': { paddingBlock: 0 },
							}}>
								<InputLabel id="caching-label" sx={{ fontSize: 13 }}>{translate('select_useAnswersCache')}</InputLabel>
								<Select
									labelId="caching-label"
									id="caching"
									label={translate('select_useAnswersCache')}
									disabled={isDisabled}
									value={selectCaching}
									onChange={changeCachingHandler}
									style={{ fontSize: 13, height: 33, color: colorPrimary }}
								>
									{ANSWER_CACHE_USAGE_LIST.map(({ type, translation }) => (
										<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControlLabel sx={{ alignSelf: 'flex-start', overflow: 'hidden', marginTop: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
								<Checkbox
									checked={checkGPT}
									disabled={isDisabled}
									onChange={changeGPTHandler}
									size='small'
								/>
							} label={translate('checkbox_useGpt')} />
							<FormControlLabel sx={{ alignSelf: 'flex-start', overflow: 'hidden', marginTop: '-15px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
								<Checkbox
									checked={checkCreative}
									disabled={isDisabled}
									onChange={changeCreativeHandler}
									size='small'
								/>
							} label={translate('checkbox_useCreative')} />
							<FormControlLabel sx={{ alignSelf: 'flex-start', overflow: 'hidden', marginTop: '-3px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px', textAlign: 'left' } }} control={
								<Checkbox
									checked={checkAddAlias}
									disabled={isDisabled}
									onChange={changeAddAliasHandler}
									size='small'
								/>
							} label={translate('checkbox_useCacheAlias')} />
						</>
					}
				</>
			}

			{/* условия запуска действий */}
			<DraggablePopoverComponent anchorEl={anchorElConditionsRun} setAnchorEl={setAnchorElConditionsRun} header='title_conditionsRun'>
				<>
					{'conditions' in action && action.conditions?.map((conditionDataBlock, conditionBlockIdx, arrayConditionsData) => (
						// блоки ИЛИ
						<ConditionBlock
							conditionDataBlock={conditionDataBlock}
							conditionBlockIdx={conditionBlockIdx}
							isDisable={isDisabled}
							isAvailable={isEnabled}
							changeFlg={changeFlg}
							setChangeFlg={setChangeFlg}
							conditionFor={{
								for: 'action',
								itsAction: actionFor.for,
								actionEvent: (actionFor.for === 'robotEvent' || actionFor.for === 'dataElement') ? actionFor.event : actionFor.for === 'robotException' ? actionFor.exception : undefined,
								channel,
								channelIdx: idx,
							}}
							addConditionRunHandler={addConditionRunActionHandler}
							arrayConditionsData={arrayConditionsData}
							key={conditionDataBlock.toString() + conditionBlockIdx}
						/>
					))}
					{/* форма добавления условия */}
					{isEnabled &&
						<FormAddingCondition
							conditionForFormVariant={(!(action as Exclude<ActionVariantType, IActionInternalChat>).conditions || (action as Exclude<ActionVariantType, IActionInternalChat>)?.conditions?.length === 0)}
							addCondition={(data) => addConditionRunActionHandler((action as Exclude<ActionVariantType, IActionInternalChat>).conditions?.length || 0, data)}
							listOfSkipConditions={['interval']}
						/>
					}
				</>
			</DraggablePopoverComponent>

			{/* условия присвоения категорий */}
			<DraggablePopoverComponent anchorEl={anchorElCategoriesSettings} setAnchorEl={setAnchorElCategoriesSettings} header='title_conditionsCategories'>
				<>
					{/* категория */}
					<FormControl fullWidth margin='dense' sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiSelect-select': { paddingBlock: 0 },
						marginBottom: '8px',
					}}>
						<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
						<Select
							labelId="category-label"
							id="category"
							label={translate('select_category')}
							value={selectCategoryId}
							onChange={e => setSelectCategoryId(e.target.value)}
							style={{ fontSize: 13, height: 33, color: colorPrimary, backgroundColor: colorSecondaryLight3 }}
						>
							{/* на случай, если не найдена категория */}
							{(Object.keys((action as IActionInternalAsk)?.categories || {}).map(categoryId => {
								if (!categoriesList.data.find(categoryItem => categoryItem.id === categoryId)) {
									return (
										<MenuItem key={categoryId} value={categoryId} sx={{ fontSize: 12, color: colorRed }}>{categoryId} ({translate('selectItem_notFound')})</MenuItem>
									);
								} else return undefined;
							}))}
							{categoriesList.data.map((category) => (
								<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>
									{category.name} {(action as IActionInternalAsk).categories?.[category.id]?.length > 0 && `(${(action as IActionInternalAsk).categories[category.id].length})`}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					{/* блоки условий присвоения категорий */}
					{((selectCategoryId !== '' && activeRobotVersion === 'draft') || (action as IActionInternalAsk).categories?.[selectCategoryId]?.length > 0) &&
						<>
							{(action as IActionInternalAsk).categories?.[selectCategoryId]?.map((categoryDataBlock, categoryBlockIdx, arrayCategoriesData) =>
								// блоки ИЛИ
								<ConditionBlock
									conditionDataBlock={categoryDataBlock}
									conditionBlockIdx={categoryBlockIdx}
									isDisable={isDisabled}
									isAvailable={isEnabled}
									changeFlg={changeFlg}
									setChangeFlg={setChangeFlg}
									conditionFor={{
										for: 'categoryAction',
										itsAction: actionFor.for,
										actionEvent: (actionFor.for === 'robotEvent' || actionFor.for === 'dataElement') ? actionFor.event : actionFor.for === 'robotException' ? actionFor.exception : undefined,
										channel,
										channelIdx: idx,
										categoryId: selectCategoryId,
									}}
									addConditionRunHandler={addCategoryDataHandler}
									arrayConditionsData={arrayCategoriesData}
									key={categoryDataBlock.toString() + categoryBlockIdx + selectCategoryId}
								/>
							)}
							{/* форма добавления условия */}
							{isEnabled &&
								<FormAddingCondition
									conditionForFormVariant={!(action as IActionInternalAsk).categories[selectCategoryId] || (action as IActionInternalAsk).categories?.[selectCategoryId]?.length === 0}
									addCondition={(data) => addCategoryDataHandler((action as IActionInternalAsk).categories?.[selectCategoryId]?.length || 0, data)}
									listOfSkipConditions={['interval']}
								/>
							}
						</>
					}
				</>
			</DraggablePopoverComponent>

			{/* форма копирования/перемещения действия */}
			{showCopyAndMoveForm.isShown &&
				<FormCopyAndMoveAction
					showModal={showCopyAndMoveForm}
					setShowModal={setShowCopyAndMoveForm}
					action={action}
					actionFor={actionFor}
					sourceChannel={channel}
					sourceIdx={idx}
					changeFlgHandler={changeFlgHandler}
				/>
			}

			{/* подтверждение удаления действия */}
			<AlertDialog
				showAlertDialog={showAlertDialogDelAction}
				setShowAlertDialog={setShowAlertDialogDelAction}
				submitHandler={deleteActionHandler}
				title='dialog_deleteAction'
				description='dialog_deleteActionConfirm'
			/>
		</fieldset>
	);
};

export default ActionEvent;
